import React, { useContext, useEffect, useState } from 'react'
import * as L from './layout.styles';
import DocumentTitle from "react-document-title";
import { ThemeContext } from "@fluentui/react-theme-provider";
import Auth from "../../Auth/Auth";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";
import { Spinner, SpinnerSize, Toggle, TextField, DefaultButton, Dropdown, IDropdownOption } from "@fluentui/react";
import PortfolioModelWalletService, { ModelWallet } from '../../services/PortfolioModelWalletService';
import UserIdentitiesService from '../../services/UserIdentitiesService';
import SavingsPlanService, { SavingsPlanType } from '../../services/SavingsPlanService';
import { CartEP } from './components/CartEP';
import { cartStateEP, clearCartEP, updateModelPortfolioNameEP } from '../../store/educationalcart/actions';
import { connect, useDispatch } from "react-redux";

export enum CartModesEP
{
    VIEW_MODEL_PORTFOLIO = "VIEW_MODEL_PORTFOLIO",
    CREATE_MODEL_PORTFOLIO = "CREATE_MODEL_PORTFOLIO",
}

interface CartScreenEPProps extends RouteComponentProps
{
    cartLines: any[];
    modelPercentageTotal?: number;
    savingPlanType?: number;
    portfolioModelId?: number;
    current_state: CartModesEP;
    modelPortfolioName: string;
    activePunctualContribution: any;
}

function mapStateToProps(state: any)
{
    return { cartLines: state.educationalCart.cartLines, modelPercentageTotal: state.educationalCart.modelPercentageTotal, current_state: state.educationalCart.current_state, modelPortfolioName: state.educationalCart.modelPortfolioName, activePunctualContribution: state.educationalCart.activePunctualContribution };
}

const TMPCartEducationalPlanScreen = (props: CartScreenEPProps) =>
{
    const { modelPercentageTotal, current_state, modelPortfolioName, activePunctualContribution } = props;
    const dispatch = useDispatch();
    const theme = useContext(ThemeContext);
    const plan = Auth.getActivePlan();
    let user = Auth.getUserProfile();

    const location = useLocation();
    let state: any = location.state;
    const history = useHistory();

    const [planName, setPlanName] = useState(plan.name);
    const [planType, setPlanType] = useState(plan.type);

    const [loadingSavingsPlan, setLoadingSavingsPlan] = useState(false);

    const [toggleChecked, setToggleChecked] = useState<boolean>(true);
    const [modelWalletName, setModelWalletName] = useState<string>("");
    const [loadingModelWallet, setLoadingModelWallet] = useState<boolean>(false);
    const [isCurrentInstrumentIdsLoaded, setIsCurrentInstrumentIdsLoaded] = useState<boolean>(true);
    const [currentInstrumentIds, setCurrentInstrumentsIds] = useState<string[]>();
    const [error, setError] = useState({ message: false });

    const [cartModeStatus, setCartModeStatus] = useState<CartModesEP>(current_state);

    useEffect(() =>
    {
        if (props.current_state !== cartModeStatus)
        {
            setCartModeStatus(current_state);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    useEffect(() =>
    {

        if (props.cartLines.length > 0 && props.cartLines[0].savingsPlanId !== null && props.cartLines[0].savingsPlanId !== undefined)
        {
            setLoadingSavingsPlan(true);
            SavingsPlanService.getSavingsPlan(props.cartLines[0].savingsPlanId).then((savingsPlan: SavingsPlanType) =>
            {
                if (savingsPlan !== undefined)
                {
                    setPlanName(savingsPlan.name);
                    setPlanType(savingsPlan.type);
                    setLoadingSavingsPlan(false);
                }
            })
                .catch(error =>
                {
                    setError(error);
                });
        }
    }, [props.cartLines]);

    useEffect(() =>
    {
        if (!isCurrentInstrumentIdsLoaded)
        {
            // UserIdentitiesService.getInstrumentsIds(user.mainUserIdentity.id).then((currentInstrumentIds: string[]) =>
            // {
            //     setCurrentInstrumentsIds(currentInstrumentIds);
            //     setIsCurrentInstrumentIdsLoaded(true);
            // })
            // .catch((error: any) =>
            // {
            //     setError(error);
            // });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const modelWalletNameChangeHandler = (newValue: string | undefined) =>
    {
        if (newValue !== undefined)
        {
            setModelWalletName(newValue);
        }
    };

    const saveModelWalletOnClickHandler = () =>
    {
        setLoadingModelWallet(true);

        if(state?.portfolioModelId !== undefined)
        {
            PortfolioModelWalletService.updateModelWallet(plan.id!, modelWalletName, state?.portfolioModelId, props.cartLines).finally(() =>
            {
                 dispatch(clearCartEP());
                 dispatch(cartStateEP(CartModesEP.CREATE_MODEL_PORTFOLIO));
                dispatch(updateModelPortfolioNameEP(""));
                setLoadingModelWallet(false);
                history.push("/savingsPlans");
            });
        }
        else
        {
            PortfolioModelWalletService.saveModelWallet(plan.id!, modelWalletName, 0, props.cartLines).finally(() =>
            {
                 dispatch(clearCartEP());
                 dispatch(cartStateEP(CartModesEP.CREATE_MODEL_PORTFOLIO));
                 dispatch(updateModelPortfolioNameEP(""));
                setLoadingModelWallet(false);
                history.push("/savingsPlans");
            });
        }
    };



    return (
        <DocumentTitle title="IronIA - Carrito">
            <L.Main theme={theme}>
                <L.HeaderContainer>
                    <L.TitleContainer>
                        Carrito de Inversiones
                    </L.TitleContainer>
                    {plan !== undefined &&
                        (
                            <React.Fragment>
                                <L.PlanTitleContainer>
                                    {loadingSavingsPlan && <Spinner size={SpinnerSize.small} className="button-spinner" style={{ paddingTop: "4px" }} />}
                                    {!loadingSavingsPlan && <L.SubTitleContainer>{planName}</L.SubTitleContainer>}
                                </L.PlanTitleContainer>
                            </React.Fragment>
                        )}

                    <L.ModelWalletInputsContainer>
                        <L.ModelWalletToggle>
                            <Toggle label={<L.ModelWalletLabel>Cartera Modelo</L.ModelWalletLabel>}
                                inlineLabel
                                onText=" "
                                offText=" "
                                checked={toggleChecked}
                                disabled={true}
                                styles={
                                    {
                                        root: { margin: 0 },
                                        label: { margin: 0, paddingBottom: 0, paddingTop: "8px" },
                                        container: { paddingTop: "13px" }
                                    }}
                            />
                        </L.ModelWalletToggle>
                        <React.Fragment>
                            <TextField className="model-wallet-textfield"
                                theme={theme}
                                value={modelWalletName}
                                onChange={(e, newValue) => modelWalletNameChangeHandler(newValue)}
                                //disabled={cartModeStatus === CartModes.VIEW_MODEL_PORTFOLIO}
                                styles={
                                    {
                                        field:
                                        {
                                            borderRadius: "100px !important"
                                        },
                                        fieldGroup:
                                        {
                                            "&:hover":
                                            {
                                                borderRadius: "100px !important"
                                            },
                                            "&::after":
                                            {
                                                borderRadius: "100px !important"
                                            },
                                            borderRadius: "100px !important",
                                            height: "35px !important"
                                        },
                                        root:
                                        {
                                            margin: "0 1em 0 0 !important",
                                            paddingTop: "7px"
                                        }
                                    }}
                            />
                            {loadingModelWallet && <Spinner size={SpinnerSize.medium} className="button-spinner" />}
                            {!loadingModelWallet &&
                                (
                                    <DefaultButton className="button mini-primary-button"

                                        onClick={saveModelWalletOnClickHandler}
                                        styles={{ root: { height: "30px", marginTop: "7px !important" } }}
                                    >
                                        Guardar
                                    </DefaultButton>
                                )}
                        </React.Fragment>

                    </L.ModelWalletInputsContainer>
                    {/* )} */}
                </L.HeaderContainer>
                <L.SeparatorTop />
                {!isCurrentInstrumentIdsLoaded && <Spinner size={SpinnerSize.large} className="button-spinner" />}
                {isCurrentInstrumentIdsLoaded &&
                    (
                        <CartEP cartModeStatus={props.current_state} savingPlanType={state?.savingPlanType} currentInstrumentIds={currentInstrumentIds}
                            onChangeCartModeStatus={(newStatus: CartModesEP) =>
                            {
                                dispatch(cartStateEP((newStatus)));
                            }}
                        />
                    )}
            </L.Main>
        </DocumentTitle>
    )
}


export const CartEducationalPlanScreen = connect(mapStateToProps)(TMPCartEducationalPlanScreen);