import { IDropdownOption, Dropdown, Spinner, SpinnerSize } from '@fluentui/react';
import { ReactElement, useCallback, useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "@fluentui/react-theme-provider";
import DocumentTitle from 'react-document-title';
import React from 'react';
import { ColMarketDescription, ColMarket, MarketDescription, MarketTitle, RowMarket, MarketColLastAnalyisis, MarketColSearch, ColMarketAnalysis, MarketColLastAnalyisisContainer, MarketColSearchContainer, ButtonAnalysis, MarketSubTitle, MarketSearchCardsContainer, MarketManagementContainer, MarketDatePickerCont, MarkerDatePickerInner, ErrorMessage, MarketColSearchContainerInner, MarketColLastAnalyisisContainerInner, ColHeaderMarket, RowHeaderMarket, MarketSpacer, MarketSeparator, SearchButtonAnalysis, MarketLineSeparator, AnalisysChatGPTContainer, AnalyisisTableContainerInner, AnalyisisReportContainerInner, AnalyisisTableContainer, AnalyisisReportContainer, AnalyisisContainer } from './MarketStyle';
import SavingsPlanService, { ActiveSubscriptionAndSavingPlans } from '../../../services/SavingsPlanService';
import Auth from '../../../Auth/Auth';
import { IInfoFundPortfolio } from '../../../services/FundService';
import PortfolioTable from '../Summary/PortfolioTable';
import FinametrixService from '../../../services/FinametrixService';
import { useHistory } from 'react-router';
import moment from 'moment';
import { DatePicker } from "@fluentui/react";
import { useConst } from '@fluentui/react-hooks';
import { DatePickerStyle } from '../../../components/GlobalStyle';
import { DayPickerStrings } from '../../../components/ConvenienceTest/Utils/DatesTranlateFluents';
import MarketCardsCarousel from './MarketCarousel/MarketCarousel';
import MarketService from '../../../services/MarketService';
import subscriptionService, { UserSubscriptionsType } from '../../../services/SubscriptionService';
import { MarketAttachment } from '../../../components/MarketAttachment';
import { ActivePlansToAgregate, initialReporting, MarketItem, Subscription, TrialAttemps, dropdownStyles, AttemptResponse, Attempt } from './MarketTypes';

const Market = () => {
    moment.locale("es"); 
    const history = useHistory();
    const theme = useContext(ThemeContext);
    const user = Auth.getUserProfile();

    // useRef
    const myActivePlan = useRef<any>(Auth.getActivePlan());
    const planId = useRef<number>();
    const plansData = useRef<IDropdownOption>();
    const isLoadedReporting = useRef<boolean>(true);
    const registeredAttempt = useRef<Attempt | null>(null);
    const usedItemsFromDatabase = useRef<any[]>([]);

     // Plans TODO: >>> meter mano a esto
     const [selectedPlanId, setSelectedPlanId] = useState<number>(Auth.getActivePlan().id);
     const [allActivePlanToAgregate, setAllActivePlanToAgregate] = React.useState<ActivePlansToAgregate[]>([]);
     const [selectedActivePlansIds, setSelectedActivePlansIds] = React.useState<number[]>([]);
     const [selectedActivePlansData, setSelectedActivePlansData] = useState<IDropdownOption>();
    
     // Dropdown TODO:  >>> meter mano a esto
     const [dropdownOptions, setDropdownOptions] = useState<IDropdownOption[]>();
     const [disabledDropdown, setDisabledDropdown] = useState<boolean>(false);
     const [selectedPlansIds, setSelectedPlansIds] = React.useState<number[]>([]);
     const [selectedPlansData, setSelectedPlansData] = useState<IDropdownOption>();
     /**
      * TODO:
      * Opciones que se recogen de la llamada
      * Podemos trabajar con ellas para filtrarlas en el caso de que los intentos se hayan completado?
      * Aplicar FILTER por cada click de la tajeta
      */
     var auxDropdownOptions: IDropdownOption[] = [];
     const [auxDropdownStateO0ptions, setAuxDropdownStateO0ptions] = useState<IDropdownOption[]>(auxDropdownOptions);
     const [filteredOptions, setFilteredOptions] = useState<IDropdownOption[]>([]);
     const optionsRef = useRef<IDropdownOption[]>([]); // Referencia para el estado de las opciones
 
     // Attempts
     const [attemps, setAttemps] = useState<number | null>(0);
     const [gameOver, setGameOver] = useState<boolean>(false);
     const [marketSubscription, setMarketSubscription] = useState<Subscription>();
 
     // MarketItems && disableItems
     const [marketItems, setMarketItems] = useState<MarketItem[]>([]);
     const [showMarketItems, setShowMarketItems] = useState<boolean>(false);
     const [blockedCarousel, setBlockedCarousel] = useState<boolean>(false);
     const [selectedMarketItem, setSelectedMarketItem] = useState<MarketItem>();
 
     // LastMarketItems
     const [lastMarketItems, setLastMarketItems] = useState<MarketItem[]>([]);
     const [showLastMarketItems, setShowLastMarketItems] = useState<boolean>(false);
 
     // UsedMarketItems && disableUsedItems
     const [selectedUsedMarketItem, setSelectedUsedMarketItem] = useState<MarketItem>();
     const [usedMarketItems, setusedMarketItems] = useState<MarketItem[]>([]);
     const [blockedUsedCarousel, setBlockedUsedCarousel] = useState<boolean>(false);
     const [usedSavingPlanId, setUsedSavingPlanId] = useState<number>(0);
     const [isUsedCase, setIsUsedCase] = useState<boolean>(false);
     const [usedAttemptId, setUsedAttemptId] = useState<number>(0);

 
     // Pdf
     const [showMarketReport, setShowMarketReport] = useState<boolean>(false);
     const [showPdfViewer, setShowPdfViewer] = useState<boolean>(false);
     const [pdfUrl, setPdfUrl] = useState<string>("");

     // Reporting (tabla)
     const [reporting, setReporting] = useState(initialReporting);
     const [collectedItems, setCollectedItems] = useState<any[]>([]);
     const [isLoadingReporting, setIsLoadingReporting] = useState<boolean>(false);
 
     // Analysis && management
     const [analysisTitle, setAnalysisTitle] = useState<string>("Últimos análisis");
     const [analysisName, setAnalysisName] = useState<string | null>(null);
     const [showDropdown, setShowDropdown] = useState<boolean>(true);
     const [showMarketManagementContainer, setShowMarketManagementContainer] = useState<boolean>(false);

     // Analysis ChatGPT
     const [canSendChatGPT, setCanSendChatGPT] = useState<boolean>(false);
     const [analysisResponseChatGPT, setAnalysisResponseChatGPT] = useState<any>();
 
     // Subscription
     const [isSuscribed, setIsSuscribed] = useState<boolean>(false);
     const [subscriptionId, setSubscriptionId] = useState<number>(0);

     // Finametrix && Loading
     const [selectedFinametrixContracts, setSelectedFinametrixContracts] = React.useState<string[]>([]);
     const [isFinametrixPortfolioLoaded, setIsFinametrixPortfolioLoaded] = useState<boolean>(false);
     const [finametrixPortfolio, setFinametrixPortfolio] = useState(
        {
            creationDate: "",
            finametrixId: "",
            amount: 0,
            cost: 0
        });
    
     // Funds
    const [modelFunds, setModelFunds] = useState<any[]>([]);
    const [funds, setFunds] = useState<IInfoFundPortfolio[]>([]);
    const [isLoadedFunds, setIsLoadedFunds] = useState(false);
 
     // Loading
     const [firstLoad, setFirstLoad] = useState<boolean>(true);
     const [mainLoading, setMainLoading] = useState<boolean>(false);
     const [isLoadingChange, setLoadingChange] = useState<boolean>(false); // aux
 
     // Dates && disableDates
     const [searchDatesInProcess, setSearchDatesInProcess] = React.useState<boolean>(false);
     const [blockedDateSearch, setBlockedDateSearch] = useState<boolean>(false);
     const [analyisisDateRange, setAnalysisDateRange] = useState({ startDate: new Date(), endDate: new Date() });
     const date_to = function (this: any) { 
        this.setDate(this.getDate()); 
        return this;
    }.call(new Date());
    const date_to_text = useConst(date_to.getFullYear().toString() + ("0" + (date_to.getMonth() + 1)).slice(-2) + ("0" + date_to.getDate()).slice(-2));


    /* -----------------------------------------------------------------------------
    /* ERRORES
    /*----------------------------------------------------------------------------*/
     const [dateErrors, setDateErrors] = useState({
         analysisStartDate: "",
         analysisEndDate: "",
     });

     const [mainError, setMainError] = useState({
        message: "",
    });


    /* -----------------------------------------------------------------------------
    /* CERRAR CONTENEDOR MANAGEMENT
    /*----------------------------------------------------------------------------*/
    const handleCloseAnalysisClick = () => {
        if (isSuscribed) {
            setShowMarketManagementContainer(true);
            setShowPdfViewer(true);
            setShowDropdown(true);
            setShowMarketReport(false);
            setIsLoadingReporting(false);
            setBlockedCarousel(false);
            setBlockedDateSearch(false);
        } else if (attemps && attemps >= 3) {
            setShowMarketManagementContainer(true);
            setShowPdfViewer(true);
            setShowDropdown(true);
            setShowMarketReport(false);
            setIsLoadingReporting(false);
            setBlockedCarousel(true);
            setBlockedDateSearch(true);
            setBlockedUsedCarousel(false);
        } else {
            setShowMarketManagementContainer(true);
            setShowPdfViewer(true);
            setShowDropdown(true);
            setShowMarketReport(false);
            setIsLoadingReporting(false);
            setBlockedCarousel(false);
            setBlockedDateSearch(false);
            setBlockedUsedCarousel(false);
        }
    };


    /* -----------------------------------------------------------------------------
    /* FECHAS
    /*----------------------------------------------------------------------------*/
    const handleAnalysisDateInputChange = (name: string, value: Date) => {
        if (name === 'analysisStartDate') {
            setAnalysisDateRange((prevRange) => ({
                ...prevRange,
                startDate: value,
            }));
        } else if (name === 'analysisEndDate') {
            setAnalysisDateRange((prevRange) => ({
                ...prevRange,
                endDate: value,
            }));
        }
    };

    const handleAnalysisDatesClick = () => {
        setSearchDatesInProcess(true);
        setBlockedDateSearch(true);
        setBlockedCarousel(true);
        setBlockedUsedCarousel(true);
        setDisabledDropdown(true);
        setLoadingChange(true);
        const startDate = moment(analyisisDateRange.startDate).format('YYYY-MM-DD');
        const endDate = moment(analyisisDateRange.endDate).format('YYYY-MM-DD');

        MarketService.getMarketItems(startDate, endDate)
            .then((response: any) => {
                if (response.length > 0) {
                    setMarketItems(response);
                    setShowMarketItems(true);
                }
            }).catch((error) => {
                setMainError(prevState =>
                    ({
                        ...prevState,
                        message: "Ha ocurrido un error al recoger los datos de la fecha seleccionada."
                    }));
            }).finally(() => {
                setBlockedDateSearch(false);
                setBlockedCarousel(false);
                setBlockedUsedCarousel(false);
                setDisabledDropdown(false);
                setLoadingChange(false);
                setSearchDatesInProcess(false);
            });
    };


    // ----------------------------------------------------------------------------------
    // HELPERS
    // ----------------------------------------------------------------------------------
    const unlimitedAttempts = (marketItem: MarketItem, pdfFileName: string) => {
        setAttemps(null);
        setPdfUrl(pdfFileName);
        setAnalysisName(marketItem.id);
        setShowPdfViewer(true);
        setShowMarketManagementContainer(true);
        setBlockedCarousel(false);
        setDisabledDropdown(false);
        setBlockedDateSearch(false);
        setLoadingChange(false);
    }

    const remainingAttempts = (marketItem: MarketItem, pdfFileName: string) => {
        setPdfUrl(pdfFileName);
        setAnalysisName(marketItem.id);
        setShowMarketManagementContainer(true);
        setShowPdfViewer(true);
        setBlockedCarousel(false);
        setDisabledDropdown(false);
        setBlockedDateSearch(false);
        setLoadingChange(false);
    }
    
    const noAttempts = () => {
        setShowMarketManagementContainer(true);
        setShowPdfViewer(true);
        setBlockedCarousel(true);
        setDisabledDropdown(false);
        setBlockedDateSearch(false);
        setLoadingChange(false);
    }

    const disableComponentsOnMarketCardClick = () => {
        setLoadingChange(true);
        setBlockedCarousel(true);
        setAnalysisResponseChatGPT(null);
        setAnalysisResponseChatGPT(null);
        setBlockedCarousel(true); // Bloquea temporalmente mientras procesas
        setShowMarketManagementContainer(false); // Oculta el contenedor hasta verificar
        setShowPdfViewer(false);
        setMainLoading(true);
        setDisabledDropdown(true);
        setBlockedDateSearch(true);
    }

    const enableComponentsOnGettingAttempt = () => {
        setShowPdfViewer(false);
        setIsLoadingReporting(false);
        setShowMarketReport(true);
        setMainLoading(false);
    }


    const disableAvailableOptions = () => {
        setAnalysisTitle("Análisis gratuítos disponibles");
        setBlockedCarousel(true);
        setShowLastMarketItems(false);
    }

    const portfolioDataWithErrorsStatuses = () => {
        // setError(error);
        isLoadedReporting.current = true;
        setIsLoadedFunds(true);
        setLoadingChange(false);
    }

    const updateModelFunds = (items: any[]) => {
        if (items) {
            setModelFunds(items);
        }
        return items;
    }

    // Recoge los datos de la tabla de reporting
    const handleCollectedItemsUpdate = (items: any[]) => {
        setCollectedItems(items);
    };

    const getDateAsString = (date: Date) =>
        {
            return date.getFullYear().toString() + ("0" + (date.getMonth() + 1)).slice(-2) + ("0" + date.getDate()).slice(-2);
        };
    const getLowestSelectedPlanCreationDate = () =>
    {
        //get lowest date_from from selected plans to be agregated
        var date = new Date(Math.min(...allActivePlanToAgregate.filter(x => x.isSelected === true).map(element =>
        {
            return new Date(element.createdAt).getTime();
        })));

        return date;
    };


    // ----------------------------------------------------------------------------------
    // FINAMETRIX :: Primera carga de los fondos
    // ----------------------------------------------------------------------------------
    const fetchFinametrixReporting = useCallback(async (portfolioId: string, date_from: string, date_to: string) => {
        if(isLoadedReporting.current)
        {
            isLoadedReporting.current = false;
            await FinametrixService.getReporting(portfolioId, date_from, date_to).then((data: any) => {
                setReporting(data);
                setCanSendChatGPT(true);
            },
            error =>
            {
                portfolioDataWithErrorsStatuses();
                setMainError(prevState => ({
                    ...prevState,
                    message: 'Error al recoger el reporting. ' 
                }));
            })
            .catch((error) =>
            {
                portfolioDataWithErrorsStatuses();
                setMainError(prevState => ({
                    ...prevState,
                    message: 'Error al recoger el reporting. ' 
                }));
            })
            .finally(() =>
            {
                setIsLoadedFunds(true);
                isLoadedReporting.current = true;
                setIsLoadingReporting(false)
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

    const fetchFinametrixPortfolio = async (portfolioId: string) => {
        if (isFinametrixPortfolioLoaded === false) {
            await FinametrixService.getPortfolio(portfolioId).then((finametrixData: any) => {
                if (finametrixData === "Not Found") {
                    setReporting(initialReporting);
                    portfolioDataWithErrorsStatuses();
                    setIsFinametrixPortfolioLoaded(true);
                }
                else {
                    setFinametrixPortfolio(finametrixData);
                    setIsFinametrixPortfolioLoaded(true);
                }
            },
                error => {
                    setReporting(initialReporting);
                    setMainError(prevState =>
                        ({
                            ...prevState,
                            message: "Ha ocurrido un error al recoger los datos del portfolio."
                        }));
                    portfolioDataWithErrorsStatuses();
                })
                .catch((error) => {
                    setReporting(initialReporting);
                    setMainError(prevState =>
                        ({
                            ...prevState,
                             message: "Ha ocurrido un error al recoger los datos del portfolio."
                        }));
                    portfolioDataWithErrorsStatuses();
                    setIsFinametrixPortfolioLoaded(true);
                });
        }
    };

    // ----------------------------------------------------------------------------------
    // MARKET CARD ITEMS :: EVENTOS CLICK :: tanto para los últimos como para el carousel
    // ----------------------------------------------------------------------------------
    const handleMarketCardClick = async (marketItem: MarketItem) => {
        setSelectedUsedMarketItem(marketItem);
        setSelectedMarketItem(marketItem);
        // bloqueo de campos
        disableComponentsOnMarketCardClick();

        try {
            // POST: para registrar el intento (Se registra con el plan seleccionado por defecto)
            const postAttemptResponse: AttemptResponse = await MarketService.postFreeTrialAttempt(user.id, subscriptionId, marketItem.fileUrl, selectedPlanId);
            if(postAttemptResponse && postAttemptResponse.success && postAttemptResponse.attempt){

                console.log('postAttemptResponse ***Zzz', postAttemptResponse);
                // aqui: recogemos el intento registrado
                registeredAttempt.current = postAttemptResponse.attempt;

                // GET: devuelve los intentos restantes
                const getAttemptResponse = await MarketService.getFreeTrialAttemps(user.id);
                if(getAttemptResponse && getAttemptResponse.success){
                    const pdfFileName = marketItem.fileUrl.split("/marketreports/")[1];
                    if (getAttemptResponse.usedAttempts === null) {
                        // Intentos ilimitados => usuario 
                        setAttemps(null);
                        
                        unlimitedAttempts(marketItem, pdfFileName); 
                    } else if (getAttemptResponse.usedAttempts < 4) {
                        // usuario no registrado al que le quedan análisis
                        const usedAttempts = getAttemptResponse.usedAttempts;
                        setAttemps(usedAttempts);
                        remainingAttempts(marketItem, pdfFileName);
                    }else{
                        // no quedan intentos de análisis
                        setGameOver(true);
                        setAttemps(3);
                        noAttempts();
                    }
                }else{
                    setMainError(prevState => ({
                        ...prevState,
                        message: "No se pudo procesar tu solicitud."
                    }));
                    setBlockedCarousel(true);
                }
            }
        } catch (error) {
            setMainError(prevState => ({
                ...prevState,
                message: "Error al manejar el clic en la tarjeta: "  
            }));
            console.error("Error al manejar el clic en la tarjeta:", error);
            setBlockedCarousel(true);
        } finally {
            setMainLoading(false);
        }
    };

    const fetchSavingsPlans = async (filteredItems: []) => {
        setMainLoading(true);
        console.log('usedItemsFromDatabase.current!', usedItemsFromDatabase.current!);

        try {
            const savingPlans = await Promise.all(
                filteredItems.map((id: any) => SavingsPlanService.getSavingsPlan(id))
            );
            const options: IDropdownOption[] = savingPlans.map((plan: any, index: number) => {
                return {
                    id: plan.id.toString(),
                    key: plan.id,
                    text: plan.name,
                    selected: index === 0
                };
            });
            console.log('myoptions ***', dropdownOptions);
            setUsedSavingPlanId(Number(options[0].id));
            setDropdownOptions(options);
        } catch (error) {
        
            console.error("Error fetching saving plans:", error);
        } finally {
            setShowMarketManagementContainer(true);
            setMainLoading(false);
        }
    };

    // ----------------------------------------------------------------------------------
    // USED MARKET CARD ITEMS :: EVENTOS CLICK
    // ----------------------------------------------------------------------------------
    const handleUsedMarketCardClick = (marketItem: MarketItem) => {
        setMainLoading(true);
        setIsUsedCase(true);
        // setMainLoading(true);
        setSelectedUsedMarketItem(marketItem);
        // disableComponentsOnUsedMarketCardClick();
        setShowMarketManagementContainer(true);
        setAnalysisName(marketItem.id);

        const postResponse =  MarketService.postFreeTrialAttempt(user.id, subscriptionId, selectedUsedMarketItem?.fileUrl!, selectedPlanId);
        postResponse.then((res: AttemptResponse) => {
            if(res && res.success && res.attempt){
                // recogemos el intento registrado
                registeredAttempt.current = res.attempt;
                const pdfFileName = marketItem.fileUrl.split("/marketreports/")[1];
                setPdfUrl(pdfFileName);
                setShowPdfViewer(true);
                setUsedAttemptId(res.attempt?.id!);
            }
        }).catch((error) => {
            setMainError(prevState => ({
                ...prevState,
                message: "Error akl insertar el registro de intento: "
            }));
        })
        .finally(() => {
            setMainLoading(false);
        });
       
    };

    // ----------------------------------------------------------------------------------
    // ATTEMPS :: Recoge los intentos restantes
    // ----------------------------------------------------------------------------------
    const fetchTrialAttempts = async () => {
        try {
            const response: TrialAttemps = await MarketService.getFreeTrialAttemps(user.id);
            if (response.usedAttempts === null || response.usedAttempts === undefined) {
                // Si no hay registros, asumimos que el usuario no ha usado intentos
                setAttemps(0);
                setBlockedCarousel(false);
            } else {
                const remainingAttempts = 3 - response.usedAttempts;
                // Si hay registros, calculamos los intentos restantes
                if (response.usedAttempts > 3) {
                    setAttemps(3);
                    setBlockedCarousel(true);
                } else {
                    setAttemps(response.usedAttempts);
                    setBlockedCarousel(remainingAttempts <= 0);
                }
            }
        } catch (error) {
            setMainError(prevState => ({
                ...prevState,
                message: 'Error al recoger los intentos del usuario. ' 
            }));
            console.error("Error fetching trial attempts:", error);
            setAttemps(0); // En caso de error, asumimos que no hay intentos restantes
            setBlockedCarousel(true);
        }
    };

    // ----------------------------------------------------------------------------------
    // CHATGPT :: Actualiza el análisis en la base de datos
    // ----------------------------------------------------------------------------------
    const updateDataBaseExtractedAnalysisChatGPT = (analysis: any, analysisId: number) => {
        var attemptToUpdate = {
            id: analysisId,
            ExtactedAnalysisChatGPT: analysis.toString()
        };
        const patchAttemptResponse = MarketService.patchAttempt(attemptToUpdate);
        return patchAttemptResponse;
    }

    const updateSavingPlanId = async (analysisId: number, savingPlanId: number) => {
        setMainLoading(true);
        var attemptToUpdate = {
            id: analysisId,
            savingPlanId: savingPlanId
        };
        const patchResult = MarketService.patchAttempt(attemptToUpdate);
        return patchResult;
    };

    const createNewAnalysisChatGPT = async (reportingToChatGPT: any, pdfFileNameInput: string) => {
        if (reportingToChatGPT !== null) {
            try {
                const pdfFileName = pdfFileNameInput!.split("/marketreports/")[1];
                const response = await MarketService.postMarketAnalysisChatGPT(user.id, subscriptionId, pdfFileName, reportingToChatGPT);
    
                if (response && response.status === 200) {
                    setAnalysisResponseChatGPT(response.data);
                    return response.data;
                } else {
                    const errorMessage = `Ha ocurrido un error al procesar tu solicitud de ChatGPT. ${response}`;
                    setAnalysisResponseChatGPT(errorMessage);
                    console.error('Error en la solicitud de ChatGPT >>>', response);
                    return null;
                }
            } catch (error) {
                console.error("Error en createNewAnalysisChatGPT:", error);
                setAnalysisResponseChatGPT("Ha ocurrido un error inesperado al procesar tu solicitud de ChatGPT.");
                return null;
            } finally {
                setShowMarketReport(true);
            }
        }
        return null;
    };
    


    // ----------------------------------------------------------------------------------
    // Finametrix :: Recoge los fondos del la tabla de reporting
    // ----------------------------------------------------------------------------------
    const getSelectedPortfolio = async (selectedPlanId: number) => {
        let lowestDate_from = getLowestSelectedPlanCreationDate();
        try {
            // Obtener el plan de ahorro
            const savingPlanResponse = await SavingsPlanService.getSavingsPlan(selectedPlanId);
            if (savingPlanResponse && savingPlanResponse?.fnmContract?.fnmPortfolio?.finametrixId) {
                const auxFinametrixId = savingPlanResponse.fnmContract.fnmPortfolio.finametrixId;
                const reportingResponse = await FinametrixService.getReporting(auxFinametrixId, getDateAsString(lowestDate_from), date_to_text);
                setReporting(reportingResponse);
                setIsLoadingReporting(false);
                setShowMarketReport(true);
                return reportingResponse;
            }

        } catch (error) {
            setMainError(prevState => ({
                ...prevState,
                message: "Error al obtener el portfolio: " 
            }));
        } finally {
            setCanSendChatGPT(true);
        }
    
        return null;
    };
    

    // ----------------------------------------------------------------------------------------------------
    // ANÁLISIS
    // Paso 1: Verifica si existe un análisis previo en el backend antes de enviar a ChatGPT.
    // Paso 2: 
    //  2.1 >>> recoge el portfolio seleccionado para enviar los datos a ChatGPT
    //  2.2 >>> Llamada a ChatGPT con los datos obtenidos de los datos del reporting >> collectedItems
    //  2.3 >>> Update del análisis de ChatGPT en la base de datos.
    //  2.4 >>> Muestra los componentes tabla y análisisChatGPT
    // ----------------------------------------------------------------------------------------------------
    const disableComponentsOnClickAnalysis = () => {
        setMainLoading(true);
        setIsLoadingReporting(true);
        setShowPdfViewer(false);
        setShowMarketReport(true);
        setShowDropdown(false);
        setBlockedCarousel(true);
        setBlockedDateSearch(true);
        setBlockedUsedCarousel(true);
        setReporting(initialReporting); 
        setAnalysisResponseChatGPT(null);
    }

    const getAnalysisFromDataBase = async (planIdSelected: number, pdfFileUrlString: string) => {
        const pdfFileName = pdfFileUrlString.split("/marketreports/")[1];
        const attemptFromDataBase = MarketService.getMarketReportAnalysis(user.id, pdfFileName, planIdSelected);
        attemptFromDataBase.then((response: any) => {
            if (response && response.analysis !== null) {
                setAnalysisResponseChatGPT(response.analysis);
            }
        });
        return attemptFromDataBase;
    };

    const handleAnalysisClick = async () => {
        setMainLoading(true);
        setBlockedCarousel(true);
        setBlockedDateSearch(true);
        setLoadingChange(true);
        setShowPdfViewer(false);
        setShowDropdown(false);
        // disableComponentsOnClickAnalysis();

        setSelectedFinametrixContracts([]);

        let myPlanId = isUsedCase ? usedSavingPlanId : registeredAttempt.current?.savingPlanId!;
        let selectedPlans = allActivePlanToAgregate.filter(x => x.id === selectedPlanId.toString());
        if(selectedPlans.length === 1){
            const userSelectedPlanId = Number(selectedPlans[0].id);
            // comiena a cargar el reporting
            setIsLoadingReporting(true);
            setSelectedPlanId(userSelectedPlanId);

            // registeredAttempt.current?.savingPlanId; por defecto
            // selectedPlanId => el que selecciono
            if(registeredAttempt.current?.savingPlanId !== selectedPlanId){
                // Si el plan seleccionado es diferente al plan registrado se actualiza el plan seleccionado
                myPlanId = selectedPlanId;
            }
            // Paso 1: Verifica si existe un análisis previo en el backend antes de enviar a ChatGPT.
            var pdf = "";
            if(isUsedCase){
                pdf = selectedUsedMarketItem?.fileUrl!;
            }else{
                pdf = registeredAttempt.current?.marketReportFileUrl!;
            }

            // recoge el análisis de la base de datos
            const analysisFromDataBase = await getAnalysisFromDataBase(myPlanId, pdf);

            // Paso 2.1 >>> recoge el portfolio seleccionado para enviar los datos a ChatGPT
            const selectedPortfolio = await getSelectedPortfolio(userSelectedPlanId);
            if (analysisFromDataBase?.success === true) {
                // muestra el análisis que ya existe en base de datos
                enableComponentsOnGettingAttempt();
            } else {
                // Paso 2.2: Update del análisis de ChatGPT en la base de datos >> registeredAttempt.current?.id!
                if(selectedPortfolio !== null){
                    const reportingToChatGPT = JSON.stringify(collectedItems);
                    const responseChatGPT = await createNewAnalysisChatGPT(reportingToChatGPT, pdf);
                    
                    if(responseChatGPT !== null){
                        if(isUsedCase){
                            const patchResponse = updateDataBaseExtractedAnalysisChatGPT(responseChatGPT, registeredAttempt.current!.id);
                            patchResponse.then((response: any) => {
                                if(response && (response.status === 200)){
                                    setBlockedDateSearch(false);
                                    setLoadingChange(false);
                                    setMainLoading(false);
                                }
                            });
                        }else{
                            const patchResponse = updateDataBaseExtractedAnalysisChatGPT(responseChatGPT, registeredAttempt.current!.id!);
                            patchResponse.then((response: any) => {
                                if(response && (response.status === 200)){
                                    setBlockedDateSearch(false);
                                    setLoadingChange(false);
                                    setMainLoading(false);
                                }
                            });
                        }
                    }
                    // Paso 2.4 >>> Muestra los componentes tabla y análisisChatGPT
                    setIsLoadingReporting(false);
                }else{
                    setMainError(prevState => ({
                        ...prevState,
                        message: 'Error al recoger el portfolio seleccionado'
                    }));
                }
            }
        }
    };

    
    // ----------------------------------------------------------------------------------
    // DROPDOWN
    // ----------------------------------------------------------------------------------
    const handleRenderTitle = (items: IDropdownOption[] | undefined): ReactElement<any, any> => {
        if (selectedActivePlansData != undefined){
            return <React.Fragment>{`${selectedActivePlansData.text}`}</React.Fragment>;
        }else{
            if ( items && items.length === 1) {
                return <React.Fragment>{`${items[0].text}`}</React.Fragment>;
            }
        }
        return <React.Fragment></React.Fragment>;
    };

    /**
     * 
     * @param event 
     * @param item el item seleccionado en el dropdown
     */
    const updateSelectedPlanKeys = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {
        // spinner
        setMainLoading(true);
        // bloqueo de campos
        setLoadingChange(true);
        setBlockedCarousel(true);
        setBlockedDateSearch(true);
        setBlockedUsedCarousel(true);
        if(item && item.id){
            setSelectedActivePlansData(item);
            // recoge el plan seleccionado en el dropdown para usarlo en la tabla de reporting
            setSelectedActivePlansIds([Number(item!.id)]);   
            setSelectedPlanId(Number(item!.id)); 
            planId.current = Number(item!.id);

            // saber si existe el registro de intento con ese PlanId
            // aqui
            const pdfFileName = selectedMarketItem?.fileUrl!.split("/marketreports/")[1];
            const attemptExist = MarketService.getSavingPlanIdByMarketCard(user.id, planId.current,pdfFileName!);
            attemptExist.then((rs: any) => {
                console.log('attemptExist ***', rs);
                if(rs.success === false){
                    MarketService.postFreeTrialAttempt(user.id, subscriptionId, selectedMarketItem?.fileUrl!, Number(item!.id)).then((response: AttemptResponse) => {
                        if(response && response.success && response.attempt){
                            registeredAttempt.current = response.attempt;
                            setLoadingChange(false);
                            setBlockedCarousel(false);
                            setBlockedDateSearch(false);
                            setBlockedUsedCarousel(false);
                            setMainLoading(false);
                        }
                    });                    
                }else{
                    const updateResponse = updateSavingPlanId(registeredAttempt.current!.id!, Number(item!.id));
                    updateResponse.then((response: any) => {
                        if(response && response.status === 200){
                            setLoadingChange(false);
                            setBlockedCarousel(false);
                            setBlockedDateSearch(false);
                            setBlockedUsedCarousel(false);
                            setMainLoading(false);
                        }
                    }).finally(()=>{
                        setLoadingChange(false);
                        setBlockedCarousel(false);
                        setBlockedDateSearch(false);
                        setBlockedUsedCarousel(false);
                        setMainLoading(false);
                    });
                    registeredAttempt.current = rs.attempt;
                }
            });
            // en el caso de que el usuario haya usado los 3 intentos
            setUsedSavingPlanId(Number(item!.id));
        }  
    };

    // *****************************************************************************
    // EFFECTS :: USER SUBSCRIPTION > saber si el usuario está suscrito a MERCADO
    // *****************************************************************************
    useEffect(() => {
        try {
            subscriptionService.getSubscriptionByUser(user.id).then((response: UserSubscriptionsType[]) => {
                const mercadoSubscription = response.filter(subscription =>
                    subscription.subscriptionType === "service" && subscription.subscriptionName === "Mercado"
                );
                if (mercadoSubscription.length > 0) {
                    setIsSuscribed(true);
                } else {
                    setIsSuscribed(false);
                }
            });
        } catch (error) {
            setMainError(prevState => ({
                ...prevState,
                message: 'Error al recoger el la suscripción del usuario' 
            }));
        }

        
    }, []);


    // *****************************************************************************
    // EFFECTS :: Recopila datos de MARKET SUBSCRIPTION
    // *****************************************************************************
    useEffect(() => {
        try {
            subscriptionService.getSubscriptionsByType("service").then((response: Subscription[]) => {
                if (response.length > 0) {
                    const mercadoItem = response.filter(item => item.name === "Mercado");
                    if(mercadoItem[0] && mercadoItem[0]?.id){
                        setSubscriptionId(mercadoItem[0]?.id!);
                        setMarketSubscription(mercadoItem[0]);
                    }
                }
            });
        } catch (error) {
            setMainError(prevState => ({
                ...prevState,
                message: 'Error al recoger el la suscripción del servicio Mercado. ' 
            }));
        }
    }, []);


    // *****************************************************************************
    // EFFECTS :: FECHAS
    // *****************************************************************************
    useEffect(() => {
        const today = new Date();
        const fourteenDaysAgo = new Date();
        fourteenDaysAgo.setDate(today.getDate() - 14);

        setAnalysisDateRange({
            startDate: fourteenDaysAgo,
            endDate: today
        });
    }, []);


    // *****************************************************************************
    // EFFECTS :: INTENTOS :: Comprueba el número de intentos restantes 
    // usedAttempts = null => usuario suscrito
    // intentos máximos = 3
    // *****************************************************************************
    useEffect(() => {
        fetchTrialAttempts();
    }, []);


    // *****************************************************************************
    // EFFECTS :: MARKET ITEMS & LASTMARKETITEMS
    // *****************************************************************************
    useEffect(() => {
        const today = new Date();
        const twoMonthsAgo = new Date(today);
        twoMonthsAgo.setDate(today.getDate() - 60);

        const startDate = twoMonthsAgo.toISOString().split('T')[0];
        const endDate = today.toISOString().split('T')[0];

        try {
            MarketService.getLastMarketItems().then((response: any) => {
                if (response.length > 0) {
                    setLastMarketItems(response);
                    setShowLastMarketItems(true);
                }
            });

            MarketService.getMarketItems(startDate, endDate).then((response: any) => {
                if (response.length > 0) {
                    setMarketItems(response);
                    setShowMarketItems(true);
                }
            });
        } catch (error) {
            setMainError(prevState => ({
                ...prevState,
                message: 'Error al recoger los items del servicio Mercado. ' 
            }));
        }
    }, []);


    // *********************************************************************************
    // EFFECTS :: REPORTES DISPONIBLES
    // *********************************************************************************
    useEffect(() => {
        const fetchAvailableReports = async () => {
            if (attemps && attemps >= 3) {
                setBlockedDateSearch(true);
                try {
                    const response = await MarketService.getAvailableReports(user.id);
                    if (response.success) {
                        disableAvailableOptions();
                        setusedMarketItems(response.availableMarketReports);
                    }
                } catch (error) {
                    setMainError(prevState => ({
                        ...prevState,
                        message: 'Error al obtener los informes de Mercado disponibles. ' 
                    }));
                    console.error("Error al obtener los informes disponibles", error);
                }
            }
        };
        fetchAvailableReports();
    }, [attemps, user.id]);


    // ********************************************************************************
    // EFFECTS :: Primera carga para recoger las opciones del dropdown y planes activos
    // ********************************************************************************
    useEffect(() => {
        if (firstLoad) {
            setFirstLoad(false);
            let allPlanToAgregate: ActivePlansToAgregate[] = [];
            let selectedActivePlanIds: number[] = [];

            try {
                const initialSuscriptions = SavingsPlanService.getActiveSubscriptionAndSavingPlan(user.id);
                initialSuscriptions.then((response: ActiveSubscriptionAndSavingPlans[]) => {
                    for (var i = 0; i < response.length; i++) {
                        auxDropdownOptions.push(
                            {
                                key: response[i].id,
                                text: response[i].planName,
                                selected: response[i].selected,
                                id: response[i].id.toString()
                            });
    
                        let planToAgregate: ActivePlansToAgregate =
                        {
                            id: response[i].id.toString(),
                            createdAt: response[i].createdAt!,
                            isSelected: response[i].selected,
                            finametrixId: response[i].finametrixId,
                            finametrixContract: response[i].finametrixContract,
                            fnmContractId: response[i].fnmContractId
                        };
    
                        allPlanToAgregate.push(planToAgregate);
    
                        if (response[i].selected) {
                            let selectedlanId = response[i].id;
                            selectedActivePlanIds.push(selectedlanId);
                        }
                    }
                    setDropdownOptions(auxDropdownOptions);
                });
                setAllActivePlanToAgregate(allPlanToAgregate);
                setSelectedActivePlansIds(selectedActivePlanIds);

            } catch (error) {
                setMainError(prevState => ({
                    ...prevState,
                    message: 'Error al recoger los items del servicio Mercado. ' 
                }));
            }
        }
    }, [user]);


    // *********************************************************************************
    // EFFECTS :: FINAMETRIX :: recoge los fondos de la cartera si cambia el plan activo
    // *********************************************************************************
    useEffect(() => {
        if (myActivePlan?.current.portfolio?.finametrixId !== undefined) {
            fetchFinametrixPortfolio(myActivePlan.current.portfolio.finametrixId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myActivePlan]);

    useEffect(() => {
        if (isLoadedReporting.current) {
            if (finametrixPortfolio && finametrixPortfolio !== undefined && finametrixPortfolio.creationDate !== "" && finametrixPortfolio.creationDate !== undefined && myActivePlan?.current.portfolio?.finametrixId !== undefined) {
                setSelectedFinametrixContracts([myActivePlan.current.finametrixContract]);
                fetchFinametrixReporting(myActivePlan.current?.portfolio?.finametrixId, finametrixPortfolio.creationDate.substring(0, 10).replace(/-/g, ""), date_to_text);
            }
        }
    }, []);




    return (
        <DocumentTitle title='IronIA - Mercado'>
            <>
                <React.Fragment>
                    <div>
                        <RowHeaderMarket className="ms-Grid-row">
                            <ColHeaderMarket>
                                <h1>Análisis Mercado</h1>
                            </ColHeaderMarket>
                            {
                                !isSuscribed && <ButtonAnalysis
                                    text="Suscribirse"
                                    onClick={() => history.push('/portfolio/market-suscription')}
                                    disabled={isLoadingChange}
                                />
                            }
                        </RowHeaderMarket>
                        <MarketLineSeparator className='separator' />
                    </div>
                    <MarketSeparator />
                    <RowMarket className="ms-Grid-row">
                        <ColMarket className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                            <MarketTitle>
                                Análisis Mercado
                                {!isSuscribed &&
                                    <span> {attemps} de 3 gratis</span>
                                }
                            </MarketTitle>
                        </ColMarket>
                        <ColMarketDescription className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12 ms-xxxl12">
                            <MarketDescription>
                                {marketSubscription?.description}.{!isSuscribed && <span>Dispone de 3 análisis gratuitos.</span>}  El coste del servicio es de {marketSubscription?.price}€/año.
                            </MarketDescription>
                        </ColMarketDescription>
                    </RowMarket>
                    {/* Últimos Análisis y buscar */}
                    <RowMarket className="ms-Grid-row">
                        <ColMarketAnalysis>
                            <MarketColLastAnalyisis>
                                <MarketColLastAnalyisisContainer>
                                    <MarketColLastAnalyisisContainerInner>
                                        <div className="shadow">
                                            <MarketSubTitle>
                                                {analysisTitle}
                                            </MarketSubTitle>
                                            <MarketSpacer></MarketSpacer>
                                            <MarketSearchCardsContainer>
                                                {showLastMarketItems &&
                                                    <MarketCardsCarousel
                                                        disabled={blockedCarousel}
                                                        marketItems={lastMarketItems}
                                                        onMarketCardClick={handleMarketCardClick}
                                                    />
                                                }
                                                {!showLastMarketItems &&
                                                    <MarketCardsCarousel
                                                        disabled={blockedUsedCarousel}
                                                        marketItems={usedMarketItems}
                                                        onMarketCardClick={handleUsedMarketCardClick}
                                                    />
                                                }
                                            </MarketSearchCardsContainer>
                                        </div>
                                    </MarketColLastAnalyisisContainerInner>
                                </MarketColLastAnalyisisContainer>
                            </MarketColLastAnalyisis>
                            <MarketColSearch>
                                <MarketColSearchContainer>
                                    <MarketColSearchContainerInner>
                                        <div className="shadow">
                                            <MarketSubTitle>
                                                Buscar
                                            </MarketSubTitle>
                                            <MarketDatePickerCont>
                                                <MarkerDatePickerInner>
                                                    <DatePicker
                                                        styles={DatePickerStyle}
                                                        label='Fecha de inicio'
                                                        id={'analysisStartDate'}
                                                        // @ts-ignore
                                                        name={'analysisStartDate'}
                                                        formatDate={(date) => {
                                                            return moment(date).format('DD/MM/YYYY');
                                                        }}
                                                        onSelectDate={(date) => {
                                                            if (date) {
                                                                const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
                                                                handleAnalysisDateInputChange('analysisStartDate', utcDate);
                                                            }
                                                        }}
                                                        value={analyisisDateRange.startDate! ? analyisisDateRange.startDate! : undefined}
                                                        maxDate={new Date()}
                                                        disabled={blockedDateSearch}
                                                        strings={DayPickerStrings}
                                                        allowTextInput={true}
                                                        parseDateFromString={(dateStr) => {
                                                            var parts: string[] = dateStr.split('/');
                                                            var myDate = new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));
                                                            return new Date(myDate)
                                                        }}
                                                    />
                                                    <ErrorMessage className="error">{dateErrors.analysisStartDate}</ErrorMessage>
                                                </MarkerDatePickerInner>
                                                <MarkerDatePickerInner>
                                                    <DatePicker
                                                        styles={DatePickerStyle}
                                                        label='Fecha de fin'
                                                        id={'analysisEndDate'}
                                                        // @ts-ignore
                                                        name={'analysisEndDate'}
                                                        formatDate={(date) => {
                                                            return moment(date).format('DD/MM/YYYY');
                                                        }}
                                                        onSelectDate={(date) => {
                                                            if (date) {
                                                                const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
                                                                handleAnalysisDateInputChange('analysisEndDate', utcDate);
                                                            }
                                                        }}
                                                        value={analyisisDateRange.endDate! ? analyisisDateRange.endDate! : undefined}
                                                        maxDate={new Date()}
                                                        disabled={blockedDateSearch}
                                                        strings={DayPickerStrings}
                                                        allowTextInput={true}
                                                        parseDateFromString={(dateStr) => {
                                                            var parts: string[] = dateStr.split('/');
                                                            var myDate = new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));
                                                            return new Date(myDate)
                                                        }}
                                                    />
                                                    <ErrorMessage className="error">{dateErrors.analysisEndDate}</ErrorMessage>
                                                </MarkerDatePickerInner>
                                                <SearchButtonAnalysis
                                                    text=""
                                                    onClick={handleAnalysisDatesClick}
                                                    disabled={isLoadingChange || searchDatesInProcess}
                                                >
                                                    {searchDatesInProcess && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                                                    <strong>Buscar</strong>
                                                </SearchButtonAnalysis>
                                            </MarketDatePickerCont>
                                            <MarketSearchCardsContainer>
                                                {showMarketItems &&
                                                    <MarketCardsCarousel
                                                        disabled={blockedCarousel}
                                                        marketItems={marketItems}
                                                        onMarketCardClick={handleMarketCardClick}
                                                    />
                                                }
                                            </MarketSearchCardsContainer>
                                        </div>
                                    </MarketColSearchContainerInner>
                                </MarketColSearchContainer>
                            </MarketColSearch>
                        </ColMarketAnalysis>
                    </RowMarket>


                    {/* Main errors */}
                    {mainError.message !== "" ? (
                        <RowMarket>
                            <ErrorMessage className="error">{mainError.message}</ErrorMessage>
                        </RowMarket>
                    ): (<></>)}
                    


                    {/* Análisis */}
                    {mainLoading ? (
                        // Mostrar spinner cuando mainLoading es true
                        <Spinner size={SpinnerSize.large} />
                    ) : (
                        // Mostrar contenido principal cuando mainLoading es false
                        <>
                            {showMarketManagementContainer && (
                                <RowMarket className="ms-Grid-row">
                                    <ColMarketAnalysis>
                                        <MarketColLastAnalyisis>
                                            <MarketColLastAnalyisisContainer>
                                                <MarketSubTitle>
                                                    Análisis{analysisName !== null && ": " + moment(analysisName).format('DD-MM-YYYY')}
                                                </MarketSubTitle>
                                            </MarketColLastAnalyisisContainer>
                                        </MarketColLastAnalyisis>

                                        <MarketColSearch>
                                            <MarketColSearchContainer>
                                                <MarketManagementContainer className="analysis-management">
                                                    {showDropdown ? (
                                                        dropdownOptions !== undefined && (
                                                            <>
                                                                {dropdownOptions.length > 0 && (
                                                                    <Dropdown
                                                                        className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl4 ms-xxl5 ms-xxxl6"
                                                                        options={dropdownOptions as IDropdownOption[]}
                                                                        selectedKeys={selectedActivePlansIds}
                                                                        onRenderTitle={handleRenderTitle}
                                                                        onChange={(event, item) => updateSelectedPlanKeys(event, item)}
                                                                        styles={dropdownStyles}
                                                                        disabled={disabledDropdown}
                                                                    />
                                                                )}
                                                                <ButtonAnalysis
                                                                    text="Análisis Cartera"
                                                                    onClick={handleAnalysisClick}
                                                                    disabled={isLoadingChange}
                                                                />
                                                            </>
                                                        )
                                                    ) : (
                                                        <i
                                                            className="ironia-icon cross"
                                                            onClick={handleCloseAnalysisClick}
                                                            style={{ cursor: 'pointer', fontSize: '24px' }}
                                                        ></i>
                                                    )}
                                                </MarketManagementContainer>
                                            </MarketColSearchContainer>
                                        </MarketColSearch>
                                    </ColMarketAnalysis>
                                </RowMarket>
                            )}
                            {showPdfViewer && (
                                <RowMarket className="ms-Grid-row">
                                    {pdfUrl !== null && <MarketAttachment fileName={pdfUrl} className="market-viewer" />}
                                </RowMarket>
                            )}
                            {showMarketReport && (
                                <>
                                    <RowMarket className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12">
                                            <div className="shadow">
                                              <AnalyisisContainer>
                                                <AnalyisisTableContainer>
                                                <div className="ms-Grid-row">
                                                    <div>
                                                        <h3>Detalles de tu cartera</h3>
                                                    </div>
                                                </div>
                                                <AnalyisisTableContainerInner>
                                                    {
                                                      isLoadingReporting ? (
                                                        <>
                                                          <Spinner size={SpinnerSize.large} />
                                                        </>
                                                      ) : (
                                                        <>
                                                        <PortfolioTable
                                                            reporting={reporting}
                                                            funds={funds} 
                                                            fundsforModelData={items => updateModelFunds(items)}
                                                            isResumeWallet={selectedFinametrixContracts.length > 1}
                                                            onCollectedItemsUpdate={handleCollectedItemsUpdate}
                                                            showActions={false}
                                                            showDocuments={false}
                                                            showIroniaPoints={false}
                                                        />
                                                        </>
                                                      )
                                                    }
                                                  </AnalyisisTableContainerInner>
                                                </AnalyisisTableContainer>
                                                <AnalyisisReportContainer>
                                                <div className="ms-Grid-row">
                                                    <div>
                                                        <h3>Análisis de tu cartera</h3>
                                                    </div>
                                                </div>
                                                <AnalyisisReportContainerInner>
                                                    {
                                                      analysisResponseChatGPT ? (
                                                          <AnalisysChatGPTContainer>
                                                              <div dangerouslySetInnerHTML={{ __html: analysisResponseChatGPT }} />
                                                          </AnalisysChatGPTContainer>
                                                      ) : (
                                                          <Spinner size={SpinnerSize.large} />
                                                      )
                                                    }
                                                  </AnalyisisReportContainerInner>
                                                </AnalyisisReportContainer>
                                              </AnalyisisContainer>
                                            </div>
                                        </div>
                                    </RowMarket>
                                </>
                            )}
                        </>
                    )}


                </React.Fragment>
            </>
        </DocumentTitle >
    );
}

export default Market;