import React from "react";
import { Link, useHistory } from "react-router-dom";
import styles from "./index.module.sass";
import { CompetitionType, CompetitionTypes } from "../../services/CompetitionsService";
import { currencyNeutreFormatter } from "../../utils/numberFormatter";
import { PrimaryButton } from "@fluentui/react";

interface CompetitionCardProps
{
    competition: CompetitionType;
}

const CompetitionCard: React.FC<CompetitionCardProps> = ({
    competition
}) =>
{
    const history = useHistory();

    const formatDate = (date: string) =>
    {
        const [month, day, year] = date.split("/");
        return `${day}/${month}/${year}`;
    };

    const createurl = (name: string): string =>
    {
        return name
            .toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-z0-9\s-]/g, "").replace(/\s+/g, "-")
            .replace(/-+/g, "-").trim();
    };

    function truncateHtml(html: string, limit: number): string {
        const textContent = html.replace(/<\/?[^>]+(>|$)/g, ""); // Elimina etiquetas HTML
        if (textContent.length > limit) {
          const truncatedText = textContent.substring(0, limit) + "...";
          return truncatedText;
        }
        return textContent;
      }

    return (
        <article
            className={`${styles.card} rounded background-background`}
            style={{ marginRight: "20px", boxShadow: "0 0 5px 3px rgb(0 0 0 / 8%)", paddingTop: "10px", paddingLeft: "16px", paddingRight: "16px", height: "220px",
                width: "390px"}}
        >
            <Link
                to={{
                    pathname: `/competition/${createurl(competition.name)}-${competition.id}`,
                    state: { competitionId: competition.id },
                }}
                className="color-black"
            >
                <header>
                    <div
                        style={{
                            display: "flex",
                            marginTop: "10px",
                            alignContent: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <p
                                style={{
                                    marginBottom: "0px",
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    wordBreak: "break-word",
                                }}
                            >
                                {competition.name}
                            </p>
                            <span className="color-neutralTertiaryAlt">
                                Del {formatDate(competition.startDate)} al {formatDate(competition.endDate)}
                            </span>
                        </div>
                        <span className={styles.type}>
                            Tipo: {CompetitionTypes[competition.type]}
                        </span>
                    </div>
                </header>
                <main
                    style={{
                        marginTop: "10px",
                        whiteSpace: "normal",
                        height: "100px"
                    }}
                    dangerouslySetInnerHTML={{
                        __html: truncateHtml(competition.description ?? "", 200),
                      }}
                />
            </Link>
            <div
                style={{
                    position: "relative",
                    width: "100%",
                    height: "190px",
                }}
            >
                <footer
                    style={{
                        backgroundColor: "white",
                        width: "100%",
                        fontSize: "14px",
                        fontWeight: "500",
                        display: "flex",
                        justifyContent: "space-between",
                        boxSizing: "border-box",
                    }}
                >
                    <div>
                    <span>
                        {competition.price === 0 ? (
                            <>Inscripción gratuita</>
                        ) : competition.typeOfPrice === 0 ? (
                            <>
                                {currencyNeutreFormatter.format(competition.price)} Elements IA
                            </>
                        ) : (
                            <>
                                {currencyNeutreFormatter.format(competition.price)} Elements Agua
                            </>
                        )}
                    </span>
                    {competition.price != 0 && <> <p>Participantes: {competition.competitionsUsers.length}</p>
                        <p className={styles.price}>
                            Bote:{" "}
                            {competition.typeOfPrice === 0 ? (
                                <>
                                    {currencyNeutreFormatter.format(
                                        competition.competitionsUsers.length * competition.price
                                    )}{" "}
                                    Elements IA
                                </>
                            ) : (
                                <>
                                    {currencyNeutreFormatter.format(
                                        competition.competitionsUsers.length * competition.price
                                    )}{" "}
                                    Elements Agua
                                </>
                            )}
                        </p></>}
                   
                        </div>
                    <PrimaryButton
                        className="rounded"
                        onClick={() =>
                        {
                            history.push(
                                `/competitions/join/nsv/${competition.id}`
                            );
                        }}
                    >
                        Participar
                    </PrimaryButton>
                </footer>
                {/* {competition.price !== 0 && (
                    <footer
                        style={{
                            backgroundColor: "white",
                            width: "100%",
                            fontSize: "14px",
                            fontWeight: "500",
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "0 10px",
                            boxSizing: "border-box",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            paddingTop: "20px",
                            position: "absolute",
                            bottom: "20px",
                        }}
                    >
                        
                    </footer>
                )} */}
            </div>
        </article>
    );
};

export default CompetitionCard;
