import { useEffect, useState } from "react";
import DocumentTitle from "react-document-title";
import CompetitionsService, {
    CompetitionType,
    CompetitionTypes,
} from "../../services/CompetitionsService";
import { PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";
import styles from "./index.module.sass";
import { currencyNeutreFormatter } from "../../utils/numberFormatter";
import { Link, useHistory } from "react-router-dom";
import ElementService from "../../services/ElementsService";
import Auth from "../../Auth/Auth";
import * as L from "./layout.styles";
import { Toggle } from "@fluentui/react/lib/Toggle";
import CompetitionCard from "../../components/competitionCard/CompetitionCard";

const Competitions = () =>
{
    const [competitions, setCompetitions] = useState<CompetitionType[]>([]);
    const [error, setError] = useState({ status: false, message: "" });
    const [loading, setLoading] = useState<boolean>(true);
    const [isUserElementsSubscribed, setIsUserElementsSubscribed] = useState<boolean | undefined>(undefined);
    const [selectedCompetitionFinished, setSelectedCompetitionFinished] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [currentPageCompetitionsFinished, setCurrentPageCompetitionsFinished] = useState(0);
    const history = useHistory();
    const activePlan = Auth.getActivePlan();
    const isUserSuscribed = Auth.isSubscribed();
    const elementsPerPage = 18;



    useEffect(() =>
    {
        CompetitionsService.getCompetitions().then((response: CompetitionType[]) =>
        {
            setCompetitions(response);
        },
            (error: any) =>
            {
                setError(error);
            }
        )
            .finally(() =>
            {
                setLoading(false);
            });

        if (activePlan.id != undefined)
        {
            ElementService.isSavingsPlanSubscribedToElements(activePlan.id).then((response: any) =>
            {
                setIsUserElementsSubscribed(response.isSubscribed);
            });
        }
    }, []);


    const totalPages = Math.max(1, Math.ceil(competitions.length / elementsPerPage));

    const totalPagesCompetitionsFinished = Math.max(1, Math.ceil(competitions.filter((c) => new Date(c.endDate).getTime() > Date.now()).length / elementsPerPage));

    const getCurrentPageElements = (): CompetitionType[] =>
    {
        const start = currentPage * elementsPerPage;

        //Este ejemplo solo funciona en produccion y no en local porque en la sql endDate es una Date y se devuelve como string
        let competitionsFilterNotFinishedCompetition = competitions.filter((c) => new Date(c.endDate).getTime() > Date.now());

        return competitionsFilterNotFinishedCompetition.slice(start, start + elementsPerPage);
    };

    const getCurrentPageElementsCompetitionsFinished = (): CompetitionType[] =>
    {
        const start = currentPageCompetitionsFinished * elementsPerPage;
        //Este ejemplo solo funciona en produccion y no en local porque en la sql endDate es una Date y se devuelve como string
        const competitionsFilterFinishedCompetition = competitions.filter((c) => new Date(c.endDate).getTime() < Date.now());

        return competitionsFilterFinishedCompetition.slice(start, start + elementsPerPage);
    };

    const handlePreviousPage = () =>
    {
        setCurrentPage((prev) => Math.max(prev - 1, 0));
    };

    const handleNextPage = () =>
    {
        setCurrentPage((prev) => Math.min(prev + 1, totalPages - 1));
    };

    const handlePreviousPageCompetitionsFinished = () =>
    {
        setCurrentPageCompetitionsFinished((prev) => Math.max(prev - 1, 0));
    };

    const handleNextPageCompetitionsFinished = () =>
    {
        setCurrentPageCompetitionsFinished((prev) =>
            Math.min(prev + 1, totalPagesCompetitionsFinished - 1)
        );
    };

    const handleToggleValueCompetitionChange = (
        event: React.MouseEvent<HTMLElement>,
        checked: boolean | undefined
    ) =>
    {
        setSelectedCompetitionFinished(checked ?? false);
    };

    const createurl = (name: string): string => {
        return name
            .toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-z0-9\s-]/g, "").replace(/\s+/g, "-") 
            .replace(/-+/g, "-").trim();
    };


    if (error.status)
    {
        return (
            <DocumentTitle title="IronIA - Competiciones - Versión Preliminar">
                <div>Error: {error.message}</div>
            </DocumentTitle>
        );
    }

    return (
        <DocumentTitle title="IronIA - Competiciones - Versión Preliminar">
            <section className={`${styles.index}`}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <div>
                        <h2>Competiciones - Versión Preliminar</h2>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                            style={{ display: "flex", marginRight: "10px", marginTop: "8px" }}
                        >
                            <p style={{ marginRight: "5px" }}>
                                Ver Competiciones Finalizadas
                            </p>
                            <Toggle
                                checked={selectedCompetitionFinished}
                                onChange={handleToggleValueCompetitionChange}
                            />
                        </div>
                        {isUserSuscribed && <Link to={"/competitions/new"} className="color-black">
                            <PrimaryButton
                                className="rounded"
                                style={{ marginTop: "0px", float: "right" }}
                                onClick={() => { }}
                            >
                                Crear Competición
                            </PrimaryButton>
                        </Link>}

                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12">
                        <p className="descriptioncompetition ">
                            ¿Quieres aprender jugando?
                        </p>
                        <p className="descriptioncompetition ">
                            Las competiciones de IronIA te permiten conocer el mercado de los fondos de inversión gracias a nuestro simulador. Crea carteras
                            modelo, sin ningún tipo de inversión real y compite en rentabilidad con otras carteras.
                        </p>

                        <p className="descriptioncompetition ">
                            La cartera más rentable gana... y lo que gana depende del reto.
                        </p>
                    </div>
                </div>
                {loading && <Spinner size={SpinnerSize.large} />}

                {!loading && (
                    <div
                        style={{
                            marginTop: "20px",
                            marginBottom: "26px",
                            paddingBottom: "20px",
                            paddingLeft: "20px",
                            display: "grid",
                            gridTemplateColumns: "repeat(3, 1fr)",
                            gap: "10px",
                            maxWidth: "100%", // Ensure it fits within the container
                            overflow: "hidden",
                        }}
                    >
                        {competitions !== undefined &&
                            competitions.length > 0 &&
                            (isUserElementsSubscribed
                                ? selectedCompetitionFinished
                                    ? getCurrentPageElementsCompetitionsFinished().map(
                                        (competition, competitionIndex) => (
                                            <CompetitionCard
                                            key={competition.id}
                                            competition={competition}
                                            />
                                        )
                                    )
                                    : getCurrentPageElements().map(
                                        (competition, competitionIndex) => (
                                            <CompetitionCard
                                            key={competition.id}
                                            competition={competition}
                                            />
                                        )
                                    )
                                : selectedCompetitionFinished
                                    ? getCurrentPageElementsCompetitionsFinished()
                                        .filter((c) => c.price == 0)
                                        .map((competition, competitionIndex) => (
                                            <CompetitionCard
                                    key={competition.id}
                                    competition={competition}
                                    />
                                        ))
                                    : getCurrentPageElements()
                                        .filter((c) => c.price == 0)
                                        .map((competition, competitionIndex) => (
                                            <CompetitionCard
                                            key={competition.id}
                                            competition={competition}
                                            />
                                        )))}
                    </div>
                )}
                {competitions != undefined && !loading ? (
                    selectedCompetitionFinished ? (
                        <L.PaginationContainer>
                            <L.PageCount>
                                <span>{`${currentPageCompetitionsFinished + 1} de ${totalPagesCompetitionsFinished}`}</span>
                            </L.PageCount>
                            <L.PageButtons>
                                <button
                                    onClick={handlePreviousPageCompetitionsFinished}
                                    disabled={currentPageCompetitionsFinished === 0}
                                    className={
                                        currentPageCompetitionsFinished === 0 ? "disabled" : ""
                                    }
                                >
                                    <i className={"ironia-icon keyboard-arrow-left"} />
                                </button>
                                <button
                                    onClick={handleNextPageCompetitionsFinished}
                                    disabled={
                                        currentPageCompetitionsFinished ===
                                        totalPagesCompetitionsFinished - 1
                                    }
                                    className={
                                        currentPageCompetitionsFinished ===
                                            totalPagesCompetitionsFinished - 1
                                            ? "disabled"
                                            : ""
                                    }
                                >
                                    <i className={"ironia-icon keyboard-arrow-right"} />
                                </button>
                            </L.PageButtons>
                        </L.PaginationContainer>
                    ) : (
                        <L.PaginationContainer>
                            <L.PageCount>
                                <span>{`${currentPage + 1} de ${totalPages}`}</span>
                            </L.PageCount>
                            <L.PageButtons>
                                <button
                                    onClick={handlePreviousPage}
                                    disabled={currentPage === 0}
                                    className={currentPage === 0 ? "disabled" : ""}
                                >
                                    <i className={"ironia-icon keyboard-arrow-left"} />
                                </button>
                                <button
                                    onClick={handleNextPage}
                                    disabled={currentPage === totalPages - 1}
                                    className={currentPage === totalPages - 1 ? "disabled" : ""}
                                >
                                    <i className={"ironia-icon keyboard-arrow-right"} />
                                </button>
                            </L.PageButtons>
                        </L.PaginationContainer>
                    )
                ) : (
                    <></>
                )}
            </section>
        </DocumentTitle>
    );
};

export default Competitions;
