import DocumentTitle from 'react-document-title';
import { useEffect, useState } from 'react';
import { Spinner, SpinnerSize } from '@fluentui/react';
import ContractDocsService, { ContractDocsVersionType } from '../../../services/ContractDocsService';
import styles from './index.module.sass';

const contractName = 'Contrato Elements';

export const ElementsFrameworkAgreement = () =>
{
    const [contractHTML, setContractHTML] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() =>
    {
        setIsLoading(true);
        ContractDocsService.getLastVersion(contractName).then((contractDocsVersion: ContractDocsVersionType) =>
        {
            setContractHTML(contractDocsVersion.text);
        })
        .finally(() =>
        {
            setIsLoading(false);
        })
    }, []);

    return(
        <DocumentTitle title="IronIA - Programa de Lealtad - IronIA Elements">
            <>
                <div className={`ms-Grid-row ${styles.content}`}>
                    <div className="ms-Grid-col">
                        {isLoading &&
                        (
                            <Spinner size={SpinnerSize.large} />
                        )}
                        <div dangerouslySetInnerHTML={{ __html: contractHTML }}></div>
                    </div>
                </div>
            </>
        </DocumentTitle>
    )
}