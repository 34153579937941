import { CartModesEP } from '../../screen/CartEducationalPlan/CartEducationalPlanScreen';
import {ADD_FUND, UPDATE_FUND, REMOVE_FUND, CLEAR_CART, MODEL_PERCENTAGE_TOTAL, UPDATE_FUND_MODEL_PERCENTAGE, CART_STATE, MODEL_PORTFOLIO_NAME, PUNCTUAL_CONTRIBUTION_PORTFOLIO_ID} from './Constants';

interface IActionLineEP
{
    fund: any
    amount: number,
    modelPercentage?: number
    readOnly?: boolean,
    userSuscriptionId?: number,
    savingsPlanId?: number,
    source?: string
}
interface IActionFundEP
{
    isin: string
}
export const addLineEP = (value: IActionLineEP) => async (dispatch: any) =>
{
    await dispatch(
    {
        type: ADD_FUND,
        payload: value
    });
};
export const updateLineEP = (value: IActionLineEP) => async (dispatch: any) =>
{
    await dispatch(
    {
        type: UPDATE_FUND,
        payload: value
    });
};
export const removeLineEP = (value: IActionFundEP) => async (dispatch: any) =>
{
    await dispatch(
    {
        type: REMOVE_FUND,
        payload: value
    });
};
export const clearCartEP = () => async (dispatch: any) =>
{
    await dispatch(
    {
        type: CLEAR_CART,
    });
};
export const updateModelPercentageLineEP = (value: IActionLineEP) => async (dispatch: any) =>
{
    await dispatch(
    {
        type: UPDATE_FUND_MODEL_PERCENTAGE,
        payload: value
    });
};
export const updateModelPercentageTotalEP = (value: Number) => async (dispatch: any) =>
{
    await dispatch(
    {
        type: MODEL_PERCENTAGE_TOTAL,
        payload: value
    });
};

export const cartStateEP = (value: CartModesEP) => async (dispatch: any) =>
    {
        await dispatch(
        {
            type: CART_STATE,
            payload: value
        });
    };

export const updateModelPortfolioNameEP = (value: string) => async (dispatch: any) =>
{
    await dispatch(
    {
        type: MODEL_PORTFOLIO_NAME,
        payload: value
    });
};

export const setPunctualContributionPortfolioDataEP = (value: any) => async (dispatch: any) =>
{
    await dispatch(
    {
        type: PUNCTUAL_CONTRIBUTION_PORTFOLIO_ID,
        payload: {name: value.name, id: value.id}
    });
}