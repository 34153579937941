import { useState } from "react";
import styled from "styled-components";
import { ActiveIcon, InactiveIcon } from "../../icons/IconsTable";
import { getScoreIcons } from "../../../utils/scoreToIcons";
import { TooltipHost } from "@fluentui/react";
import { ChildrenTable } from './ChildrenTable';
import { ChevronRightIcon, ChevronDownIcon } from '@fluentui/react-icons-mdl2';
import { translate } from "../../../utils/i18n";

const SubTableInternalStyled = styled.div`
    div
    {
        width: 100%;
        min-width: max-content;
        padding: 10px 0 !important;
    }
    margin: 15px;
    margin-left: 10px !important;
    table
    {
        color: ${props => props?.theme?.palette?.black};
        box-shadow: rgb(101 119 134 / 20%) 0px 0px 15px;
        border-radius: 16px;
        th div,
        td div
        {
            text-align: left;
            margin: 0 !important;
        }
        th,
        td
        {
            padding-left: 20px !important;
        }
    }
`;

const FundName = styled.div`
    .brothers-btn-icon
    {
        margin-right: 5px;
        vertical-align: baseline;
    }
`;

export const SubTableInternal = ({group, fund, publicVersion, theme, fromEF}) =>
{
    const [isExpanded, setIsExpanded] = useState(false);
    const iconArray = getScoreIcons(fund.children[0].averageScore);

    return(
        <>
            <tr>
                <td>
                    <FundName className="max-content">
                        <button style={{ marginLeft: "20px", color: theme.palette.black }} className="expanded" onClick={() => setIsExpanded(!isExpanded)}>
                            {fund?.children?.length > 0 && (isExpanded
                                ? <ChevronDownIcon className="brothers-btn-icon" />
                                : <ChevronRightIcon className="brothers-btn-icon" />
                            )}
                            {fund.family}
                            {fund?.children?.length > 0 && ` (${fund?.children?.length})`}
                        </button>
                    </FundName>
                </td>
                <td>{<p className="max-content">{translate(fund.children[0].manager)}</p>}</td>
                <td>{<p className="max-content">{fund.children[0].subcategory}</p>}</td>
                <td>
                    <TooltipHost content={`${parseFloat(fund.children[0].averageScore).toFixed(2)}/100`}>
                        <div className="max-content">
                            {iconArray.map((active, iconIndex) =>
                            {
                                return active ? <ActiveIcon key={'iconIndex' + iconIndex} /> : <InactiveIcon key={'iconIndex' + iconIndex} />;
                            })}
                        </div>
                    </TooltipHost>
                </td>
            </tr>
            {isExpanded &&
            (
                <tr className="container-table">
                    <td colSpan={4}>
                        <SubTableInternalStyled>
                            <ChildrenTable children={group.children} publicVersion={publicVersion} fromEF={fromEF} />
                        </SubTableInternalStyled>
                    </td>
                </tr>
            )}
        </>
    );
};