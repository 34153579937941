import axios from "axios";
import { proxyUrl } from "../config";
import { setupInterceptorsTo } from "./Interceptors";
setupInterceptorsTo(axios);

interface IMarketItem {
    id: any,
    fileUrl: string,
    imageUrl: string,
}

type FreeTrialAttemps = {
    userId: number,
    subscriptionId: number,
    marketReportFileUrl: string,
}

export type MarketResponseResponse =
    {
        items: IMarketItem[]
    }
export type DataverseType = IMarketItem;

class MarketService {

    public getMarketItems = async (startDate: string, endDate: string): Promise<MarketResponseResponse> => {
        if (!startDate || !endDate) {
            throw new Error("Both startDate and endDate must be provided.");
        }

        const url = `${proxyUrl}/market-reports?startDate=${startDate}&endDate=${endDate}`;

        try {
            const req = await axios.get(url);
            return req.data;
        } catch (error) {
            console.error("Error fetching Market Reports:", error);
            throw error;
        }
    }
    public getLastMarketItems = async (): Promise<MarketResponseResponse> => {
        let url: string = `${proxyUrl}/market-reports/last`;
        const req = await axios.get(url)
        return req.data
    }
    public getFreeTrialAttemps = async (userId: number): Promise<any> => {
        let url: string = `${proxyUrl}/market-reports/${userId}/free-trial-attepms`;
        const req = await axios.get(url);
        return req.data;
    };
    public postFreeTrialAttempt = async (userId: number, subscriptionId: number, marketReportFileUrl: string, savingPlanId: number): Promise<any> => {
        const url: string = `${proxyUrl}/market-reports/free-trial-attepms`;

        let formData = new FormData();
        const data = {
            userId: userId,
            subscriptionId: subscriptionId,
            marketReportFileUrl: marketReportFileUrl,
            savingPlanId: savingPlanId,
        } as FreeTrialAttemps;

        formData.append("data", JSON.stringify(data));
        const req = await axios.post(url, formData);
        return req.data;
    };
    public getAvailableReports = async (userId: number): Promise<any> => {
        let url: string = `${proxyUrl}/market-reports/${userId}/available`;
        const req = await axios.get(url);
        return req.data;
    };
    public postMarketAnalysisChatGPT = async (userId: number, subscriptionId: number, marketReportFileUrl: string, reporting: string): Promise<any> => {
        const url: string = `${proxyUrl}/marketAnalysisisChatGPT`;

        let formData = new FormData();
        const data = {
            userId: userId,
            subscriptionId: subscriptionId,
            marketReportFileUrl: marketReportFileUrl,
            reporting: reporting,
        } as FreeTrialAttemps;
        formData.append("data", JSON.stringify(data));
        const req = await axios.post(url, formData);
        return req.data;
    };
    public getMarketReportAnalysis = async (userId: number, marketReportFileUrl: string, savingPlanId: number): Promise<any> => {
        let url: string = `${proxyUrl}/market-reports/${userId}/get-analysis/${marketReportFileUrl}/${savingPlanId}`;
        const req = await axios.get(url);
        return req.data;
    };
    public patchAttempt = async (attempt: any): Promise<any> =>
    {
        let url: string = `${proxyUrl}/market-reports/attempt/${attempt.id}`
        const req = await axios.patch(url, attempt);
        return req.data;
    }    
    public getSavingPlanIdByMarketCard = async (userId: number,  savingPlanId: number, marketReportFileUrl: string): Promise<any> => {
        let url: string = `${proxyUrl}/market-reports/${userId}/saving-plan/${savingPlanId}/${marketReportFileUrl}`;
        const req = await axios.get(url);
        return req.data;
    };


}

const news = new MarketService();
export default news;