import axios from "axios";
import { proxyUrl } from "../config";
import { UserType } from "./UserService";
import { ISuitabilityTest } from "./SuitabilityTestsService";
import { setupInterceptorsTo } from "./Interceptors";
setupInterceptorsTo(axios);

interface IUserIdentity {
    id?: number,
    firstName?: string,
    lastName?: string,
    secondLastName?: string,
    idDocument?: string,
    idExpiration?: Date,
    birthday?: Date,
    birthdayCountry?: string,
    nationalCountry?: string,
    idType?: any,
    idFront?: any,
    idBack?: any,
    accountType?: string,
    proofOfMinorGuardianDescription?: any,
    email?: string,
    countryPrefix?: string,
    phone?: string,
    operationCountryPrefix?: string,
    operationPhone?: string,
    signCountryPrefix?: string,
    signPhone?: string,
    economicActivity?: number,
    proofOfIncome?: any,
    profession?: number,
    verificationCode?: string,
    verificationCodeSentAt?: Date,
    signCode?: string,
    signCodeSentAt?: Date,
    gafiQuestion?: boolean,
    publicPosition?: boolean,
    fiscalAddressId?: string,
    postalAddressId?: string,
    sameAddress?: boolean,
    main?: boolean,
    finametrixId?: string,
    updatedAt?: Date,
    savingsPlansUserIdentities?: any,
    economicStudies?: any,
    financialExperience?: any,
    investmentExperience?: any,
    fiscalAddress?: any,
    postalAddress?: any,
    status?: string,
    suitabilityTests?: ISuitabilityTest[],
    userId?: number,
    maxRiskAllowed?: number,
    user?: UserType,
    createdAt?: Date,
    fnmContractsUserIdentities?: any[],
    canActivateSuitabilityTestButton?: boolean,
}

export type UserIdentityType = IUserIdentity

export const AccountTypes =
    [
        {
            key: "0",
            name: "Titular principal",
            value: "owner"
        },
        {
            key: "1",
            name: "Titular",
            value: "authorized"
        },
        {
            key: "2",
            name: "Representante legal",
            value: "representative"
        },
        {
            key: "3",
            name: "Titular menor de edad",
            value: "minor"
        }
    ];

class UserIdentitiesService {

    public getUserIdentity = async (userIdentityId: number): Promise<UserIdentityType> => {
        let url: string = `${proxyUrl}/userIdentities/${userIdentityId}`;
        const req = await axios.get<UserIdentityType>(url);
        return req.data;
    }
    public getUserIdentityWithSuitabilityTests = async (userIdentityId: number): Promise<UserIdentityType> => {
        let url: string = `${proxyUrl}/userIdentities/${userIdentityId}?includes=SuitabilityTests`;
        const req = await axios.get<UserIdentityType>(url);
        return req.data;
    }
    public createUserIdentity = async (userIdentity: UserIdentityType): Promise<UserIdentityType> => {
        if (userIdentity.sameAddress === undefined || userIdentity.sameAddress) {
            delete userIdentity.fiscalAddress;
            delete userIdentity.fiscalAddressId;
        }
        let url: string = `${proxyUrl}/userIdentities`;
        let formData = new FormData();
        let data: any = {};
        Object.keys(userIdentity).forEach((field) => {
            // @ts-ignore
            if (userIdentity[field]?.size !== undefined) {
                // @ts-ignore
                formData.append(field, userIdentity[field]);
            }
            else {
                // @ts-ignore
                data[field] = userIdentity[field];
            }
        });

        formData.append("data", JSON.stringify(data));
        const req = await axios.post(url, formData,
            {
                headers: { 'content-type': 'multipart/form-data' },
            })
        return req.data;
    }
    public patch = async (userIdentityId: string, data: any): Promise<boolean> => {
        let url: string = `${proxyUrl}/userIdentities/${userIdentityId}`;
        const req = await axios.patch(url, data);
        return req.data;
    }
    public updateUserIdentity = async (userIdentity: UserIdentityType): Promise<UserIdentityType> => {
        if (userIdentity.sameAddress === null || userIdentity.sameAddress) {
            delete userIdentity.fiscalAddress;
            delete userIdentity.fiscalAddressId;
        }
        let url: string = `${proxyUrl}/userIdentities/${userIdentity.id}`;
        let formData = new FormData();
        let data: any = {};
        Object.keys(userIdentity).forEach((field) => {
            // @ts-ignore
            if (userIdentity[field]?.size !== undefined) {
                // @ts-ignore
                formData.append(field, userIdentity[field]);
            }
            else {
                // @ts-ignore
                data[field] = userIdentity[field];
            }
        });

        formData.append("data", JSON.stringify(data));
        const req = await axios.put(url, formData,
            {
                headers: { 'content-type': 'multipart/form-data' },
            });
        return req.data;
    }
    public getIdentitiesBySavingsPlanId = async (savingsPlanId: number): Promise<UserIdentityType[]> => {
        let url: string = `${proxyUrl}/userIdentities/getBySavingsPlanId/${savingsPlanId}`
        const req = await axios.get(url)
        return req.data
    }
    public getIdentitiesByUserId = async (userId: number): Promise<UserIdentityType[]> => {
        let url: string = `${proxyUrl}/userIdentities/getByUserId/${userId}`
        const req = await axios.get(url)
        return req.data
    }
    public getLastIdentityByUserId = async (userId: number): Promise<UserIdentityType> => {
        let url: string = `${proxyUrl}/userIdentities/getLastByUserId/${userId}`
        const req = await axios.get(url)
        return req.data
    }
    public getRefundCode = async (userId: number): Promise<boolean> => {
        let url: string = `${proxyUrl}/userIdentities/${userId}/getRefundCode`;
        const req = await axios.get(url);
        return req.data;
    }
    public isRefundCodeDefined = async (userIdentityId: number): Promise<boolean> => {
        let url: string = `${proxyUrl}/userIdentities/${userIdentityId}/isRefundCodeDefined`;
        const req = await axios.get(url);
        return req.data;
    }
    public checkByIdDocumentAndBirthday = async (userIdentityId: number, toSend: any): Promise<boolean> => {
        let url: string = `${proxyUrl}/userIdentities/${userIdentityId}/checkByIdDocumentAndBirthday`;
        const req = await axios.post<any>(url, toSend);
        return req.data;
    }
    public addSuitabilityTestSignatureToUserIdentity = async (userIdentityId: number): Promise<UserIdentityType> => {
        let url: string = `${proxyUrl}/userIdentities/${userIdentityId}/suitabilityTestSignatures`;
        const req = await axios.post<any>(url, {});
        return req.data;
    }
    public validateUniqueDocument = async (finametrixId: string, idDocument: string, id: number): Promise<boolean> => {
        let url: string = `${proxyUrl}/userIdentities/validateUniqueDocument/${finametrixId}/${idDocument}/${id}`;
        const req = await axios.get(url);
        return req.data;
    }
    public getInstrumentsIds = async (userIdentityId: number): Promise<string[]> => {
        let url: string = `${proxyUrl}/userIdentities/${userIdentityId}/instrumentsIds`;
        const req = await axios.get(url);
        return req.data;
    }
    public userIdentityExists = async (idDocument: string): Promise<boolean> => {
        let url: string = `${proxyUrl}/userIdentities/${idDocument}/verify`;
        const req = await axios.get(url);
        return req.data;
    }
    public AMLVerification = async (userIdentityId: number, idDocument: string): Promise<any> => {
        let url: string = `${proxyUrl}/userIdentities/${userIdentityId}/aml-verification/${idDocument}`;
        const req = await axios.get(url);
        return req.data;
    }
    public deleteUserIdentity = async (userIdentityId: number): Promise<boolean> => {
        let url: string = `${proxyUrl}/userIdentities/${userIdentityId}`;
        const req = await axios.delete(url);
        return req.data;
    }
    public getUSerIdentityByIdDocument = async (idDocument: string): Promise<boolean> => {
        let url: string = `${proxyUrl}/userIdentities/getByIdocument/${idDocument}`;
        const req = await axios.get(url);
        return req.data;
    }
}

const userIdentities = new UserIdentitiesService();
export default userIdentities;