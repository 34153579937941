import { useEffect, useState } from "react";
import DocumentTitle from "react-document-title";
import CompetitionsService, { CompetitionType,
} from "../../services/CompetitionsService";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { useHistory } from "react-router-dom";
import * as L from "./layout.styles";
import CompetitionCard from "../../components/competitionCard/CompetitionCard";

type Competition = {
    id: number;
    name: string;
};

const CompetitionsPromotioned = () =>
{
    const [competitions, setCompetitions] = useState<CompetitionType[]>([]);
    const [error, setError] = useState({ status: false, message: "" });
    const [loading, setLoading] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState(0);
    const history = useHistory();

    const elementsPerPage = 18;

    useEffect(() =>
    {
        CompetitionsService.getCompetitionsPromotionated().then((response: CompetitionType[]) =>
        {
            setCompetitions(response);
        },
            (error: any) =>
            {
                setError(error);
            }
        )
            .finally(() =>
            {
                setLoading(false);
            });
    }, []);

    const createurl = (name: string): string =>
    {
        return name
            .toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-z0-9\s-]/g, "").replace(/\s+/g, "-")
            .replace(/-+/g, "-").trim();
    };

    const totalPages = Math.max(1, Math.ceil(competitions.length / elementsPerPage));

    const getCurrentPageElements = (): CompetitionType[] =>
    {
        const start = currentPage * elementsPerPage;

        //Este ejemplo solo funciona en produccion y no en local porque en la sql endDate es una Date y se devuelve como string
        let competitionsFilterNotFinishedCompetition = competitions.filter((c) => new Date(c.endDate).getTime() > Date.now());


        return competitionsFilterNotFinishedCompetition.slice(start, start + elementsPerPage);
    };

    const handlePreviousPage = () =>
    {
        setCurrentPage((prev) => Math.max(prev - 1, 0));
    };

    const handleNextPage = () =>
    {
        setCurrentPage((prev) => Math.min(prev + 1, totalPages - 1));
    };

    if (error.status)
    {
        return (
            <DocumentTitle title="IronIA - Competiciones - Versión Preliminar">
                <div>Error: {error.message}</div>
            </DocumentTitle>
        );
    }

    return (
        <DocumentTitle title="IronIA - Competiciones - Versión Preliminar">
            <section style={{maxWidth: "1450px", margin: "0 auto 20px"}}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        
                    }}
                >
                    <div>
                        <h2>Competiciones</h2>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12">
                        <p className="descriptioncompetition ">
                            ¿Quieres aprender jugando?
                        </p>
                        <p className="descriptioncompetition ">
                            Las competiciones de IronIA te permiten conocer el mercado de los fondos de inversión gracias a nuestro simulador. Crea carteras
                            modelo, sin ningún tipo de inversión real y compite en rentabilidad con otras carteras.
                        </p>

                        <p className="descriptioncompetition ">
                            La cartera más rentable gana... y lo que gana depende del reto.
                        </p>
                    </div>
                </div>
                {loading && <Spinner size={SpinnerSize.large} />}

                {!loading && (
                    <div
                        style={{
                            marginTop: "20px",
                            marginBottom: "26px",
                            paddingBottom: "20px",
                            paddingLeft: "20px",
                            display: "grid",
                            gridTemplateColumns: "repeat(3, 1fr)",
                            gap: "10px",
                            maxWidth: "100%",
                            overflow: "hidden",
                        }}
                    >
                        {competitions !== undefined &&
                            competitions.length > 0 &&
                            getCurrentPageElements()
                                .map((competition, competitionIndex) => <CompetitionCard
                                    key={competition.id}
                                    competition={competition}
                                    />
                        )}
                    </div>
                )}
                {competitions != undefined && !loading ? ((
                    <L.PaginationContainer>
                        <L.PageCount>
                            <span>{`${currentPage + 1} de ${totalPages}`}</span>
                        </L.PageCount>
                        <L.PageButtons>
                            <button
                                onClick={handlePreviousPage}
                                disabled={currentPage === 0}
                                className={currentPage === 0 ? "disabled" : ""}
                            >
                                <i className={"ironia-icon keyboard-arrow-left"} />
                            </button>
                            <button
                                onClick={handleNextPage}
                                disabled={currentPage === totalPages - 1}
                                className={currentPage === totalPages - 1 ? "disabled" : ""}
                            >
                                <i className={"ironia-icon keyboard-arrow-right"} />
                            </button>
                        </L.PageButtons>
                    </L.PaginationContainer>
                )
                ) : (
                    <></>
                )}
            </section>
        </DocumentTitle>
    );
};

export default CompetitionsPromotioned;
