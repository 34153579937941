/* eslint-disable array-callback-return */
import React, { useState, useEffect, useCallback } from "react";
import Cookies from 'universal-cookie';
import { DefaultButton, MessageBar } from "office-ui-fabric-react";
import { TeachingBubble } from "office-ui-fabric-react/lib/TeachingBubble";
import { useHistory, Switch, Link } from "react-router-dom";
import Toggle from "./Toggle";
import PersonStatus from "./PersonStatus";
import { Route } from "react-router-dom";
import PortfolioMenu from "../screen/Portfolios/PortfolioMenu";
import SavingsPlansMenu from "./SavingsPlans/SavingsPlansMenu";
import SearchMenu from "../components/Instruments/Menu";
import IframeSearchMenu from "../components/Instruments/IframeMenu";
import ElementsMenu from "../components/Elements/ElementsMenu";
import "./Header.sass";
import { MainSearch } from "./Buscadores/main/MainSearch";
import Auth from "../Auth/Auth";
import ProfileMenu from "../screen/Profile/ProfileMenu";
import { connect, useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { ReactComponent as SpacerIcon } from "../images/spacer.svg";
import * as Element from "./HeaderStyles";
import { IIconProps, MessageBarType } from "@fluentui/react";
import StatusService, { StatusBarModel } from "../services/StatusBarService";
import { USER_ACTIVE_PLAN, SAVINGS_PLAN_ELEMENTS_STATUS } from "../store/auth/Constants";
import { SavingsPlanElementsStatus } from "../services/ElementsService";

interface CartButtonProps
{
    cartLines: any[];
}

function mapCartStateToProps(state: any)
{
    return { cartLines: state.cart.cartLines };
}

function mapStateToProps(state: any)
{
    return {headerVisible: state.ui.headerVisible};
}
function compareCookieAndResponse(bigList: StatusBarModel[], shortList: StatusBarModel[])
{
    return bigList.filter(({id: id1}) => !shortList.some(({id: id2}) => id2 === id1));
}

const Header = (props: any) =>
{
    const user: any = Auth.getUserProfile();
    const activePlan: any = Auth.getActivePlan();
    const activeSavingsPlanHasOutdatedcontract = useSelector((state: RootStateOrAny) => state.auth.outdatedContract);
    const savingsPlanElementsStatus = useSelector((state: RootStateOrAny) => state.auth.savingsPlanElementsStatus) as SavingsPlanElementsStatus;
    const dispatch = useDispatch();
    const cookies = new Cookies();
    const theme = props.theme;
    let history = useHistory();
    const warningIcon: IIconProps = {iconName: "Warning12"};
    const [bubbleVisible, setBubbleVisible] = useState<boolean>(Auth.bubbleVisible());
    const isActiveManagementPlan = Auth.isActiveManagementPlan();
    const [statusBarElements, setStatusBarElements] = useState<StatusBarModel[]>();

    const checkActiveSavingsPlan = useCallback(async () =>
    {
        await dispatch(
        {
            type: USER_ACTIVE_PLAN,
            payload: Auth.getActivePlan()
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        if(!activePlan?.sync)
        {
            checkActiveSavingsPlan();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkSavingsPlanElementsStatus = useCallback(async () =>
    {
        await dispatch(
        {
            type: SAVINGS_PLAN_ELEMENTS_STATUS,
            payload: Auth.getSavingsPlanElementsStatus()
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        if(!savingsPlanElementsStatus.sync)
        {
            checkSavingsPlanElementsStatus();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        if(user.id !== undefined)
        {
            StatusService.GetAllStatusBarEnabled(user.id).then((response: StatusBarModel[]) =>
            {
                var cookie = cookies.get("statusBarNotifications") ?? [];
                if(cookie.length !== 0)
                {
                    if(response.length === cookie.length)
                    {
                        setStatusBarElements(cookie);
                    }
                    else if(response.length > cookie.length)
                    {
                        var items: StatusBarModel[] = compareCookieAndResponse(response, cookie);
                        if(items.length > 1)
                        {
                            for(let i=0; i<items.length; i++)
                            {
                                cookie.push(items[i]);
                            }
                        }
                        else
                        {
                            cookie.push(items[0]);
                        }

                        cookie.sort((a: StatusBarModel, b: StatusBarModel) => b.severityOrder-a.severityOrder);
                        cookies.set("statusBarNotifications", cookie, {path: "/"});
                        
                        setStatusBarElements(cookie);
                    }
                    else
                    {
                        var itemsCompared: StatusBarModel[] = compareCookieAndResponse(cookie, response);
                        if(itemsCompared.length > 1)
                        {
                            for(let i=0; i<itemsCompared.length; i++)
                            {
                                var idx = cookie.indexOf(itemsCompared[i]);
                                cookie.splice(idx,1);
                            }
                        }
                        else
                        {
                            var index = cookie.indexOf(itemsCompared[0]);
                            cookie.splice(index, 1);
                        }

                        cookie.sort((a: StatusBarModel, b: StatusBarModel) => b.severityOrder-a.severityOrder);
                        cookies.set("statusBarNotifications", cookie, {path: "/"});
                        
                        setStatusBarElements(cookie);
                    }
                }
                else
                {
                    cookies.set("statusBarNotifications", response, {path: "/"});
                    setStatusBarElements(response);
                }
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.id]);

    const onDeleteNotification = (index: number) =>
    {
        if(statusBarElements === undefined || statusBarElements.length === 0)
        {
            var cookie = cookies.get("statusBarNotifications") ?? [];
            if(cookie.length !== 0)
            {
                var statusModel: StatusBarModel[] = cookie;
                setStatusBarElements(statusModel.filter(item => item.id !== index));
                cookies.set("statusBarNotifications", statusModel.filter(item => item.id !== index), {path: "/"});
            }

            StatusService.setReadNotification(user.id);
        }
        else
        {
            setStatusBarElements(statusBarElements?.filter(item => item.id !== index));
            StatusService.setReadNotification(user.id);
            cookies.set("statusBarNotifications", statusBarElements?.filter(item => item.id !== index), {path: "/"});
        }
    }

    if(!props.headerVisible)
    {
        return(
            <React.Fragment>
                <Element.HeaderDiv id="header">
                    <Switch>
						<Route path="/iframe/search" component={IframeSearchMenu} />
					</Switch>
                </Element.HeaderDiv>
            </React.Fragment>
        );
    }
    const handleHomeButtonClick = () =>
    {
        history.push("/");
    };
    const handleMenuButtonClick = (event: any) =>
    {
        event.preventDefault();
        props.menuRef.current.showMenu();
    };
    const handleStatusChange = () =>
    {
        setBubbleVisible(false);
    };

    const CartButton = connect(mapCartStateToProps)((props: CartButtonProps) =>
    {
        const isActiveManagementPlan = Auth.isActiveManagementPlan();
        const isUserSuscribed = Auth.isSubscribed();

        if(isActiveManagementPlan === true)
        {
            return <></>;
        } 
        else if(!isUserSuscribed){
            return(
                <Link to='/cart-ef'>
                    <Element.DivCartButton>
                        <Element.DivBadgeButton className="badge">{props.cartLines?.length || 0}</Element.DivBadgeButton>
                        <DefaultButton title="Carrito">
                            <i className="ironia-icon shopping-cart" />
                        </DefaultButton>
                    </Element.DivCartButton>
                </Link>
            );
        }
        else
        {
            return(
                <Link to='/cart'>
                    <Element.DivCartButton>
                        <Element.DivBadgeButton className="badge">{props.cartLines?.length || 0}</Element.DivBadgeButton>
                        <DefaultButton title="Carrito">
                            <i className="ironia-icon shopping-cart" />
                        </DefaultButton>
                    </Element.DivCartButton>
                </Link>
            );
        }
    });

    return(
        <Element.HeaderMain id="header">
            <Element.RowHeader className="ms-Grid-row">
                <Element.ColNineDots className="ms-Grid-col">
                    <Element.ButtonDots title="Mostrar menú" id="show-menu" onClick={handleMenuButtonClick}>
                        <i className="ironia-icon functions" />
                    </Element.ButtonDots>
                </Element.ColNineDots>
                <Element.ColSeparator className="ms-Grid-col">
                    <SpacerIcon className="spacer" />
                </Element.ColSeparator>
                <Element.ColImageLogo className="ms-Grid-col">
                    <Element.ButtonLogo className="home-link" title="Página principal" onClick={handleHomeButtonClick}>
                        {theme.name === "dark" &&
                        (
                            <React.Fragment>
                                {window.innerWidth === 320 &&
                                (
                                    <svg width="190" height="55" viewBox="0 0 200 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M61.2153 41.7407V22.2344H65.9958V41.7407H61.2153Z" fill="#2A4143" />
                                        <path d="M108.96 41.7407V22.2344H113.053L121.46 34.158V22.2344H126.048V41.7407H121.955L113.548 29.8171V41.7407H108.96Z" fill="#2A4143" />
                                        <path d="M128.6 41.7407V22.2344H133.381V41.7407H128.6Z" fill="#CC214F" />
                                        <path d="M135.385 41.7407L143.105 22.2344H148.188L155.908 41.7407H150.962L149.589 37.9493H141.539L140.165 41.7407H135.385ZM142.995 33.9382H148.133L145.55 26.8225L142.995 33.9382Z" fill="#CC214F" />
                                        <path d="M36.3752 27.1719L28.0524 40.9062L47.9866 40.9062L45.7971 37.5225" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M43.1375 33.459L42.5045 32.4808L41.6551 31.2028" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M40.7812 24.875C40.7812 26.5836 39.3961 27.9688 37.6875 27.9688C35.9789 27.9688 34.5938 26.5836 34.5938 24.875C34.5938 23.1664 35.9789 21.7812 37.6875 21.7812C39.3961 21.7812 40.7812 23.1664 40.7812 24.875ZM36.7781 24.875C36.7781 25.3773 37.1852 25.7844 37.6875 25.7844C38.1898 25.7844 38.5969 25.3773 38.5969 24.875C38.5969 24.3727 38.1898 23.9656 37.6875 23.9656C37.1852 23.9656 36.7781 24.3727 36.7781 24.875Z" fill="#2A4143" />
                                        <path d="M82.3294 33.4265L85.6729 41.7296H80.4636L77.5661 34.318H74.0205V41.7296H69.2837V22.252H79.1822C82.4977 22.252 85.2284 24.9547 85.2284 28.2703C85.2257 29.3057 84.9578 30.3233 84.4503 31.2259C83.9429 32.1285 83.2127 32.8861 82.3294 33.4265ZM80.4622 28.2717C80.4583 27.933 80.322 27.6093 80.0826 27.3699C79.8431 27.1304 79.5194 26.9941 79.1807 26.9902H74.0205V29.5816H79.1822C79.8788 29.5816 80.4636 28.9963 80.4636 28.2717H80.4622Z" fill="#2A4143" />
                                        <path d="M106.626 31.9497C106.628 33.26 106.371 34.5577 105.87 35.7685C105.369 36.9793 104.635 38.0795 103.708 39.0061C102.782 39.9326 101.681 40.6673 100.471 41.1682C99.2598 41.669 97.9621 41.9261 96.6518 41.9248C91.1629 41.9248 86.6768 37.4666 86.6768 31.9497C86.6768 26.4607 91.1605 21.9746 96.6518 21.9746C102.169 21.9746 106.626 26.4607 106.626 31.9497ZM101.89 31.9497C101.891 31.2616 101.756 30.5801 101.493 29.9442C101.23 29.3083 100.844 28.7306 100.358 28.244C99.871 27.7574 99.2932 27.3716 98.6573 27.1086C98.0214 26.8457 97.3399 26.7107 96.6518 26.7114C93.7818 26.7114 91.4136 29.0521 91.4136 31.9497C91.4136 34.8472 93.7818 37.1879 96.6518 37.1879C97.3399 37.1887 98.0214 37.0537 98.6573 36.7907C99.2932 36.5278 99.871 36.1419 100.358 35.6554C100.844 35.1688 101.23 34.591 101.493 33.9552C101.756 33.3193 101.891 32.6378 101.89 31.9497Z" fill="#2A4143" />
                                    </svg>
                                )}
                                {window.innerWidth > 320 && window.innerWidth < 400 &&
                                (
                                    <svg width="150" height="55" viewBox="0 0 180 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M61.2153 41.7407V22.2344H65.9958V41.7407H61.2153Z" fill="#2A4143" />
                                        <path d="M108.96 41.7407V22.2344H113.053L121.46 34.158V22.2344H126.048V41.7407H121.955L113.548 29.8171V41.7407H108.96Z" fill="#2A4143" />
                                        <path d="M128.6 41.7407V22.2344H133.381V41.7407H128.6Z" fill="#CC214F" />
                                        <path d="M135.385 41.7407L143.105 22.2344H148.188L155.908 41.7407H150.962L149.589 37.9493H141.539L140.165 41.7407H135.385ZM142.995 33.9382H148.133L145.55 26.8225L142.995 33.9382Z" fill="#CC214F" />
                                        <path d="M36.3752 27.1719L28.0524 40.9062L47.9866 40.9062L45.7971 37.5225" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M43.1375 33.459L42.5045 32.4808L41.6551 31.2028" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M40.7812 24.875C40.7812 26.5836 39.3961 27.9688 37.6875 27.9688C35.9789 27.9688 34.5938 26.5836 34.5938 24.875C34.5938 23.1664 35.9789 21.7812 37.6875 21.7812C39.3961 21.7812 40.7812 23.1664 40.7812 24.875ZM36.7781 24.875C36.7781 25.3773 37.1852 25.7844 37.6875 25.7844C38.1898 25.7844 38.5969 25.3773 38.5969 24.875C38.5969 24.3727 38.1898 23.9656 37.6875 23.9656C37.1852 23.9656 36.7781 24.3727 36.7781 24.875Z" fill="#2A4143" />
                                        <path d="M82.3294 33.4265L85.6729 41.7296H80.4636L77.5661 34.318H74.0205V41.7296H69.2837V22.252H79.1822C82.4977 22.252 85.2284 24.9547 85.2284 28.2703C85.2257 29.3057 84.9578 30.3233 84.4503 31.2259C83.9429 32.1285 83.2127 32.8861 82.3294 33.4265ZM80.4622 28.2717C80.4583 27.933 80.322 27.6093 80.0826 27.3699C79.8431 27.1304 79.5194 26.9941 79.1807 26.9902H74.0205V29.5816H79.1822C79.8788 29.5816 80.4636 28.9963 80.4636 28.2717H80.4622Z" fill="#2A4143" />
                                        <path d="M106.626 31.9497C106.628 33.26 106.371 34.5577 105.87 35.7685C105.369 36.9793 104.635 38.0795 103.708 39.0061C102.782 39.9326 101.681 40.6673 100.471 41.1682C99.2598 41.669 97.9621 41.9261 96.6518 41.9248C91.1629 41.9248 86.6768 37.4666 86.6768 31.9497C86.6768 26.4607 91.1605 21.9746 96.6518 21.9746C102.169 21.9746 106.626 26.4607 106.626 31.9497ZM101.89 31.9497C101.891 31.2616 101.756 30.5801 101.493 29.9442C101.23 29.3083 100.844 28.7306 100.358 28.244C99.871 27.7574 99.2932 27.3716 98.6573 27.1086C98.0214 26.8457 97.3399 26.7107 96.6518 26.7114C93.7818 26.7114 91.4136 29.0521 91.4136 31.9497C91.4136 34.8472 93.7818 37.1879 96.6518 37.1879C97.3399 37.1887 98.0214 37.0537 98.6573 36.7907C99.2932 36.5278 99.871 36.1419 100.358 35.6554C100.844 35.1688 101.23 34.591 101.493 33.9552C101.756 33.3193 101.891 32.6378 101.89 31.9497Z" fill="#2A4143" />
                                    </svg>
                                )}
                                {window.innerWidth > 401 && window.innerWidth < 480 &&
                                (
                                    <svg width="150" height="55" viewBox="0 0 180 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M61.2153 41.7407V22.2344H65.9958V41.7407H61.2153Z" fill="#2A4143" />
                                        <path d="M108.96 41.7407V22.2344H113.053L121.46 34.158V22.2344H126.048V41.7407H121.955L113.548 29.8171V41.7407H108.96Z" fill="#2A4143" />
                                        <path d="M128.6 41.7407V22.2344H133.381V41.7407H128.6Z" fill="#CC214F" />
                                        <path d="M135.385 41.7407L143.105 22.2344H148.188L155.908 41.7407H150.962L149.589 37.9493H141.539L140.165 41.7407H135.385ZM142.995 33.9382H148.133L145.55 26.8225L142.995 33.9382Z" fill="#CC214F" />
                                        <path d="M36.3752 27.1719L28.0524 40.9062L47.9866 40.9062L45.7971 37.5225" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M43.1375 33.459L42.5045 32.4808L41.6551 31.2028" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M40.7812 24.875C40.7812 26.5836 39.3961 27.9688 37.6875 27.9688C35.9789 27.9688 34.5938 26.5836 34.5938 24.875C34.5938 23.1664 35.9789 21.7812 37.6875 21.7812C39.3961 21.7812 40.7812 23.1664 40.7812 24.875ZM36.7781 24.875C36.7781 25.3773 37.1852 25.7844 37.6875 25.7844C38.1898 25.7844 38.5969 25.3773 38.5969 24.875C38.5969 24.3727 38.1898 23.9656 37.6875 23.9656C37.1852 23.9656 36.7781 24.3727 36.7781 24.875Z" fill="#2A4143" />
                                        <path d="M82.3294 33.4265L85.6729 41.7296H80.4636L77.5661 34.318H74.0205V41.7296H69.2837V22.252H79.1822C82.4977 22.252 85.2284 24.9547 85.2284 28.2703C85.2257 29.3057 84.9578 30.3233 84.4503 31.2259C83.9429 32.1285 83.2127 32.8861 82.3294 33.4265ZM80.4622 28.2717C80.4583 27.933 80.322 27.6093 80.0826 27.3699C79.8431 27.1304 79.5194 26.9941 79.1807 26.9902H74.0205V29.5816H79.1822C79.8788 29.5816 80.4636 28.9963 80.4636 28.2717H80.4622Z" fill="#2A4143" />
                                        <path d="M106.626 31.9497C106.628 33.26 106.371 34.5577 105.87 35.7685C105.369 36.9793 104.635 38.0795 103.708 39.0061C102.782 39.9326 101.681 40.6673 100.471 41.1682C99.2598 41.669 97.9621 41.9261 96.6518 41.9248C91.1629 41.9248 86.6768 37.4666 86.6768 31.9497C86.6768 26.4607 91.1605 21.9746 96.6518 21.9746C102.169 21.9746 106.626 26.4607 106.626 31.9497ZM101.89 31.9497C101.891 31.2616 101.756 30.5801 101.493 29.9442C101.23 29.3083 100.844 28.7306 100.358 28.244C99.871 27.7574 99.2932 27.3716 98.6573 27.1086C98.0214 26.8457 97.3399 26.7107 96.6518 26.7114C93.7818 26.7114 91.4136 29.0521 91.4136 31.9497C91.4136 34.8472 93.7818 37.1879 96.6518 37.1879C97.3399 37.1887 98.0214 37.0537 98.6573 36.7907C99.2932 36.5278 99.871 36.1419 100.358 35.6554C100.844 35.1688 101.23 34.591 101.493 33.9552C101.756 33.3193 101.891 32.6378 101.89 31.9497Z" fill="#2A4143" />
                                    </svg>
                                )}
                                {window.innerWidth === 480 &&
                                (
                                    <svg width="180" height="55" viewBox="0 0 180 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M61.2153 41.7407V22.2344H65.9958V41.7407H61.2153Z" fill="#2A4143" />
                                        <path d="M108.96 41.7407V22.2344H113.053L121.46 34.158V22.2344H126.048V41.7407H121.955L113.548 29.8171V41.7407H108.96Z" fill="#2A4143" />
                                        <path d="M128.6 41.7407V22.2344H133.381V41.7407H128.6Z" fill="#CC214F" />
                                        <path d="M135.385 41.7407L143.105 22.2344H148.188L155.908 41.7407H150.962L149.589 37.9493H141.539L140.165 41.7407H135.385ZM142.995 33.9382H148.133L145.55 26.8225L142.995 33.9382Z" fill="#CC214F" />
                                        <path d="M36.3752 27.1719L28.0524 40.9062L47.9866 40.9062L45.7971 37.5225" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M43.1375 33.459L42.5045 32.4808L41.6551 31.2028" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M40.7812 24.875C40.7812 26.5836 39.3961 27.9688 37.6875 27.9688C35.9789 27.9688 34.5938 26.5836 34.5938 24.875C34.5938 23.1664 35.9789 21.7812 37.6875 21.7812C39.3961 21.7812 40.7812 23.1664 40.7812 24.875ZM36.7781 24.875C36.7781 25.3773 37.1852 25.7844 37.6875 25.7844C38.1898 25.7844 38.5969 25.3773 38.5969 24.875C38.5969 24.3727 38.1898 23.9656 37.6875 23.9656C37.1852 23.9656 36.7781 24.3727 36.7781 24.875Z" fill="#2A4143" />
                                        <path d="M82.3294 33.4265L85.6729 41.7296H80.4636L77.5661 34.318H74.0205V41.7296H69.2837V22.252H79.1822C82.4977 22.252 85.2284 24.9547 85.2284 28.2703C85.2257 29.3057 84.9578 30.3233 84.4503 31.2259C83.9429 32.1285 83.2127 32.8861 82.3294 33.4265ZM80.4622 28.2717C80.4583 27.933 80.322 27.6093 80.0826 27.3699C79.8431 27.1304 79.5194 26.9941 79.1807 26.9902H74.0205V29.5816H79.1822C79.8788 29.5816 80.4636 28.9963 80.4636 28.2717H80.4622Z" fill="#2A4143" />
                                        <path d="M106.626 31.9497C106.628 33.26 106.371 34.5577 105.87 35.7685C105.369 36.9793 104.635 38.0795 103.708 39.0061C102.782 39.9326 101.681 40.6673 100.471 41.1682C99.2598 41.669 97.9621 41.9261 96.6518 41.9248C91.1629 41.9248 86.6768 37.4666 86.6768 31.9497C86.6768 26.4607 91.1605 21.9746 96.6518 21.9746C102.169 21.9746 106.626 26.4607 106.626 31.9497ZM101.89 31.9497C101.891 31.2616 101.756 30.5801 101.493 29.9442C101.23 29.3083 100.844 28.7306 100.358 28.244C99.871 27.7574 99.2932 27.3716 98.6573 27.1086C98.0214 26.8457 97.3399 26.7107 96.6518 26.7114C93.7818 26.7114 91.4136 29.0521 91.4136 31.9497C91.4136 34.8472 93.7818 37.1879 96.6518 37.1879C97.3399 37.1887 98.0214 37.0537 98.6573 36.7907C99.2932 36.5278 99.871 36.1419 100.358 35.6554C100.844 35.1688 101.23 34.591 101.493 33.9552C101.756 33.3193 101.891 32.6378 101.89 31.9497Z" fill="#2A4143" />
                                    </svg>
                                )}
                                {window.innerWidth >= 481 && window.innerWidth < 640 &&
                                (
                                    <svg width="180" height="55" viewBox="0 0 185 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M61.2153 41.7407V22.2344H65.9958V41.7407H61.2153Z" fill="#2A4143" />
                                        <path d="M108.96 41.7407V22.2344H113.053L121.46 34.158V22.2344H126.048V41.7407H121.955L113.548 29.8171V41.7407H108.96Z" fill="#2A4143" />
                                        <path d="M128.6 41.7407V22.2344H133.381V41.7407H128.6Z" fill="#CC214F" />
                                        <path d="M135.385 41.7407L143.105 22.2344H148.188L155.908 41.7407H150.962L149.589 37.9493H141.539L140.165 41.7407H135.385ZM142.995 33.9382H148.133L145.55 26.8225L142.995 33.9382Z" fill="#CC214F" />
                                        <path d="M36.3752 27.1719L28.0524 40.9062L47.9866 40.9062L45.7971 37.5225" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M43.1375 33.459L42.5045 32.4808L41.6551 31.2028" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M40.7812 24.875C40.7812 26.5836 39.3961 27.9688 37.6875 27.9688C35.9789 27.9688 34.5938 26.5836 34.5938 24.875C34.5938 23.1664 35.9789 21.7812 37.6875 21.7812C39.3961 21.7812 40.7812 23.1664 40.7812 24.875ZM36.7781 24.875C36.7781 25.3773 37.1852 25.7844 37.6875 25.7844C38.1898 25.7844 38.5969 25.3773 38.5969 24.875C38.5969 24.3727 38.1898 23.9656 37.6875 23.9656C37.1852 23.9656 36.7781 24.3727 36.7781 24.875Z" fill="#2A4143" />
                                        <path d="M82.3294 33.4265L85.6729 41.7296H80.4636L77.5661 34.318H74.0205V41.7296H69.2837V22.252H79.1822C82.4977 22.252 85.2284 24.9547 85.2284 28.2703C85.2257 29.3057 84.9578 30.3233 84.4503 31.2259C83.9429 32.1285 83.2127 32.8861 82.3294 33.4265ZM80.4622 28.2717C80.4583 27.933 80.322 27.6093 80.0826 27.3699C79.8431 27.1304 79.5194 26.9941 79.1807 26.9902H74.0205V29.5816H79.1822C79.8788 29.5816 80.4636 28.9963 80.4636 28.2717H80.4622Z" fill="#2A4143" />
                                        <path d="M106.626 31.9497C106.628 33.26 106.371 34.5577 105.87 35.7685C105.369 36.9793 104.635 38.0795 103.708 39.0061C102.782 39.9326 101.681 40.6673 100.471 41.1682C99.2598 41.669 97.9621 41.9261 96.6518 41.9248C91.1629 41.9248 86.6768 37.4666 86.6768 31.9497C86.6768 26.4607 91.1605 21.9746 96.6518 21.9746C102.169 21.9746 106.626 26.4607 106.626 31.9497ZM101.89 31.9497C101.891 31.2616 101.756 30.5801 101.493 29.9442C101.23 29.3083 100.844 28.7306 100.358 28.244C99.871 27.7574 99.2932 27.3716 98.6573 27.1086C98.0214 26.8457 97.3399 26.7107 96.6518 26.7114C93.7818 26.7114 91.4136 29.0521 91.4136 31.9497C91.4136 34.8472 93.7818 37.1879 96.6518 37.1879C97.3399 37.1887 98.0214 37.0537 98.6573 36.7907C99.2932 36.5278 99.871 36.1419 100.358 35.6554C100.844 35.1688 101.23 34.591 101.493 33.9552C101.756 33.3193 101.891 32.6378 101.89 31.9497Z" fill="#2A4143" />
                                    </svg>
                                )}
                                {window.innerWidth === 640 &&
                                (
                                    <svg width="210" height="55" viewBox="40 0 180 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M61.2153 41.7407V22.2344H65.9958V41.7407H61.2153Z" fill="#2A4143" />
                                        <path d="M108.96 41.7407V22.2344H113.053L121.46 34.158V22.2344H126.048V41.7407H121.955L113.548 29.8171V41.7407H108.96Z" fill="#2A4143" />
                                        <path d="M128.6 41.7407V22.2344H133.381V41.7407H128.6Z" fill="#CC214F" />
                                        <path d="M135.385 41.7407L143.105 22.2344H148.188L155.908 41.7407H150.962L149.589 37.9493H141.539L140.165 41.7407H135.385ZM142.995 33.9382H148.133L145.55 26.8225L142.995 33.9382Z" fill="#CC214F" />
                                        <path d="M36.3752 27.1719L28.0524 40.9062L47.9866 40.9062L45.7971 37.5225" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M43.1375 33.459L42.5045 32.4808L41.6551 31.2028" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M40.7812 24.875C40.7812 26.5836 39.3961 27.9688 37.6875 27.9688C35.9789 27.9688 34.5938 26.5836 34.5938 24.875C34.5938 23.1664 35.9789 21.7812 37.6875 21.7812C39.3961 21.7812 40.7812 23.1664 40.7812 24.875ZM36.7781 24.875C36.7781 25.3773 37.1852 25.7844 37.6875 25.7844C38.1898 25.7844 38.5969 25.3773 38.5969 24.875C38.5969 24.3727 38.1898 23.9656 37.6875 23.9656C37.1852 23.9656 36.7781 24.3727 36.7781 24.875Z" fill="#2A4143" />
                                        <path d="M82.3294 33.4265L85.6729 41.7296H80.4636L77.5661 34.318H74.0205V41.7296H69.2837V22.252H79.1822C82.4977 22.252 85.2284 24.9547 85.2284 28.2703C85.2257 29.3057 84.9578 30.3233 84.4503 31.2259C83.9429 32.1285 83.2127 32.8861 82.3294 33.4265ZM80.4622 28.2717C80.4583 27.933 80.322 27.6093 80.0826 27.3699C79.8431 27.1304 79.5194 26.9941 79.1807 26.9902H74.0205V29.5816H79.1822C79.8788 29.5816 80.4636 28.9963 80.4636 28.2717H80.4622Z" fill="#2A4143" />
                                        <path d="M106.626 31.9497C106.628 33.26 106.371 34.5577 105.87 35.7685C105.369 36.9793 104.635 38.0795 103.708 39.0061C102.782 39.9326 101.681 40.6673 100.471 41.1682C99.2598 41.669 97.9621 41.9261 96.6518 41.9248C91.1629 41.9248 86.6768 37.4666 86.6768 31.9497C86.6768 26.4607 91.1605 21.9746 96.6518 21.9746C102.169 21.9746 106.626 26.4607 106.626 31.9497ZM101.89 31.9497C101.891 31.2616 101.756 30.5801 101.493 29.9442C101.23 29.3083 100.844 28.7306 100.358 28.244C99.871 27.7574 99.2932 27.3716 98.6573 27.1086C98.0214 26.8457 97.3399 26.7107 96.6518 26.7114C93.7818 26.7114 91.4136 29.0521 91.4136 31.9497C91.4136 34.8472 93.7818 37.1879 96.6518 37.1879C97.3399 37.1887 98.0214 37.0537 98.6573 36.7907C99.2932 36.5278 99.871 36.1419 100.358 35.6554C100.844 35.1688 101.23 34.591 101.493 33.9552C101.756 33.3193 101.891 32.6378 101.89 31.9497Z" fill="#2A4143" />
                                    </svg>
                                )}
                                {window.innerWidth >= 1366 && window.innerWidth <= 1919.99 &&
                                (
                                    <svg width="152" height="70" viewBox="0 0 165 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M61.2153 45.7401V26.2338H65.9958V45.7401H61.2153Z" fill="#2A4143" />
                                        <path d="M108.96 45.7401V26.2338H113.053L121.46 38.1574V26.2338H126.048V45.7401H121.955L113.548 33.8165V45.7401H108.96Z" fill="#2A4143" />
                                        <path d="M128.6 45.7402V26.2339H133.381V45.7402H128.6Z" fill="#CC214F" />
                                        <path d="M135.385 45.7402L143.105 26.2339H148.188L155.908 45.7402H150.962L149.589 41.9488H141.539L140.165 45.7402H135.385ZM142.995 37.9376H148.133L145.55 30.822L142.995 37.9376Z" fill="#CC214F" />
                                        <path d="M36.375 31.1719L28.0521 44.9062L47.9863 44.9062L45.7968 41.5225" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M43.1375 37.4593L42.5045 36.4811L41.6551 35.2031" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M40.7812 28.875C40.7812 30.5836 39.3961 31.9688 37.6875 31.9688C35.9789 31.9688 34.5938 30.5836 34.5938 28.875C34.5938 27.1664 35.9789 25.7812 37.6875 25.7812C39.3961 25.7812 40.7812 27.1664 40.7812 28.875ZM36.7781 28.875C36.7781 29.3773 37.1852 29.7844 37.6875 29.7844C38.1898 29.7844 38.5969 29.3773 38.5969 28.875C38.5969 28.3727 38.1898 27.9656 37.6875 27.9656C37.1852 27.9656 36.7781 28.3727 36.7781 28.875Z" fill="#2A4143" />
                                        <path d="M82.3294 37.4259L85.6729 45.729H80.4636L77.5661 38.3174H74.0205V45.729H69.2837V26.2513H79.1822C82.4977 26.2513 85.2284 28.9541 85.2284 32.2697C85.2257 33.3051 84.9578 34.3227 84.4503 35.2253C83.9429 36.1279 83.2127 36.8855 82.3294 37.4259ZM80.4622 32.2711C80.4583 31.9324 80.322 31.6087 80.0826 31.3693C79.8431 31.1298 79.5194 30.9935 79.1807 30.9896H74.0205V33.581H79.1822C79.8788 33.581 80.4636 32.9957 80.4636 32.2711H80.4622Z" fill="#2A4143" />
                                        <path d="M106.626 35.9492C106.628 37.2595 106.371 38.5572 105.87 39.768C105.369 40.9789 104.635 42.079 103.708 43.0056C102.782 43.9321 101.681 44.6668 100.471 45.1677C99.2598 45.6685 97.9621 45.9256 96.6518 45.9243C91.1629 45.9243 86.6768 41.4661 86.6768 35.9492C86.6768 30.4603 91.1605 25.9741 96.6518 25.9741C102.169 25.9741 106.626 30.4603 106.626 35.9492ZM101.89 35.9492C101.891 35.2611 101.756 34.5796 101.493 33.9437C101.23 33.3078 100.844 32.7301 100.358 32.2435C99.871 31.7569 99.2932 31.3711 98.6573 31.1081C98.0214 30.8452 97.3399 30.7102 96.6518 30.711C93.7818 30.711 91.4136 33.0517 91.4136 35.9492C91.4136 38.8467 93.7818 41.1874 96.6518 41.1874C97.3399 41.1882 98.0214 41.0532 98.6573 40.7902C99.2932 40.5273 99.871 40.1414 100.358 39.6549C100.844 39.1683 101.23 38.5906 101.493 37.9547C101.756 37.3188 101.891 36.6373 101.89 35.9492Z" fill="#2A4143" />
                                    </svg>
                                )}
                            </React.Fragment>
                        )}
                        {theme.name === "light" &&
                        (
                            <React.Fragment>
                                {window.innerWidth === 320 &&
                                (
                                    <svg width="190" height="55" viewBox="0 0 200 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M61.2153 41.7407V22.2344H65.9958V41.7407H61.2153Z" fill="#2A4143" />
                                        <path d="M108.96 41.7407V22.2344H113.053L121.46 34.158V22.2344H126.048V41.7407H121.955L113.548 29.8171V41.7407H108.96Z" fill="#2A4143" />
                                        <path d="M128.6 41.7407V22.2344H133.381V41.7407H128.6Z" fill="#CC214F" />
                                        <path d="M135.385 41.7407L143.105 22.2344H148.188L155.908 41.7407H150.962L149.589 37.9493H141.539L140.165 41.7407H135.385ZM142.995 33.9382H148.133L145.55 26.8225L142.995 33.9382Z" fill="#CC214F" />
                                        <path d="M36.3752 27.1719L28.0524 40.9062L47.9866 40.9062L45.7971 37.5225" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M43.1375 33.459L42.5045 32.4808L41.6551 31.2028" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M40.7812 24.875C40.7812 26.5836 39.3961 27.9688 37.6875 27.9688C35.9789 27.9688 34.5938 26.5836 34.5938 24.875C34.5938 23.1664 35.9789 21.7812 37.6875 21.7812C39.3961 21.7812 40.7812 23.1664 40.7812 24.875ZM36.7781 24.875C36.7781 25.3773 37.1852 25.7844 37.6875 25.7844C38.1898 25.7844 38.5969 25.3773 38.5969 24.875C38.5969 24.3727 38.1898 23.9656 37.6875 23.9656C37.1852 23.9656 36.7781 24.3727 36.7781 24.875Z" fill="#2A4143" />
                                        <path d="M82.3294 33.4265L85.6729 41.7296H80.4636L77.5661 34.318H74.0205V41.7296H69.2837V22.252H79.1822C82.4977 22.252 85.2284 24.9547 85.2284 28.2703C85.2257 29.3057 84.9578 30.3233 84.4503 31.2259C83.9429 32.1285 83.2127 32.8861 82.3294 33.4265ZM80.4622 28.2717C80.4583 27.933 80.322 27.6093 80.0826 27.3699C79.8431 27.1304 79.5194 26.9941 79.1807 26.9902H74.0205V29.5816H79.1822C79.8788 29.5816 80.4636 28.9963 80.4636 28.2717H80.4622Z" fill="#2A4143" />
                                        <path d="M106.626 31.9497C106.628 33.26 106.371 34.5577 105.87 35.7685C105.369 36.9793 104.635 38.0795 103.708 39.0061C102.782 39.9326 101.681 40.6673 100.471 41.1682C99.2598 41.669 97.9621 41.9261 96.6518 41.9248C91.1629 41.9248 86.6768 37.4666 86.6768 31.9497C86.6768 26.4607 91.1605 21.9746 96.6518 21.9746C102.169 21.9746 106.626 26.4607 106.626 31.9497ZM101.89 31.9497C101.891 31.2616 101.756 30.5801 101.493 29.9442C101.23 29.3083 100.844 28.7306 100.358 28.244C99.871 27.7574 99.2932 27.3716 98.6573 27.1086C98.0214 26.8457 97.3399 26.7107 96.6518 26.7114C93.7818 26.7114 91.4136 29.0521 91.4136 31.9497C91.4136 34.8472 93.7818 37.1879 96.6518 37.1879C97.3399 37.1887 98.0214 37.0537 98.6573 36.7907C99.2932 36.5278 99.871 36.1419 100.358 35.6554C100.844 35.1688 101.23 34.591 101.493 33.9552C101.756 33.3193 101.891 32.6378 101.89 31.9497Z" fill="#2A4143" />
                                    </svg>
                                )}
                                {window.innerWidth > 320 && window.innerWidth < 400 &&
                                (
                                    <svg width="150" height="55" viewBox="0 0 180 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M61.2153 41.7407V22.2344H65.9958V41.7407H61.2153Z" fill="#2A4143" />
                                        <path d="M108.96 41.7407V22.2344H113.053L121.46 34.158V22.2344H126.048V41.7407H121.955L113.548 29.8171V41.7407H108.96Z" fill="#2A4143" />
                                        <path d="M128.6 41.7407V22.2344H133.381V41.7407H128.6Z" fill="#CC214F" />
                                        <path d="M135.385 41.7407L143.105 22.2344H148.188L155.908 41.7407H150.962L149.589 37.9493H141.539L140.165 41.7407H135.385ZM142.995 33.9382H148.133L145.55 26.8225L142.995 33.9382Z" fill="#CC214F" />
                                        <path d="M36.3752 27.1719L28.0524 40.9062L47.9866 40.9062L45.7971 37.5225" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M43.1375 33.459L42.5045 32.4808L41.6551 31.2028" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M40.7812 24.875C40.7812 26.5836 39.3961 27.9688 37.6875 27.9688C35.9789 27.9688 34.5938 26.5836 34.5938 24.875C34.5938 23.1664 35.9789 21.7812 37.6875 21.7812C39.3961 21.7812 40.7812 23.1664 40.7812 24.875ZM36.7781 24.875C36.7781 25.3773 37.1852 25.7844 37.6875 25.7844C38.1898 25.7844 38.5969 25.3773 38.5969 24.875C38.5969 24.3727 38.1898 23.9656 37.6875 23.9656C37.1852 23.9656 36.7781 24.3727 36.7781 24.875Z" fill="#2A4143" />
                                        <path d="M82.3294 33.4265L85.6729 41.7296H80.4636L77.5661 34.318H74.0205V41.7296H69.2837V22.252H79.1822C82.4977 22.252 85.2284 24.9547 85.2284 28.2703C85.2257 29.3057 84.9578 30.3233 84.4503 31.2259C83.9429 32.1285 83.2127 32.8861 82.3294 33.4265ZM80.4622 28.2717C80.4583 27.933 80.322 27.6093 80.0826 27.3699C79.8431 27.1304 79.5194 26.9941 79.1807 26.9902H74.0205V29.5816H79.1822C79.8788 29.5816 80.4636 28.9963 80.4636 28.2717H80.4622Z" fill="#2A4143" />
                                        <path d="M106.626 31.9497C106.628 33.26 106.371 34.5577 105.87 35.7685C105.369 36.9793 104.635 38.0795 103.708 39.0061C102.782 39.9326 101.681 40.6673 100.471 41.1682C99.2598 41.669 97.9621 41.9261 96.6518 41.9248C91.1629 41.9248 86.6768 37.4666 86.6768 31.9497C86.6768 26.4607 91.1605 21.9746 96.6518 21.9746C102.169 21.9746 106.626 26.4607 106.626 31.9497ZM101.89 31.9497C101.891 31.2616 101.756 30.5801 101.493 29.9442C101.23 29.3083 100.844 28.7306 100.358 28.244C99.871 27.7574 99.2932 27.3716 98.6573 27.1086C98.0214 26.8457 97.3399 26.7107 96.6518 26.7114C93.7818 26.7114 91.4136 29.0521 91.4136 31.9497C91.4136 34.8472 93.7818 37.1879 96.6518 37.1879C97.3399 37.1887 98.0214 37.0537 98.6573 36.7907C99.2932 36.5278 99.871 36.1419 100.358 35.6554C100.844 35.1688 101.23 34.591 101.493 33.9552C101.756 33.3193 101.891 32.6378 101.89 31.9497Z" fill="#2A4143" />
                                    </svg>
                                )}
                                {window.innerWidth > 401 && window.innerWidth < 480 &&
                                (
                                    <svg width="240" height="55" viewBox="0 0 220 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M61.2153 41.7407V22.2344H65.9958V41.7407H61.2153Z" fill="#2A4143" />
                                        <path d="M108.96 41.7407V22.2344H113.053L121.46 34.158V22.2344H126.048V41.7407H121.955L113.548 29.8171V41.7407H108.96Z" fill="#2A4143" />
                                        <path d="M128.6 41.7407V22.2344H133.381V41.7407H128.6Z" fill="#CC214F" />
                                        <path d="M135.385 41.7407L143.105 22.2344H148.188L155.908 41.7407H150.962L149.589 37.9493H141.539L140.165 41.7407H135.385ZM142.995 33.9382H148.133L145.55 26.8225L142.995 33.9382Z" fill="#CC214F" />
                                        <path d="M36.3752 27.1719L28.0524 40.9062L47.9866 40.9062L45.7971 37.5225" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M43.1375 33.459L42.5045 32.4808L41.6551 31.2028" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M40.7812 24.875C40.7812 26.5836 39.3961 27.9688 37.6875 27.9688C35.9789 27.9688 34.5938 26.5836 34.5938 24.875C34.5938 23.1664 35.9789 21.7812 37.6875 21.7812C39.3961 21.7812 40.7812 23.1664 40.7812 24.875ZM36.7781 24.875C36.7781 25.3773 37.1852 25.7844 37.6875 25.7844C38.1898 25.7844 38.5969 25.3773 38.5969 24.875C38.5969 24.3727 38.1898 23.9656 37.6875 23.9656C37.1852 23.9656 36.7781 24.3727 36.7781 24.875Z" fill="#2A4143" />
                                        <path d="M82.3294 33.4265L85.6729 41.7296H80.4636L77.5661 34.318H74.0205V41.7296H69.2837V22.252H79.1822C82.4977 22.252 85.2284 24.9547 85.2284 28.2703C85.2257 29.3057 84.9578 30.3233 84.4503 31.2259C83.9429 32.1285 83.2127 32.8861 82.3294 33.4265ZM80.4622 28.2717C80.4583 27.933 80.322 27.6093 80.0826 27.3699C79.8431 27.1304 79.5194 26.9941 79.1807 26.9902H74.0205V29.5816H79.1822C79.8788 29.5816 80.4636 28.9963 80.4636 28.2717H80.4622Z" fill="#2A4143" />
                                        <path d="M106.626 31.9497C106.628 33.26 106.371 34.5577 105.87 35.7685C105.369 36.9793 104.635 38.0795 103.708 39.0061C102.782 39.9326 101.681 40.6673 100.471 41.1682C99.2598 41.669 97.9621 41.9261 96.6518 41.9248C91.1629 41.9248 86.6768 37.4666 86.6768 31.9497C86.6768 26.4607 91.1605 21.9746 96.6518 21.9746C102.169 21.9746 106.626 26.4607 106.626 31.9497ZM101.89 31.9497C101.891 31.2616 101.756 30.5801 101.493 29.9442C101.23 29.3083 100.844 28.7306 100.358 28.244C99.871 27.7574 99.2932 27.3716 98.6573 27.1086C98.0214 26.8457 97.3399 26.7107 96.6518 26.7114C93.7818 26.7114 91.4136 29.0521 91.4136 31.9497C91.4136 34.8472 93.7818 37.1879 96.6518 37.1879C97.3399 37.1887 98.0214 37.0537 98.6573 36.7907C99.2932 36.5278 99.871 36.1419 100.358 35.6554C100.844 35.1688 101.23 34.591 101.493 33.9552C101.756 33.3193 101.891 32.6378 101.89 31.9497Z" fill="#2A4143" />
                                    </svg>
                                )}
                                {window.innerWidth === 480 &&
                                (
                                    <svg width="250" height="55" viewBox="0 0 235 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M61.2153 41.7407V22.2344H65.9958V41.7407H61.2153Z" fill="#2A4143" />
                                        <path d="M108.96 41.7407V22.2344H113.053L121.46 34.158V22.2344H126.048V41.7407H121.955L113.548 29.8171V41.7407H108.96Z" fill="#2A4143" />
                                        <path d="M128.6 41.7407V22.2344H133.381V41.7407H128.6Z" fill="#CC214F" />
                                        <path d="M135.385 41.7407L143.105 22.2344H148.188L155.908 41.7407H150.962L149.589 37.9493H141.539L140.165 41.7407H135.385ZM142.995 33.9382H148.133L145.55 26.8225L142.995 33.9382Z" fill="#CC214F" />
                                        <path d="M36.3752 27.1719L28.0524 40.9062L47.9866 40.9062L45.7971 37.5225" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M43.1375 33.459L42.5045 32.4808L41.6551 31.2028" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M40.7812 24.875C40.7812 26.5836 39.3961 27.9688 37.6875 27.9688C35.9789 27.9688 34.5938 26.5836 34.5938 24.875C34.5938 23.1664 35.9789 21.7812 37.6875 21.7812C39.3961 21.7812 40.7812 23.1664 40.7812 24.875ZM36.7781 24.875C36.7781 25.3773 37.1852 25.7844 37.6875 25.7844C38.1898 25.7844 38.5969 25.3773 38.5969 24.875C38.5969 24.3727 38.1898 23.9656 37.6875 23.9656C37.1852 23.9656 36.7781 24.3727 36.7781 24.875Z" fill="#2A4143" />
                                        <path d="M82.3294 33.4265L85.6729 41.7296H80.4636L77.5661 34.318H74.0205V41.7296H69.2837V22.252H79.1822C82.4977 22.252 85.2284 24.9547 85.2284 28.2703C85.2257 29.3057 84.9578 30.3233 84.4503 31.2259C83.9429 32.1285 83.2127 32.8861 82.3294 33.4265ZM80.4622 28.2717C80.4583 27.933 80.322 27.6093 80.0826 27.3699C79.8431 27.1304 79.5194 26.9941 79.1807 26.9902H74.0205V29.5816H79.1822C79.8788 29.5816 80.4636 28.9963 80.4636 28.2717H80.4622Z" fill="#2A4143" />
                                        <path d="M106.626 31.9497C106.628 33.26 106.371 34.5577 105.87 35.7685C105.369 36.9793 104.635 38.0795 103.708 39.0061C102.782 39.9326 101.681 40.6673 100.471 41.1682C99.2598 41.669 97.9621 41.9261 96.6518 41.9248C91.1629 41.9248 86.6768 37.4666 86.6768 31.9497C86.6768 26.4607 91.1605 21.9746 96.6518 21.9746C102.169 21.9746 106.626 26.4607 106.626 31.9497ZM101.89 31.9497C101.891 31.2616 101.756 30.5801 101.493 29.9442C101.23 29.3083 100.844 28.7306 100.358 28.244C99.871 27.7574 99.2932 27.3716 98.6573 27.1086C98.0214 26.8457 97.3399 26.7107 96.6518 26.7114C93.7818 26.7114 91.4136 29.0521 91.4136 31.9497C91.4136 34.8472 93.7818 37.1879 96.6518 37.1879C97.3399 37.1887 98.0214 37.0537 98.6573 36.7907C99.2932 36.5278 99.871 36.1419 100.358 35.6554C100.844 35.1688 101.23 34.591 101.493 33.9552C101.756 33.3193 101.891 32.6378 101.89 31.9497Z" fill="#2A4143" />
                                    </svg>
                                )}
                                {window.innerWidth > 480 && window.innerWidth < 640 &&
                                (
                                    <svg width="180" height="55" viewBox="0 0 185 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M61.2153 41.7407V22.2344H65.9958V41.7407H61.2153Z" fill="#2A4143" />
                                        <path d="M108.96 41.7407V22.2344H113.053L121.46 34.158V22.2344H126.048V41.7407H121.955L113.548 29.8171V41.7407H108.96Z" fill="#2A4143" />
                                        <path d="M128.6 41.7407V22.2344H133.381V41.7407H128.6Z" fill="#CC214F" />
                                        <path d="M135.385 41.7407L143.105 22.2344H148.188L155.908 41.7407H150.962L149.589 37.9493H141.539L140.165 41.7407H135.385ZM142.995 33.9382H148.133L145.55 26.8225L142.995 33.9382Z" fill="#CC214F" />
                                        <path d="M36.3752 27.1719L28.0524 40.9062L47.9866 40.9062L45.7971 37.5225" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M43.1375 33.459L42.5045 32.4808L41.6551 31.2028" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M40.7812 24.875C40.7812 26.5836 39.3961 27.9688 37.6875 27.9688C35.9789 27.9688 34.5938 26.5836 34.5938 24.875C34.5938 23.1664 35.9789 21.7812 37.6875 21.7812C39.3961 21.7812 40.7812 23.1664 40.7812 24.875ZM36.7781 24.875C36.7781 25.3773 37.1852 25.7844 37.6875 25.7844C38.1898 25.7844 38.5969 25.3773 38.5969 24.875C38.5969 24.3727 38.1898 23.9656 37.6875 23.9656C37.1852 23.9656 36.7781 24.3727 36.7781 24.875Z" fill="#2A4143" />
                                        <path d="M82.3294 33.4265L85.6729 41.7296H80.4636L77.5661 34.318H74.0205V41.7296H69.2837V22.252H79.1822C82.4977 22.252 85.2284 24.9547 85.2284 28.2703C85.2257 29.3057 84.9578 30.3233 84.4503 31.2259C83.9429 32.1285 83.2127 32.8861 82.3294 33.4265ZM80.4622 28.2717C80.4583 27.933 80.322 27.6093 80.0826 27.3699C79.8431 27.1304 79.5194 26.9941 79.1807 26.9902H74.0205V29.5816H79.1822C79.8788 29.5816 80.4636 28.9963 80.4636 28.2717H80.4622Z" fill="#2A4143" />
                                        <path d="M106.626 31.9497C106.628 33.26 106.371 34.5577 105.87 35.7685C105.369 36.9793 104.635 38.0795 103.708 39.0061C102.782 39.9326 101.681 40.6673 100.471 41.1682C99.2598 41.669 97.9621 41.9261 96.6518 41.9248C91.1629 41.9248 86.6768 37.4666 86.6768 31.9497C86.6768 26.4607 91.1605 21.9746 96.6518 21.9746C102.169 21.9746 106.626 26.4607 106.626 31.9497ZM101.89 31.9497C101.891 31.2616 101.756 30.5801 101.493 29.9442C101.23 29.3083 100.844 28.7306 100.358 28.244C99.871 27.7574 99.2932 27.3716 98.6573 27.1086C98.0214 26.8457 97.3399 26.7107 96.6518 26.7114C93.7818 26.7114 91.4136 29.0521 91.4136 31.9497C91.4136 34.8472 93.7818 37.1879 96.6518 37.1879C97.3399 37.1887 98.0214 37.0537 98.6573 36.7907C99.2932 36.5278 99.871 36.1419 100.358 35.6554C100.844 35.1688 101.23 34.591 101.493 33.9552C101.756 33.3193 101.891 32.6378 101.89 31.9497Z" fill="#2A4143" />
                                    </svg>
                                )}
                                {window.innerWidth === 640 &&
                                (
                                    <svg width="150" height="55" viewBox="10 0 180 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M61.2153 41.7407V22.2344H65.9958V41.7407H61.2153Z" fill="#2A4143" />
                                        <path d="M108.96 41.7407V22.2344H113.053L121.46 34.158V22.2344H126.048V41.7407H121.955L113.548 29.8171V41.7407H108.96Z" fill="#2A4143" />
                                        <path d="M128.6 41.7407V22.2344H133.381V41.7407H128.6Z" fill="#CC214F" />
                                        <path d="M135.385 41.7407L143.105 22.2344H148.188L155.908 41.7407H150.962L149.589 37.9493H141.539L140.165 41.7407H135.385ZM142.995 33.9382H148.133L145.55 26.8225L142.995 33.9382Z" fill="#CC214F" />
                                        <path d="M36.3752 27.1719L28.0524 40.9062L47.9866 40.9062L45.7971 37.5225" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M43.1375 33.459L42.5045 32.4808L41.6551 31.2028" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M40.7812 24.875C40.7812 26.5836 39.3961 27.9688 37.6875 27.9688C35.9789 27.9688 34.5938 26.5836 34.5938 24.875C34.5938 23.1664 35.9789 21.7812 37.6875 21.7812C39.3961 21.7812 40.7812 23.1664 40.7812 24.875ZM36.7781 24.875C36.7781 25.3773 37.1852 25.7844 37.6875 25.7844C38.1898 25.7844 38.5969 25.3773 38.5969 24.875C38.5969 24.3727 38.1898 23.9656 37.6875 23.9656C37.1852 23.9656 36.7781 24.3727 36.7781 24.875Z" fill="#2A4143" />
                                        <path d="M82.3294 33.4265L85.6729 41.7296H80.4636L77.5661 34.318H74.0205V41.7296H69.2837V22.252H79.1822C82.4977 22.252 85.2284 24.9547 85.2284 28.2703C85.2257 29.3057 84.9578 30.3233 84.4503 31.2259C83.9429 32.1285 83.2127 32.8861 82.3294 33.4265ZM80.4622 28.2717C80.4583 27.933 80.322 27.6093 80.0826 27.3699C79.8431 27.1304 79.5194 26.9941 79.1807 26.9902H74.0205V29.5816H79.1822C79.8788 29.5816 80.4636 28.9963 80.4636 28.2717H80.4622Z" fill="#2A4143" />
                                        <path d="M106.626 31.9497C106.628 33.26 106.371 34.5577 105.87 35.7685C105.369 36.9793 104.635 38.0795 103.708 39.0061C102.782 39.9326 101.681 40.6673 100.471 41.1682C99.2598 41.669 97.9621 41.9261 96.6518 41.9248C91.1629 41.9248 86.6768 37.4666 86.6768 31.9497C86.6768 26.4607 91.1605 21.9746 96.6518 21.9746C102.169 21.9746 106.626 26.4607 106.626 31.9497ZM101.89 31.9497C101.891 31.2616 101.756 30.5801 101.493 29.9442C101.23 29.3083 100.844 28.7306 100.358 28.244C99.871 27.7574 99.2932 27.3716 98.6573 27.1086C98.0214 26.8457 97.3399 26.7107 96.6518 26.7114C93.7818 26.7114 91.4136 29.0521 91.4136 31.9497C91.4136 34.8472 93.7818 37.1879 96.6518 37.1879C97.3399 37.1887 98.0214 37.0537 98.6573 36.7907C99.2932 36.5278 99.871 36.1419 100.358 35.6554C100.844 35.1688 101.23 34.591 101.493 33.9552C101.756 33.3193 101.891 32.6378 101.89 31.9497Z" fill="#2A4143" />
                                    </svg>
                                )}
                                {window.innerWidth > 640 && window.innerWidth < 800 &&
                                (
                                    <svg width="175" height="55" viewBox="0 0 225 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M61.2153 41.7407V22.2344H65.9958V41.7407H61.2153Z" fill="#2A4143" />
                                        <path d="M108.96 41.7407V22.2344H113.053L121.46 34.158V22.2344H126.048V41.7407H121.955L113.548 29.8171V41.7407H108.96Z" fill="#2A4143" />
                                        <path d="M128.6 41.7407V22.2344H133.381V41.7407H128.6Z" fill="#CC214F" />
                                        <path d="M135.385 41.7407L143.105 22.2344H148.188L155.908 41.7407H150.962L149.589 37.9493H141.539L140.165 41.7407H135.385ZM142.995 33.9382H148.133L145.55 26.8225L142.995 33.9382Z" fill="#CC214F" />
                                        <path d="M36.3752 27.1719L28.0524 40.9062L47.9866 40.9062L45.7971 37.5225" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M43.1375 33.459L42.5045 32.4808L41.6551 31.2028" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M40.7812 24.875C40.7812 26.5836 39.3961 27.9688 37.6875 27.9688C35.9789 27.9688 34.5938 26.5836 34.5938 24.875C34.5938 23.1664 35.9789 21.7812 37.6875 21.7812C39.3961 21.7812 40.7812 23.1664 40.7812 24.875ZM36.7781 24.875C36.7781 25.3773 37.1852 25.7844 37.6875 25.7844C38.1898 25.7844 38.5969 25.3773 38.5969 24.875C38.5969 24.3727 38.1898 23.9656 37.6875 23.9656C37.1852 23.9656 36.7781 24.3727 36.7781 24.875Z" fill="#2A4143" />
                                        <path d="M82.3294 33.4265L85.6729 41.7296H80.4636L77.5661 34.318H74.0205V41.7296H69.2837V22.252H79.1822C82.4977 22.252 85.2284 24.9547 85.2284 28.2703C85.2257 29.3057 84.9578 30.3233 84.4503 31.2259C83.9429 32.1285 83.2127 32.8861 82.3294 33.4265ZM80.4622 28.2717C80.4583 27.933 80.322 27.6093 80.0826 27.3699C79.8431 27.1304 79.5194 26.9941 79.1807 26.9902H74.0205V29.5816H79.1822C79.8788 29.5816 80.4636 28.9963 80.4636 28.2717H80.4622Z" fill="#2A4143" />
                                        <path d="M106.626 31.9497C106.628 33.26 106.371 34.5577 105.87 35.7685C105.369 36.9793 104.635 38.0795 103.708 39.0061C102.782 39.9326 101.681 40.6673 100.471 41.1682C99.2598 41.669 97.9621 41.9261 96.6518 41.9248C91.1629 41.9248 86.6768 37.4666 86.6768 31.9497C86.6768 26.4607 91.1605 21.9746 96.6518 21.9746C102.169 21.9746 106.626 26.4607 106.626 31.9497ZM101.89 31.9497C101.891 31.2616 101.756 30.5801 101.493 29.9442C101.23 29.3083 100.844 28.7306 100.358 28.244C99.871 27.7574 99.2932 27.3716 98.6573 27.1086C98.0214 26.8457 97.3399 26.7107 96.6518 26.7114C93.7818 26.7114 91.4136 29.0521 91.4136 31.9497C91.4136 34.8472 93.7818 37.1879 96.6518 37.1879C97.3399 37.1887 98.0214 37.0537 98.6573 36.7907C99.2932 36.5278 99.871 36.1419 100.358 35.6554C100.844 35.1688 101.23 34.591 101.493 33.9552C101.756 33.3193 101.891 32.6378 101.89 31.9497Z" fill="#2A4143" />
                                    </svg>
                                )}
                                {window.innerWidth > 800 && window.innerWidth < 1023 &&
                                (
                                    <svg width="160" height="55" viewBox="0 0 180 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M61.2153 41.7407V22.2344H65.9958V41.7407H61.2153Z" fill="#2A4143" />
                                        <path d="M108.96 41.7407V22.2344H113.053L121.46 34.158V22.2344H126.048V41.7407H121.955L113.548 29.8171V41.7407H108.96Z" fill="#2A4143" />
                                        <path d="M128.6 41.7407V22.2344H133.381V41.7407H128.6Z" fill="#CC214F" />
                                        <path d="M135.385 41.7407L143.105 22.2344H148.188L155.908 41.7407H150.962L149.589 37.9493H141.539L140.165 41.7407H135.385ZM142.995 33.9382H148.133L145.55 26.8225L142.995 33.9382Z" fill="#CC214F" />
                                        <path d="M36.3752 27.1719L28.0524 40.9062L47.9866 40.9062L45.7971 37.5225" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M43.1375 33.459L42.5045 32.4808L41.6551 31.2028" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M40.7812 24.875C40.7812 26.5836 39.3961 27.9688 37.6875 27.9688C35.9789 27.9688 34.5938 26.5836 34.5938 24.875C34.5938 23.1664 35.9789 21.7812 37.6875 21.7812C39.3961 21.7812 40.7812 23.1664 40.7812 24.875ZM36.7781 24.875C36.7781 25.3773 37.1852 25.7844 37.6875 25.7844C38.1898 25.7844 38.5969 25.3773 38.5969 24.875C38.5969 24.3727 38.1898 23.9656 37.6875 23.9656C37.1852 23.9656 36.7781 24.3727 36.7781 24.875Z" fill="#2A4143" />
                                        <path d="M82.3294 33.4265L85.6729 41.7296H80.4636L77.5661 34.318H74.0205V41.7296H69.2837V22.252H79.1822C82.4977 22.252 85.2284 24.9547 85.2284 28.2703C85.2257 29.3057 84.9578 30.3233 84.4503 31.2259C83.9429 32.1285 83.2127 32.8861 82.3294 33.4265ZM80.4622 28.2717C80.4583 27.933 80.322 27.6093 80.0826 27.3699C79.8431 27.1304 79.5194 26.9941 79.1807 26.9902H74.0205V29.5816H79.1822C79.8788 29.5816 80.4636 28.9963 80.4636 28.2717H80.4622Z" fill="#2A4143" />
                                        <path d="M106.626 31.9497C106.628 33.26 106.371 34.5577 105.87 35.7685C105.369 36.9793 104.635 38.0795 103.708 39.0061C102.782 39.9326 101.681 40.6673 100.471 41.1682C99.2598 41.669 97.9621 41.9261 96.6518 41.9248C91.1629 41.9248 86.6768 37.4666 86.6768 31.9497C86.6768 26.4607 91.1605 21.9746 96.6518 21.9746C102.169 21.9746 106.626 26.4607 106.626 31.9497ZM101.89 31.9497C101.891 31.2616 101.756 30.5801 101.493 29.9442C101.23 29.3083 100.844 28.7306 100.358 28.244C99.871 27.7574 99.2932 27.3716 98.6573 27.1086C98.0214 26.8457 97.3399 26.7107 96.6518 26.7114C93.7818 26.7114 91.4136 29.0521 91.4136 31.9497C91.4136 34.8472 93.7818 37.1879 96.6518 37.1879C97.3399 37.1887 98.0214 37.0537 98.6573 36.7907C99.2932 36.5278 99.871 36.1419 100.358 35.6554C100.844 35.1688 101.23 34.591 101.493 33.9552C101.756 33.3193 101.891 32.6378 101.89 31.9497Z" fill="#2A4143" />
                                    </svg>
                                )}
                                {window.innerWidth === 1023 &&
                                (
                                    <svg width="160" height="55" viewBox="0 0 180 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M61.2153 41.7407V22.2344H65.9958V41.7407H61.2153Z" fill="#2A4143" />
                                        <path d="M108.96 41.7407V22.2344H113.053L121.46 34.158V22.2344H126.048V41.7407H121.955L113.548 29.8171V41.7407H108.96Z" fill="#2A4143" />
                                        <path d="M128.6 41.7407V22.2344H133.381V41.7407H128.6Z" fill="#CC214F" />
                                        <path d="M135.385 41.7407L143.105 22.2344H148.188L155.908 41.7407H150.962L149.589 37.9493H141.539L140.165 41.7407H135.385ZM142.995 33.9382H148.133L145.55 26.8225L142.995 33.9382Z" fill="#CC214F" />
                                        <path d="M36.3752 27.1719L28.0524 40.9062L47.9866 40.9062L45.7971 37.5225" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M43.1375 33.459L42.5045 32.4808L41.6551 31.2028" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M40.7812 24.875C40.7812 26.5836 39.3961 27.9688 37.6875 27.9688C35.9789 27.9688 34.5938 26.5836 34.5938 24.875C34.5938 23.1664 35.9789 21.7812 37.6875 21.7812C39.3961 21.7812 40.7812 23.1664 40.7812 24.875ZM36.7781 24.875C36.7781 25.3773 37.1852 25.7844 37.6875 25.7844C38.1898 25.7844 38.5969 25.3773 38.5969 24.875C38.5969 24.3727 38.1898 23.9656 37.6875 23.9656C37.1852 23.9656 36.7781 24.3727 36.7781 24.875Z" fill="#2A4143" />
                                        <path d="M82.3294 33.4265L85.6729 41.7296H80.4636L77.5661 34.318H74.0205V41.7296H69.2837V22.252H79.1822C82.4977 22.252 85.2284 24.9547 85.2284 28.2703C85.2257 29.3057 84.9578 30.3233 84.4503 31.2259C83.9429 32.1285 83.2127 32.8861 82.3294 33.4265ZM80.4622 28.2717C80.4583 27.933 80.322 27.6093 80.0826 27.3699C79.8431 27.1304 79.5194 26.9941 79.1807 26.9902H74.0205V29.5816H79.1822C79.8788 29.5816 80.4636 28.9963 80.4636 28.2717H80.4622Z" fill="#2A4143" />
                                        <path d="M106.626 31.9497C106.628 33.26 106.371 34.5577 105.87 35.7685C105.369 36.9793 104.635 38.0795 103.708 39.0061C102.782 39.9326 101.681 40.6673 100.471 41.1682C99.2598 41.669 97.9621 41.9261 96.6518 41.9248C91.1629 41.9248 86.6768 37.4666 86.6768 31.9497C86.6768 26.4607 91.1605 21.9746 96.6518 21.9746C102.169 21.9746 106.626 26.4607 106.626 31.9497ZM101.89 31.9497C101.891 31.2616 101.756 30.5801 101.493 29.9442C101.23 29.3083 100.844 28.7306 100.358 28.244C99.871 27.7574 99.2932 27.3716 98.6573 27.1086C98.0214 26.8457 97.3399 26.7107 96.6518 26.7114C93.7818 26.7114 91.4136 29.0521 91.4136 31.9497C91.4136 34.8472 93.7818 37.1879 96.6518 37.1879C97.3399 37.1887 98.0214 37.0537 98.6573 36.7907C99.2932 36.5278 99.871 36.1419 100.358 35.6554C100.844 35.1688 101.23 34.591 101.493 33.9552C101.756 33.3193 101.891 32.6378 101.89 31.9497Z" fill="#2A4143" />
                                    </svg>
                                )}
                                {window.innerWidth > 1024 && window.innerWidth < 1365 &&
                                (
                                    <svg width="160" height="55" viewBox="10 0 190 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M61.2153 41.7407V22.2344H65.9958V41.7407H61.2153Z" fill="#2A4143" />
                                        <path d="M108.96 41.7407V22.2344H113.053L121.46 34.158V22.2344H126.048V41.7407H121.955L113.548 29.8171V41.7407H108.96Z" fill="#2A4143" />
                                        <path d="M128.6 41.7407V22.2344H133.381V41.7407H128.6Z" fill="#CC214F" />
                                        <path d="M135.385 41.7407L143.105 22.2344H148.188L155.908 41.7407H150.962L149.589 37.9493H141.539L140.165 41.7407H135.385ZM142.995 33.9382H148.133L145.55 26.8225L142.995 33.9382Z" fill="#CC214F" />
                                        <path d="M36.3752 27.1719L28.0524 40.9062L47.9866 40.9062L45.7971 37.5225" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M43.1375 33.459L42.5045 32.4808L41.6551 31.2028" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M40.7812 24.875C40.7812 26.5836 39.3961 27.9688 37.6875 27.9688C35.9789 27.9688 34.5938 26.5836 34.5938 24.875C34.5938 23.1664 35.9789 21.7812 37.6875 21.7812C39.3961 21.7812 40.7812 23.1664 40.7812 24.875ZM36.7781 24.875C36.7781 25.3773 37.1852 25.7844 37.6875 25.7844C38.1898 25.7844 38.5969 25.3773 38.5969 24.875C38.5969 24.3727 38.1898 23.9656 37.6875 23.9656C37.1852 23.9656 36.7781 24.3727 36.7781 24.875Z" fill="#2A4143" />
                                        <path d="M82.3294 33.4265L85.6729 41.7296H80.4636L77.5661 34.318H74.0205V41.7296H69.2837V22.252H79.1822C82.4977 22.252 85.2284 24.9547 85.2284 28.2703C85.2257 29.3057 84.9578 30.3233 84.4503 31.2259C83.9429 32.1285 83.2127 32.8861 82.3294 33.4265ZM80.4622 28.2717C80.4583 27.933 80.322 27.6093 80.0826 27.3699C79.8431 27.1304 79.5194 26.9941 79.1807 26.9902H74.0205V29.5816H79.1822C79.8788 29.5816 80.4636 28.9963 80.4636 28.2717H80.4622Z" fill="#2A4143" />
                                        <path d="M106.626 31.9497C106.628 33.26 106.371 34.5577 105.87 35.7685C105.369 36.9793 104.635 38.0795 103.708 39.0061C102.782 39.9326 101.681 40.6673 100.471 41.1682C99.2598 41.669 97.9621 41.9261 96.6518 41.9248C91.1629 41.9248 86.6768 37.4666 86.6768 31.9497C86.6768 26.4607 91.1605 21.9746 96.6518 21.9746C102.169 21.9746 106.626 26.4607 106.626 31.9497ZM101.89 31.9497C101.891 31.2616 101.756 30.5801 101.493 29.9442C101.23 29.3083 100.844 28.7306 100.358 28.244C99.871 27.7574 99.2932 27.3716 98.6573 27.1086C98.0214 26.8457 97.3399 26.7107 96.6518 26.7114C93.7818 26.7114 91.4136 29.0521 91.4136 31.9497C91.4136 34.8472 93.7818 37.1879 96.6518 37.1879C97.3399 37.1887 98.0214 37.0537 98.6573 36.7907C99.2932 36.5278 99.871 36.1419 100.358 35.6554C100.844 35.1688 101.23 34.591 101.493 33.9552C101.756 33.3193 101.891 32.6378 101.89 31.9497Z" fill="#2A4143" />
                                    </svg>
                                )}
                                {window.innerWidth === 1365 &&
                                (
                                    <svg width="220" height="55" viewBox="0 0 180 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M61.2153 41.7407V22.2344H65.9958V41.7407H61.2153Z" fill="#2A4143" />
                                        <path d="M108.96 41.7407V22.2344H113.053L121.46 34.158V22.2344H126.048V41.7407H121.955L113.548 29.8171V41.7407H108.96Z" fill="#2A4143" />
                                        <path d="M128.6 41.7407V22.2344H133.381V41.7407H128.6Z" fill="#CC214F" />
                                        <path d="M135.385 41.7407L143.105 22.2344H148.188L155.908 41.7407H150.962L149.589 37.9493H141.539L140.165 41.7407H135.385ZM142.995 33.9382H148.133L145.55 26.8225L142.995 33.9382Z" fill="#CC214F" />
                                        <path d="M36.3752 27.1719L28.0524 40.9062L47.9866 40.9062L45.7971 37.5225" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M43.1375 33.459L42.5045 32.4808L41.6551 31.2028" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M40.7812 24.875C40.7812 26.5836 39.3961 27.9688 37.6875 27.9688C35.9789 27.9688 34.5938 26.5836 34.5938 24.875C34.5938 23.1664 35.9789 21.7812 37.6875 21.7812C39.3961 21.7812 40.7812 23.1664 40.7812 24.875ZM36.7781 24.875C36.7781 25.3773 37.1852 25.7844 37.6875 25.7844C38.1898 25.7844 38.5969 25.3773 38.5969 24.875C38.5969 24.3727 38.1898 23.9656 37.6875 23.9656C37.1852 23.9656 36.7781 24.3727 36.7781 24.875Z" fill="#2A4143" />
                                        <path d="M82.3294 33.4265L85.6729 41.7296H80.4636L77.5661 34.318H74.0205V41.7296H69.2837V22.252H79.1822C82.4977 22.252 85.2284 24.9547 85.2284 28.2703C85.2257 29.3057 84.9578 30.3233 84.4503 31.2259C83.9429 32.1285 83.2127 32.8861 82.3294 33.4265ZM80.4622 28.2717C80.4583 27.933 80.322 27.6093 80.0826 27.3699C79.8431 27.1304 79.5194 26.9941 79.1807 26.9902H74.0205V29.5816H79.1822C79.8788 29.5816 80.4636 28.9963 80.4636 28.2717H80.4622Z" fill="#2A4143" />
                                        <path d="M106.626 31.9497C106.628 33.26 106.371 34.5577 105.87 35.7685C105.369 36.9793 104.635 38.0795 103.708 39.0061C102.782 39.9326 101.681 40.6673 100.471 41.1682C99.2598 41.669 97.9621 41.9261 96.6518 41.9248C91.1629 41.9248 86.6768 37.4666 86.6768 31.9497C86.6768 26.4607 91.1605 21.9746 96.6518 21.9746C102.169 21.9746 106.626 26.4607 106.626 31.9497ZM101.89 31.9497C101.891 31.2616 101.756 30.5801 101.493 29.9442C101.23 29.3083 100.844 28.7306 100.358 28.244C99.871 27.7574 99.2932 27.3716 98.6573 27.1086C98.0214 26.8457 97.3399 26.7107 96.6518 26.7114C93.7818 26.7114 91.4136 29.0521 91.4136 31.9497C91.4136 34.8472 93.7818 37.1879 96.6518 37.1879C97.3399 37.1887 98.0214 37.0537 98.6573 36.7907C99.2932 36.5278 99.871 36.1419 100.358 35.6554C100.844 35.1688 101.23 34.591 101.493 33.9552C101.756 33.3193 101.891 32.6378 101.89 31.9497Z" fill="#2A4143" />
                                    </svg>
                                )}
                                {window.innerWidth > 1365 && window.innerWidth <= 1919.19 &&
                                (
                                    <svg width="152" height="60" viewBox="0 0 165 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M61.2153 41.7407V22.2344H65.9958V41.7407H61.2153Z" fill="#2A4143" />
                                        <path d="M108.96 41.7407V22.2344H113.053L121.46 34.158V22.2344H126.048V41.7407H121.955L113.548 29.8171V41.7407H108.96Z" fill="#2A4143" />
                                        <path d="M128.6 41.7407V22.2344H133.381V41.7407H128.6Z" fill="#CC214F" />
                                        <path d="M135.385 41.7407L143.105 22.2344H148.188L155.908 41.7407H150.962L149.589 37.9493H141.539L140.165 41.7407H135.385ZM142.995 33.9382H148.133L145.55 26.8225L142.995 33.9382Z" fill="#CC214F" />
                                        <path d="M36.3752 27.1719L28.0524 40.9062L47.9866 40.9062L45.7971 37.5225" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M43.1375 33.459L42.5045 32.4808L41.6551 31.2028" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M40.7812 24.875C40.7812 26.5836 39.3961 27.9688 37.6875 27.9688C35.9789 27.9688 34.5938 26.5836 34.5938 24.875C34.5938 23.1664 35.9789 21.7812 37.6875 21.7812C39.3961 21.7812 40.7812 23.1664 40.7812 24.875ZM36.7781 24.875C36.7781 25.3773 37.1852 25.7844 37.6875 25.7844C38.1898 25.7844 38.5969 25.3773 38.5969 24.875C38.5969 24.3727 38.1898 23.9656 37.6875 23.9656C37.1852 23.9656 36.7781 24.3727 36.7781 24.875Z" fill="#2A4143" />
                                        <path d="M82.3294 33.4265L85.6729 41.7296H80.4636L77.5661 34.318H74.0205V41.7296H69.2837V22.252H79.1822C82.4977 22.252 85.2284 24.9547 85.2284 28.2703C85.2257 29.3057 84.9578 30.3233 84.4503 31.2259C83.9429 32.1285 83.2127 32.8861 82.3294 33.4265ZM80.4622 28.2717C80.4583 27.933 80.322 27.6093 80.0826 27.3699C79.8431 27.1304 79.5194 26.9941 79.1807 26.9902H74.0205V29.5816H79.1822C79.8788 29.5816 80.4636 28.9963 80.4636 28.2717H80.4622Z" fill="#2A4143" />
                                        <path d="M106.626 31.9497C106.628 33.26 106.371 34.5577 105.87 35.7685C105.369 36.9793 104.635 38.0795 103.708 39.0061C102.782 39.9326 101.681 40.6673 100.471 41.1682C99.2598 41.669 97.9621 41.9261 96.6518 41.9248C91.1629 41.9248 86.6768 37.4666 86.6768 31.9497C86.6768 26.4607 91.1605 21.9746 96.6518 21.9746C102.169 21.9746 106.626 26.4607 106.626 31.9497ZM101.89 31.9497C101.891 31.2616 101.756 30.5801 101.493 29.9442C101.23 29.3083 100.844 28.7306 100.358 28.244C99.871 27.7574 99.2932 27.3716 98.6573 27.1086C98.0214 26.8457 97.3399 26.7107 96.6518 26.7114C93.7818 26.7114 91.4136 29.0521 91.4136 31.9497C91.4136 34.8472 93.7818 37.1879 96.6518 37.1879C97.3399 37.1887 98.0214 37.0537 98.6573 36.7907C99.2932 36.5278 99.871 36.1419 100.358 35.6554C100.844 35.1688 101.23 34.591 101.493 33.9552C101.756 33.3193 101.891 32.6378 101.89 31.9497Z" fill="#2A4143" />
                                    </svg>
                                )}
                                {window.innerWidth > 1919.19 &&
                                (
                                    <svg width="152" height="60" viewBox="0 0 165 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M61.2153 41.7407V22.2344H65.9958V41.7407H61.2153Z" fill="#2A4143" />
                                        <path d="M108.96 41.7407V22.2344H113.053L121.46 34.158V22.2344H126.048V41.7407H121.955L113.548 29.8171V41.7407H108.96Z" fill="#2A4143" />
                                        <path d="M128.6 41.7407V22.2344H133.381V41.7407H128.6Z" fill="#CC214F" />
                                        <path d="M135.385 41.7407L143.105 22.2344H148.188L155.908 41.7407H150.962L149.589 37.9493H141.539L140.165 41.7407H135.385ZM142.995 33.9382H148.133L145.55 26.8225L142.995 33.9382Z" fill="#CC214F" />
                                        <path d="M36.3752 27.1719L28.0524 40.9062L47.9866 40.9062L45.7971 37.5225" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M43.1375 33.459L42.5045 32.4808L41.6551 31.2028" stroke="#CC214F" strokeWidth="2.8125" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M40.7812 24.875C40.7812 26.5836 39.3961 27.9688 37.6875 27.9688C35.9789 27.9688 34.5938 26.5836 34.5938 24.875C34.5938 23.1664 35.9789 21.7812 37.6875 21.7812C39.3961 21.7812 40.7812 23.1664 40.7812 24.875ZM36.7781 24.875C36.7781 25.3773 37.1852 25.7844 37.6875 25.7844C38.1898 25.7844 38.5969 25.3773 38.5969 24.875C38.5969 24.3727 38.1898 23.9656 37.6875 23.9656C37.1852 23.9656 36.7781 24.3727 36.7781 24.875Z" fill="#2A4143" />
                                        <path d="M82.3294 33.4265L85.6729 41.7296H80.4636L77.5661 34.318H74.0205V41.7296H69.2837V22.252H79.1822C82.4977 22.252 85.2284 24.9547 85.2284 28.2703C85.2257 29.3057 84.9578 30.3233 84.4503 31.2259C83.9429 32.1285 83.2127 32.8861 82.3294 33.4265ZM80.4622 28.2717C80.4583 27.933 80.322 27.6093 80.0826 27.3699C79.8431 27.1304 79.5194 26.9941 79.1807 26.9902H74.0205V29.5816H79.1822C79.8788 29.5816 80.4636 28.9963 80.4636 28.2717H80.4622Z" fill="#2A4143" />
                                        <path d="M106.626 31.9497C106.628 33.26 106.371 34.5577 105.87 35.7685C105.369 36.9793 104.635 38.0795 103.708 39.0061C102.782 39.9326 101.681 40.6673 100.471 41.1682C99.2598 41.669 97.9621 41.9261 96.6518 41.9248C91.1629 41.9248 86.6768 37.4666 86.6768 31.9497C86.6768 26.4607 91.1605 21.9746 96.6518 21.9746C102.169 21.9746 106.626 26.4607 106.626 31.9497ZM101.89 31.9497C101.891 31.2616 101.756 30.5801 101.493 29.9442C101.23 29.3083 100.844 28.7306 100.358 28.244C99.871 27.7574 99.2932 27.3716 98.6573 27.1086C98.0214 26.8457 97.3399 26.7107 96.6518 26.7114C93.7818 26.7114 91.4136 29.0521 91.4136 31.9497C91.4136 34.8472 93.7818 37.1879 96.6518 37.1879C97.3399 37.1887 98.0214 37.0537 98.6573 36.7907C99.2932 36.5278 99.871 36.1419 100.358 35.6554C100.844 35.1688 101.23 34.591 101.493 33.9552C101.756 33.3193 101.891 32.6378 101.89 31.9497Z" fill="#2A4143" />
                                    </svg>
                                )}
                            </React.Fragment>
                        )}
                    </Element.ButtonLogo>
                </Element.ColImageLogo>
                {window.innerWidth === 320 &&
                (
                    <React.Fragment>
                        <Element.ColCartButton className="ms-Grid-col">
                            {isActiveManagementPlan === false && <CartButton />}
                        </Element.ColCartButton>
                    </React.Fragment>
                )}
                {window.innerWidth > 320 && window.innerWidth <= 479 &&
                (
                    <React.Fragment>
                        <Element.ColNotifications className="ms-Grid-col">
                            <Element.ButtonNotifications title="Notificaciones">
                                <i className="ironia-icon notifications" />
                            </Element.ButtonNotifications>
                        </Element.ColNotifications>
                        <Element.ColCartButton className="ms-Grid-col">
                            {isActiveManagementPlan === false && <CartButton />}
                        </Element.ColCartButton>
                    </React.Fragment>
                )}
                {window.innerWidth >= 480 && window.innerWidth <= 639 &&
                (
                    <React.Fragment>
                        <Element.ColToggle className="ms-Grid-col">
                            <Toggle toggleTheme={props.toggleTheme} identificator="toggle-large" className="A" />
                        </Element.ColToggle>
                        <Element.ColSeparator2 className="ms-Grid-col">
                            <SpacerIcon className="spacer" />
                        </Element.ColSeparator2>
                        <Element.ColNotifications className="ms-Grid-col">
                            <Element.ButtonNotifications title="Notificaciones">
                                <i className="ironia-icon notifications" />
                            </Element.ButtonNotifications>
                        </Element.ColNotifications>
                        <Element.ColCartButton className="ms-Grid-col">
                            {isActiveManagementPlan === false && <CartButton />}
                        </Element.ColCartButton>
                    </React.Fragment>
                )}
                {window.innerWidth === 640 &&
                (
                    <React.Fragment>
                        <Element.ColPersonStatus className="ms-Grid-col">
                            <PersonStatus user={user} />
                        </Element.ColPersonStatus>
                        <Element.ColNotifications className="ms-Grid-col">
                            <Element.ButtonNotifications title="Notificaciones">
                                <i className="ironia-icon notifications" />
                            </Element.ButtonNotifications>
                        </Element.ColNotifications>
                        <Element.ColCartButton className="ms-Grid-col">
                            {isActiveManagementPlan === false && <CartButton />}
                        </Element.ColCartButton>
                    </React.Fragment>
                )}
                {window.innerWidth >= 641 && window.innerWidth <= 800 &&
                (
                    <React.Fragment>
                        <Element.ColPersonStatus className="ms-Grid-col">
                            <PersonStatus user={user} />
                        </Element.ColPersonStatus>
                        <Element.ColNotifications className="ms-Grid-col">
                            <Element.ButtonNotifications title="Notificaciones">
                                <i className="ironia-icon notifications" />
                            </Element.ButtonNotifications>
                        </Element.ColNotifications>
                        <Element.ColCartButton className="ms-Grid-col">
                            {isActiveManagementPlan === false && <CartButton />}
                        </Element.ColCartButton>
                    </React.Fragment>
                )}
                {window.innerWidth >= 801 && window.innerWidth <= 1023 &&
                (
                    <React.Fragment>
                        <Element.ColPersonStatus className="ms-Grid-col">
                            <PersonStatus user={user} />
                        </Element.ColPersonStatus>
                        <Element.ColToggle className="ms-Grid-col">
                            <Toggle toggleTheme={props.toggleTheme} identificator="toggle-large" className="A" />
                        </Element.ColToggle>
                        <Element.ColSeparator2 className="ms-Grid-col">
                            <SpacerIcon className="spacer" />
                        </Element.ColSeparator2>
                        <Element.ColNotifications className="ms-Grid-col">
                            <Element.ButtonNotifications title="Notificaciones">
                                <i className="ironia-icon notifications" />
                            </Element.ButtonNotifications>
                        </Element.ColNotifications>
                        <Element.ColCartButton className="ms-Grid-col">
                            {isActiveManagementPlan === false && <CartButton />}
                        </Element.ColCartButton>
                    </React.Fragment>
                )}
                {window.innerWidth === 1024 &&
                (
                    <React.Fragment>
                        <Element.ColSearch className="ms-Grid-col">
                            <MainSearch theme={theme}/>
                        </Element.ColSearch>
                        <Element.ColToggle className="ms-Grid-col">
                            <Toggle toggleTheme={props.toggleTheme} identificator="toggle-large" className="A" />
                        </Element.ColToggle>
                        <Element.ColSeparator2 className="ms-Grid-col">
                            <SpacerIcon className="spacer" />
                        </Element.ColSeparator2>
                        <Element.ColNotifications className="ms-Grid-col">
                            <Element.ButtonNotifications title="Notificaciones">
                                <i className="ironia-icon notifications" />
                            </Element.ButtonNotifications>
                        </Element.ColNotifications>
                        <Element.ColPersonStatus className="ms-Grid-col">
                            <PersonStatus user={user} />
                        </Element.ColPersonStatus>
                        <Element.ColCartButton className="ms-Grid-col">
                            {isActiveManagementPlan === false && <CartButton />}
                        </Element.ColCartButton>
                    </React.Fragment>
                )}
                {window.innerWidth >= 1025 &&
                (
                    <React.Fragment>
                        <Element.ColSearch className="ms-Grid-col">
                            <MainSearch theme={theme} />
                        </Element.ColSearch>
                        <Element.ColToggle className="ms-Grid-col">
                            <Toggle toggleTheme={props.toggleTheme} identificator="toggle-large" className="A" />
                            {bubbleVisible &&
                            (
                                <TeachingBubble target="#toggle-large"
                                    hasCloseButton={true}
                                    onDismiss={handleStatusChange}
                                    headline="Esta es la primera vez que accedes a IronIA"
                                >
                                    Puedes cambiar el tema aquí si lo deseas
                                </TeachingBubble>
                            )}
                        </Element.ColToggle>
                        <Element.ColSeparator2 className="ms-Grid-col">
                            <SpacerIcon className="spacer" />
                        </Element.ColSeparator2>
                        <Element.ColNotifications className="ms-Grid-col">
                            <Element.ButtonNotifications title="Notificaciones">
                                <i className="ironia-icon notifications" />
                            </Element.ButtonNotifications>
                        </Element.ColNotifications>
                        <Element.ColPersonStatus className="ms-Grid-col">
                            <PersonStatus user={user} />
                        </Element.ColPersonStatus>
                        <Element.ColCartButton className="ms-Grid-col">
                            {isActiveManagementPlan === false && <CartButton />}
                        </Element.ColCartButton>
                    </React.Fragment>
                )}
            </Element.RowHeader>
            <Switch>
                <Route path={["/portfolio", "/portfolio/operations", "/portfolio/orders", "/portfolio/fiscalReport"]} component={PortfolioMenu} />
                <Route path="/savingsPlans" component={SavingsPlansMenu} />
                <Route path="/search" component={SearchMenu} />
                <Route path="/profile" component={ProfileMenu} />
                <Route path={["/elements", "/elements/ranking", "/ElementsIA"]}>
                    <ElementsMenu savingsPlanElementsStatus={savingsPlanElementsStatus} />
                </Route>
            </Switch>
            <Element.RowStatusBar>
                {activeSavingsPlanHasOutdatedcontract &&
                (
                    <MessageBar messageBarType={MessageBarType.blocked}
                        isMultiline={false}
                        dismissButtonAriaLabel="Close"
                    >
                        La versión del contrato se ha actualizado. Debes <Link to={`/contract/${activePlan.id}`}>firmar la nueva versión</Link> para poder seguir operando.
                    </MessageBar>
                )}
                {savingsPlanElementsStatus.isSubscribed && savingsPlanElementsStatus.isOutdatedContract &&
                (
                    <MessageBar messageBarType={MessageBarType.blocked}
                        isMultiline={false}
                        dismissButtonAriaLabel="Close"
                    >
                        El contrato de Elements se ha actualizado a la versión {savingsPlanElementsStatus.lastContractVersion}. Debes <Link to={`/Elements/Signature`}>firmar la nueva versión</Link>.
                    </MessageBar>
                )}
                {statusBarElements !== undefined && statusBarElements.length > 0 && statusBarElements.map((element, index) =>
                {
                    switch(element.type)
                    {
                        case "Info":
                        {
                            if(element.canDelete === true)
                            {
                                return(
                                    <React.Fragment key={index}>
                                        <MessageBar messageBarType={MessageBarType.info} isMultiline={false} dismissButtonAriaLabel="Close" onDismiss={() => onDeleteNotification(element.id)}>
                                            <Element.StatusBarText dangerouslySetInnerHTML={{__html: element.text}} />
                                        </MessageBar>
                                    </React.Fragment>
                                );
                            }
                            else
                            {
                                return(
                                    <React.Fragment key={index}>
                                        <MessageBar messageBarType={MessageBarType.info} isMultiline={false}>
                                            <Element.StatusBarText dangerouslySetInnerHTML={{__html: element.text}} />
                                        </MessageBar>
                                    </React.Fragment>
                                );
                            }
                        }
                        case "Error":
                        {
                            if(element.canDelete === true)
                            {
                                return(
                                    <React.Fragment key={index}>
                                        <MessageBar messageBarType={MessageBarType.error} isMultiline={false} dismissButtonAriaLabel="Close" onDismiss={() => onDeleteNotification(element.id)}>
                                            <Element.StatusBarText dangerouslySetInnerHTML={{__html: element.text}} />
                                        </MessageBar>
                                    </React.Fragment>
                                );
                            }
                            else
                            {
                                return(
                                    <React.Fragment key={index}>
                                        <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
                                            <Element.StatusBarText dangerouslySetInnerHTML={{__html: element.text}} />
                                        </MessageBar>
                                    </React.Fragment>
                                );
                            }
                        }
                        case "Blocked":
                        {
                            if(element.canDelete === true)
                            {
                                return(
                                    <React.Fragment key={index}>
                                        <MessageBar messageBarType={MessageBarType.blocked} isMultiline={false} dismissButtonAriaLabel="Close" onDismiss={() => onDeleteNotification(element.id)}>
                                            <Element.StatusBarText dangerouslySetInnerHTML={{__html: element.text}} />
                                        </MessageBar>
                                    </React.Fragment>
                                );
                            }
                            else
                            {
                                return(
                                    <React.Fragment key={index}>
                                        <MessageBar messageBarType={MessageBarType.blocked} isMultiline={false}>
                                            <Element.StatusBarText dangerouslySetInnerHTML={{__html: element.text}} />
                                        </MessageBar>
                                    </React.Fragment>
                                );
                            }
                        }
                        case "Warning":
                        {
                            if(element.canDelete === true)
                            {
                                return(
                                    <React.Fragment key={index}>
                                        <MessageBar messageBarType={MessageBarType.warning} isMultiline={false} dismissButtonAriaLabel="Close" messageBarIconProps={warningIcon} onDismiss={() => onDeleteNotification(element.id)}>
                                            <Element.StatusBarText dangerouslySetInnerHTML={{__html: element.text}} />
                                        </MessageBar>
                                    </React.Fragment>
                                );
                            }
                            else
                            {
                                return(
                                    <React.Fragment key={index}>
                                        <MessageBar messageBarType={MessageBarType.warning} isMultiline={false} messageBarIconProps={warningIcon}>
                                            <Element.StatusBarText dangerouslySetInnerHTML={{__html: element.text}} />
                                        </MessageBar>
                                    </React.Fragment>
                                );
                            }
                        }
                        case "SevereWarning":
                        {
                            if(element.canDelete === true)
                            {
                                return(
                                    <React.Fragment key={index}>
                                        <MessageBar messageBarType={MessageBarType.severeWarning} isMultiline={false} dismissButtonAriaLabel="Close" onDismiss={() => onDeleteNotification(element.id)}>
                                            <Element.StatusBarText dangerouslySetInnerHTML={{__html: element.text}} />
                                        </MessageBar>
                                    </React.Fragment>
                                );
                            }
                            else
                            {
                                return(
                                    <React.Fragment key={index}>
                                        <MessageBar messageBarType={MessageBarType.severeWarning} isMultiline={false}>
                                            <Element.StatusBarText dangerouslySetInnerHTML={{__html: element.text}} />
                                        </MessageBar>
                                    </React.Fragment>
                                );
                            }
                        }
                        case "Success":
                        {
                            if(element.canDelete === true)
                            {
                                return(
                                    <React.Fragment key={index}>
                                        <MessageBar messageBarType={MessageBarType.success} isMultiline={false} dismissButtonAriaLabel="Close" onDismiss={() => onDeleteNotification(element.id)}>
                                            <Element.StatusBarText dangerouslySetInnerHTML={{__html: element.text}} />
                                        </MessageBar>
                                    </React.Fragment>
                                );
                            }
                            else
                            {
                                return(
                                    <React.Fragment key={index}>
                                        <MessageBar messageBarType={MessageBarType.success} isMultiline={false}>
                                            <Element.StatusBarText dangerouslySetInnerHTML={{__html: element.text}} />
                                        </MessageBar>
                                    </React.Fragment>
                                );
                            }
                        }
                    }
                })}
            </Element.RowStatusBar>
        </Element.HeaderMain>
    );
};

export default connect(mapStateToProps)(Header);