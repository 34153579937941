import { Separator } from 'office-ui-fabric-react/lib/Separator';
import { useEffect, useRef, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import Auth from "../../Auth/Auth";
import { UserType } from '../../services/UserService';
import SavingsPlans, { SavingsPlanType } from '../../services/SavingsPlanService';
import { completeUserDataRedux } from "../../store/auth/actions";
import { Link } from 'react-router-dom';
import ProccesingModalComponent from "./ProccesingModalComponent";
import { useBoolean } from '@fluentui/react-hooks';
import './Payments.sass';
import React from 'react';
import subscriptionService, { SubscriptionType } from '../../services/SubscriptionService';


const PaymentServicesOk = () => {
    const dispatch = useDispatch();
    const user: UserType = Auth.getUserProfile();
    const [isLoaded, setIsLoaded] = useState(false);
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const firstTime = useRef<boolean>(true);
    const [suscription, setSuscription] = useState<SubscriptionType | null>(null);

    if (user.druId !== undefined && firstTime.current) {
        firstTime.current = false;
        dispatch(completeUserDataRedux(user.druId));
    }

    const search = useLocation().search;
    const subscriptionId = new URLSearchParams(search).get('subscriptionId');

      // Comprobar si el usuario está suscrito al servicio "Mercado"
      useEffect(() => {
        if(subscriptionId !== null){
            showModal();
            subscriptionService.getSubscription(Number(subscriptionId)).then((response: SubscriptionType) => {
                if (response && response.id) {
                    setSuscription(response);
                    setIsLoaded(true);
                    hideModal();
                }
            });
        }
        
    }, [subscriptionId]);


    return (
        <DocumentTitle title='IronIA - Pago correcto'>
            <React.Fragment>
                <h3>Pago realizado</h3>
                {isLoaded &&
                    <p className="description">El pago de la {suscription?.description} se ha realizado correctamente</p>
                }
                <Separator className="separator" />
                {subscriptionId !== null &&
                    (
                        <React.Fragment>
                            {isLoaded &&
                                <React.Fragment>
                                    <p className="text-right">
                                        <Link className="button primary-button" to={'/portfolio/market'}>
                                            Ir a mi servicio de {suscription?.name}
                                        </Link>
                                    </p>
                                </React.Fragment>
                            }
                        </React.Fragment>
                    )}
                <ProccesingModalComponent isModalOpen={isModalOpen} hideModal={hideModal} />
            </React.Fragment>
        </DocumentTitle>
    );
};

export default PaymentServicesOk;