import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Slider } from 'office-ui-fabric-react';
import { IDropdownOption, IDropdownStyles, Dropdown } from '@fluentui/react/lib/Dropdown';
import { Checkbox, Text, DefaultButton, Separator, Spinner, SpinnerSize } from '@fluentui/react';
import NumberFormat from "react-number-format";
import savingsPlans, { SavingsPlanType, SavingsPlanTypes } from '../../services/SavingsPlanService';
import UserService, { UserType } from '../../services/UserService';
import subscriptionService, { SubscriptionType } from '../../services/SubscriptionService';
import savingsPlansUserIdentities, { SavingsPlanUserIdentityType } from '../../services/SavingsPlanUserIdentityService';
import SuitabilityTestsService, { ISuitabilityTestVersion } from '../../services/SuitabilityTestsService';
import { registerIcons } from '@uifabric/styling';
import { createIntl, createIntlCache } from '@formatjs/intl';
import Spanish from '../../translations/es.json';
import styled from 'styled-components';
import Auth from '../../Auth/Auth';
import './SavingsPlans.sass';
import './FirstSteps.sass';
import { decimalFormatter } from '../../utils/numberFormatter';

const cache = createIntlCache();
const intl = createIntl(
    {
        locale: 'es',
        messages: Spanish,
    },
    cache
);

const debounce = require('lodash/debounce');

registerIcons(
{
    icons:
    {
        'ironiaCancel': <i className="ironia-icon cross" />,
    }
});

const options: IDropdownOption[] = [
    { key: 1, text: 'Mensual' },
    { key: 2, text: 'Anual' }
];
var optionsPlans: IDropdownOption[] = [];

const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 300 } };

export interface FirstStepsExperienceInterface
{
    savingsPlan?: SavingsPlanType,
    savingsPlanIsCompleted: boolean,
    isSavingsPlanReady: boolean,
    user: UserType,
    subscription?: SubscriptionType,
    savingsPlanToCopy?: SavingsPlanType,
    hasValueSubscription: boolean,
    publicVersion?: boolean,
    onChangePeriodicQuantity: any,
    onChangeContributionPeriod: any,
    onChangeQuantity?: any,
    isLoadedModelPortfolioComposition?: boolean,
    addFundsToCart?: any,
    onSubmit?: (item: string) => any
}
export const ButtonMoreSubscription = styled.div`
    background-color: #4472c4 !important;
    padding: 0.8em;
    border-radius: 5px;
    margin-top: 7px;
    color: white;
    cursor: pointer;
`;
const FirstStepsExperience: React.FC<FirstStepsExperienceInterface> = (props: FirstStepsExperienceInterface) =>
{
    const valueFormat = (value: number) => `${value}%`;
    const history = useHistory();
    const user = Auth.getUserProfile();
    const [suitabilityTestVersion, setSuitabilityTestVersion] = useState<ISuitabilityTestVersion>();
    const [isLoaded, setIsLoaded] = useState(false);
    const [loadingSubscriptions, setLoadingSubscriptions] = useState(false);
    const [hasSuitabilityTest, setHasSuitabilityTest] = useState(false);
    const [isPlanActiveExperienced, setActivePlanExperienced] = useState<boolean>(false);
    const [isAddingFunds, setIsAddingFunds] = useState(false);
    const [allActivePlans, setAllActivePlans] = useState<any[]>();
    const [activePlansLength, setActivePlansLength] = useState<number>();
    const [dropDownVisible, setDropDownVisible] = useState<boolean>(false);
    const isDifferentSubscription = useRef<boolean>(false);
    const [newSubscription, setNewSubscription] = useState<any>();
    const [identities, setIdentities] = useState<SavingsPlanUserIdentityType[]>();

    const getProgress = () =>
    {
        var progress = 0;
        if(props.publicVersion)
        {
            return progress;
        }
        if(props.isSavingsPlanReady)
        {
            progress += 33
        }
        if(props.savingsPlan?.status !== undefined)
        {
            if(props.savingsPlan.status > 1)
            {
                progress += 33
            }
            if(props.savingsPlan.status > 2 && hasSuitabilityTest)
            {
                progress += 33
            }
        }
        if(progress > 33)
        {
            progress += 1
        }

        return progress;
    };
    useEffect(() =>
    {
        if(!loadingSubscriptions && props.user?.id !== undefined && props.savingsPlan?.type !== undefined && (props.savingsPlan.type === SavingsPlanTypes.Experience))
        {
            setLoadingSubscriptions(true);

            savingsPlans.getActiveSubscriptionAndSavingPlan(props.user.id).then((response: any) =>
            {
                var aux: any[] = [];
                optionsPlans = [];
                // eslint-disable-next-line array-callback-return
                response.map((element: any) =>
                {
                    if(element.type === 3 || element.type === 4)
                    {
                        aux.push(element);
                        return optionsPlans.push({key: element.id, text: element.planName});
                    }
                });

                setAllActivePlans(aux);
                setActivePlansLength(aux.length);
                setIsLoaded(true);
            },
            (error) =>
            {
                setLoadingSubscriptions(false);
            });
        }
    }, [props.user, loadingSubscriptions, props.savingsPlan]);
    useEffect(() =>
    {
        if(!props.publicVersion && props.savingsPlan?.type !== undefined && props.savingsPlan.type !== SavingsPlanTypes.Experience)
        {
            SuitabilityTestsService.getLastSuitabilityTestVersion().then((suitabilityTestVersion: ISuitabilityTestVersion) =>
            {
                setSuitabilityTestVersion(suitabilityTestVersion);
            },() => { });
        }
    }, [props]);
    useEffect(() =>
    {
        if(suitabilityTestVersion !== undefined && props.savingsPlan?.savingsPlansUserIdentities !== undefined && props.savingsPlan.savingsPlansUserIdentities.length > 0)
        {
            var pendingSuitabilityTest = props.savingsPlan.savingsPlansUserIdentities.find((savingsPlanUserIdentity: SavingsPlanUserIdentityType) =>
            {
                return (savingsPlanUserIdentity.userIdentity?.suitabilityTests !== undefined && savingsPlanUserIdentity.userIdentity?.suitabilityTests.length > 0
                    && savingsPlanUserIdentity.userIdentity?.suitabilityTests.some(sT => sT.signatureId !== null))
                    ? savingsPlanUserIdentity.userIdentity?.suitabilityTests.find(sT => sT.suitabilityTestVersionId !== suitabilityTestVersion?.id && sT.signatureId !== null)
                    : [];
            });
            setHasSuitabilityTest(pendingSuitabilityTest === undefined);
        }
    }, [props.savingsPlan, suitabilityTestVersion]);
    useEffect(() =>
    {
        var activePlan = Auth.getActivePlan();

        if(activePlan !== null && (activePlan.type === 3 || activePlan.type === 4))
        {
            setDropDownVisible(false);
            setActivePlanExperienced(true);

            savingsPlansUserIdentities.getSavingsPlanUserIdentitiesBySavingsPlanId(activePlan.id).then((response: SavingsPlanUserIdentityType[]) =>
            {
                setIdentities(response);
            });
        }
        else
        {
            setDropDownVisible(true);
            setActivePlanExperienced(false);
        }
    }, [dropDownVisible, isPlanActiveExperienced]);
    const isDisabledSubscription = () =>
    {
        return !props.savingsPlanIsCompleted
            || props.savingsPlan?.type === undefined
            || (props.savingsPlan.type !== SavingsPlanTypes.Experience && props.savingsPlan.type !== SavingsPlanTypes.Promoted);
    };
    const isDisabledConvenience = () =>
    {
        return props.publicVersion
            || !props.savingsPlanIsCompleted
            || props.savingsPlan?.type === undefined
            || (props.savingsPlan.type !== SavingsPlanTypes.Experience && props.savingsPlan.type !== SavingsPlanTypes.Promoted);
    };
    const isDisabledSuitability = () =>
    {
        return props.publicVersion
            || !props.savingsPlanIsCompleted
            || props.savingsPlan?.status === undefined
            || props.savingsPlan.status < 2;
    };
    const isDisabledInitialContribution = () =>
    {
        return props.publicVersion
            || !props.savingsPlanIsCompleted
            || props.savingsPlan?.status === undefined
            || props.savingsPlan.status < 2
            || !hasSuitabilityTest;
    };
    const isDisabledPeriodicContribution = () =>
    {
        return props.publicVersion
            || !props.savingsPlanIsCompleted
            || props.savingsPlan?.status === undefined
            || props.savingsPlan.status < 2
            || !hasSuitabilityTest;
    };
    const handleDropdown = (ev: any) =>
    {
        var planName: any = ev.target.textContent.slice(0, -1);

        if(allActivePlans !== undefined)
        {
            let obj = allActivePlans.find(o => o.planName === planName);
            if(obj !== undefined && obj !== null)
            {
                savingsPlans.getFullActivePlanById(obj.id).then((response: any) =>
                {
                    if(response !== undefined && response !== null)
                    {
                        UserService.selectActivePlan(user.id, response[0].id).then((updated: boolean) =>
                        {
                            if(updated === true)
                            {
                                Auth.setActivePlan(response[0]);
                                subscriptionService.getSubscription(obj.subscriptionId).then((element: any) =>
                                {
                                    isDifferentSubscription.current = true;
                                    if(activePlansLength === 1)
                                    {
                                        setDropDownVisible(false);
                                    }
                                    setNewSubscription(element);
                                });
                            }
                        });
                    }
                });
            }
        }
    }
    
    return (
        <div className="first-steps shadow">
            <div className="ms-Grid-col ms-sm12">
                <h3 style={{cursor: "default"}}>Da tus primeros pasos</h3>
                <Slider max={100} value={getProgress()} valueFormat={valueFormat} disabled={true} />
            </div>
            <div className="ms-Grid-col ms-sm12">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md3">
                        <div className={'step grey' + (isDisabledSubscription() ? ' disabled' : '')}>
                            <div className="step-content">
                                {!props.isSavingsPlanReady && props.hasValueSubscription === false &&
                                (
                                    <Spinner size={SpinnerSize.large} />
                                )}
                                {props.isSavingsPlanReady && props.hasValueSubscription === false &&
                                (
                                    <React.Fragment>
                                        <div className="row" style={{minHeight: "6em"}}>
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-xl6">
                                                <h4 style={{cursor: "default"}}>{props.subscription?.name}</h4>
                                                {props.subscription?.price !== undefined &&
                                                (
                                                    <h4 style={{cursor: "default"}}>{decimalFormatter.format(props.subscription.price)} / {intl.messages[props.subscription?.period || '']}</h4>
                                                )}
                                            </div>
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-xl6">
                                                <Link to={{pathname: "/morePlans"}}>
                                                    <ButtonMoreSubscription>Más Suscripciones</ButtonMoreSubscription>
                                                </Link>
                                            </div>
                                        </div>
                                        <p style={{marginBottom: 0}}>{props.subscription?.description}</p>
                                    </React.Fragment>
                                )}
                                {props.isSavingsPlanReady && props.hasValueSubscription && isPlanActiveExperienced === false &&
                                (
                                    <React.Fragment>
                                        <div className="row" style={{minHeight: "6em"}}>
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-xl6">
                                                <h4 style={{cursor: "default"}}>Selecciona un plan Activo válido para esta operación</h4>
                                            </div>
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-xl6" style={{marginTop: "0.5em"}}>
                                                <Dropdown placeholder="Planes" options={optionsPlans}
                                                    onChange={handleDropdown}
                                                    calloutProps={{styles: {calloutMain: 'without-spacing'}}}
                                                />
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )}
                                {props.isSavingsPlanReady && props.hasValueSubscription && isPlanActiveExperienced === true &&
                                (
                                    <React.Fragment>
                                        <div className="row" style={{minHeight: "6em"}}>
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-xl6">
                                                {isDifferentSubscription.current === true &&
                                                (
                                                    <React.Fragment>
                                                        <h4 style={{cursor: "default"}}>{newSubscription.name}</h4>
                                                        <h4 style={{cursor: "default"}}>{decimalFormatter.format(newSubscription.price)} €/ {intl.messages[newSubscription.period || '']}</h4>
                                                    </React.Fragment>
                                                )}
                                                {isDifferentSubscription.current === false &&
                                                (
                                                    <React.Fragment>
                                                        <h4 style={{cursor: "default"}}>{props.subscription?.name}</h4>
                                                        {props.subscription?.price !== undefined &&
                                                        (
                                                            <h4 style={{cursor: "default"}}>{decimalFormatter.format(props.subscription.price)} €/ {intl.messages[props.subscription?.period || '']}</h4>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </div>
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-xl6" style={{marginTop: "0.5em"}}>
                                                {dropDownVisible === true &&
                                                (
                                                    <React.Fragment>
                                                        <Dropdown placeholder="Planes" options={optionsPlans} onChange={handleDropdown}
                                                            calloutProps={{ styles: {calloutMain: 'without-spacing'}}}
                                                        />
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </div>
                                        {isDifferentSubscription.current === true &&
                                        (
                                            <React.Fragment>
                                                <p style={{marginBottom: 0}}>{newSubscription.description}</p>
                                            </React.Fragment>
                                        )
                                        }
                                        {isDifferentSubscription.current === false &&
                                        (
                                            <React.Fragment>
                                                <p style={{marginBottom: 0}}>{props.subscription?.description}</p>
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                )}
                            </div>
                            <div className="step-footer text-center">
                                {props.isSavingsPlanReady && props.publicVersion &&
                                (
                                    <React.Fragment>
                                        <Link to="/" className="disabledCursor" onClick={(event) => event.preventDefault()}>
                                            <DefaultButton className="button primary-button pending" disabled={!props.isSavingsPlanReady}>
                                                Suscribirse
                                            </DefaultButton>
                                        </Link>
                                    </React.Fragment>
                                )}
                                {(props.savingsPlan?.type === undefined || props.savingsPlan.type === SavingsPlanTypes.Experience || props.savingsPlan.type === SavingsPlanTypes.Promoted) &&
                                (
                                    <React.Fragment>
                                        {(!props.isSavingsPlanReady ?
                                            <Link to="/" className="disabledCursor" onClick={(event) => event.preventDefault()}>
                                                <DefaultButton className="button primary-button pending" disabled={!props.isSavingsPlanReady}>
                                                    {(props.publicVersion && <>Regístrate</>) || <>Suscribir</>}
                                                </DefaultButton>
                                            </Link>
                                            : 
                                            (
                                                (!props.publicVersion &&
                                                    <React.Fragment>
                                                        {(props.hasValueSubscription === false || !isLoaded) &&
                                                        (
                                                            <React.Fragment>
                                                                <Link to={{pathname: `/subscription/${props.subscription?.id}`,
                                                                    state:
                                                                    {
                                                                        initialContribution: props.savingsPlan?.initialContribution,
                                                                        managedPortfolioId: props.savingsPlan?.managedPortfolioId
                                                                    },
                                                                }}>
                                                                    <DefaultButton className="button primary-button pending" disabled={!props.isSavingsPlanReady || !isLoaded}>
                                                                        Suscribir
                                                                    </DefaultButton>
                                                                </Link>
                                                            </React.Fragment>
                                                        )}
                                                        {props.hasValueSubscription && isLoaded && isPlanActiveExperienced &&
                                                        (
                                                            <React.Fragment>
                                                                <div className='ms-Grid-row' style={{display: "block"}}>
                                                                    <div className='ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12' style={{paddingBottom: "2em", paddingRight: 0}}>
                                                                        <Link to={
                                                                            {
                                                                                pathname: `/savingsPlans/sameIdentities`,
                                                                                state:
                                                                                {
                                                                                    subscription: isDifferentSubscription.current === true
                                                                                        ? newSubscription
                                                                                        : props.subscription,
                                                                                    savingsPlanToCopy: props.savingsPlanToCopy,
                                                                                    identities: identities
                                                                                }
                                                                            }
                                                                        }>
                                                                            <p style={{fontSize: "16px", margin: 0, textAlign: "left"}}>Suscribir Mismos Intervinientes</p>
                                                                        </Link>
                                                                    </div>
                                                                    <div className='ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12' style={{paddingBottom: "2em", paddingRight: 0}}>
                                                                        <Link to={
                                                                            {
                                                                                pathname: `/savingsPlans/differentIdentities`,
                                                                                state:
                                                                                {
                                                                                    savingPlan: props.savingsPlan,
                                                                                    subscription: isDifferentSubscription.current === true
                                                                                        ? newSubscription
                                                                                        : props.subscription
                                                                                }
                                                                            }
                                                                        }>
                                                                            <p style={{fontSize: "16px", margin: 0, textAlign: "left"}}>Suscribir Distintos Intervinientes</p>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                        )}
                                                    </React.Fragment>
                                                ) 
                                                ||
                                                (
                                                    <a href="https://store.ironia.tech" className="notDisabled" target="_blank" rel="noopener noreferrer">
                                                        <DefaultButton type="button" className="button primary-button pending"
                                                            disabled={!props.isSavingsPlanReady}
                                                            onClick={(event) => false}
                                                        >
                                                            Regístrate
                                                        </DefaultButton>
                                                    </a>
                                                )
                                            )
                                        )}
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                        <Separator className="separator hiddenSmUp"/>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md3">
                        <div className={"step " + (isDisabledConvenience() ? " disabled" : "")}>
                            <div className="step-content">
                                <h4>Datos fiscales obligatorios</h4>
                                <p>
                                    Antes de poder operar debes rellenar un breve test para confirmar ante la CNMV que
                                    sabes qué estás haciendo. Recuerda que debes tener tu DNI y al menos una nómina a
                                    mano.
                                </p>
                            </div>
                            {
                                (!props.isSavingsPlanReady || !props.savingsPlanIsCompleted || props.savingsPlan?.status === undefined) && (<Spinner size={SpinnerSize.large} />)
                            }
                            {props.isSavingsPlanReady && props.savingsPlanIsCompleted && props.savingsPlan?.status !== undefined &&
                            (
                                <div className="step-footer">
                                    {props.savingsPlan?.type !== undefined && props.savingsPlan.type !== SavingsPlanTypes.Experience && props.savingsPlan.type !== SavingsPlanTypes.Promoted &&
                                    (
                                        <React.Fragment>
                                            <Link to="/" className="disabledCursor" onClick={(event) => event.preventDefault()}>
                                                <DefaultButton className="button primary-button pending" disabled={!props.isSavingsPlanReady}>
                                                    Próximamente
                                                </DefaultButton>
                                            </Link>
                                        </React.Fragment>
                                    )}
                                    {(props.savingsPlan?.type === undefined || props.savingsPlan.type === SavingsPlanTypes.Experience || props.savingsPlan.type === SavingsPlanTypes.Promoted) &&
                                    (
                                        <React.Fragment>
                                            {props.savingsPlan.id === undefined &&
                                            (
                                                <React.Fragment>
                                                    <Link to={`/convenience/${props.savingsPlan.id}`} className="disabledCursor" onClick={(event) => event.preventDefault()}>
                                                        <DefaultButton className="button primary-button pending" disabled={true}>
                                                            Conveniencia
                                                        </DefaultButton>
                                                    </Link>
                                                </React.Fragment>
                                            )}
                                            {props.savingsPlan.id !== undefined &&
                                            (
                                                <React.Fragment>
                                                    {props.savingsPlan.status === 0 &&
                                                    (
                                                        <Link to={`/convenience/${props.savingsPlan.id}`} className="disabledCursor" onClick={(event) => event.preventDefault()}>
                                                            <DefaultButton className="button primary-button pending" disabled={!props.isSavingsPlanReady || props.publicVersion || !props.user.mainUserIdentity}
                                                                onClick={() => history.push(`/convenience/${props.savingsPlan?.id}`)}
                                                            >
                                                                Conveniencia
                                                            </DefaultButton>
                                                        </Link>
                                                    )}
                                                    {props.savingsPlan.status === 1 &&
                                                    (
                                                        <DefaultButton className="button primary-button" disabled>
                                                            <Text>Pendiente de validación</Text>
                                                            <i className="ironia-icon check"></i>
                                                        </DefaultButton>
                                                    )}
                                                    {props.savingsPlan.status > 1 &&
                                                    (
                                                        <DefaultButton className="button primary-button" disabled>
                                                            <Text>Test realizado correctamente</Text>
                                                            <i className="ironia-icon check"></i>
                                                        </DefaultButton>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    )}
                                </div>
                            )}
                        </div>
                        <Separator className="separator hiddenSmUp" />
                    </div>
                    {props.savingsPlan?.type !== undefined && props.savingsPlan.type !== SavingsPlanTypes.Experience && props.savingsPlan.type !== SavingsPlanTypes.Promoted &&
                    (
                        <div className="ms-Grid-col ms-sm12 ms-md3">
                            <div className={"step" + (isDisabledSuitability() ? " disabled" : "")}>
                                <div className="step-content">
                                    <h4 style={{cursor: "default"}}>Datos idoneidad de la inversión</h4 >
                                    <p>
                                        Estos datos sólo los deberás rellenar si decides invertir a través de un gestor o un
                                        asesor. En ellos presentarás qué tipo de inversión deseas realizar.
                                    </p>
                                </div>
                                {(!props.isSavingsPlanReady || props.savingsPlan?.status === undefined || suitabilityTestVersion === undefined) &&
                                (
                                    <Spinner size={SpinnerSize.large} />
                                )}
                                {(props.isSavingsPlanReady && props.savingsPlan?.status !== undefined && suitabilityTestVersion !== undefined) &&
                                (
                                    <div className="step-footer">
                                        <React.Fragment>
                                            {(props.savingsPlan.status <= 2 || !hasSuitabilityTest) &&
                                            (
                                                <Link to={`/suitabilityTest/${props.savingsPlan.id}`} className="disabledCursor" onClick={(event) => event.preventDefault()}>
                                                    <DefaultButton className="button primary-button pending" disabled={props.publicVersion || props.savingsPlan?.status < 2}
                                                        onClick={() => history.push(`/suitabilityTest/${props.savingsPlan?.id}`)}
                                                    >
                                                        Idoneidad
                                                    </DefaultButton>
                                                </Link>
                                            )}
                                            {hasSuitabilityTest &&
                                            (
                                                <DefaultButton className="button primary-button" disabled>
                                                    <Text>Test realizado correctamente</Text>
                                                    <i className="ironia-icon check"></i>
                                                </DefaultButton >
                                            )}
                                        </React.Fragment>
                                    </div>
                                )}
                            </div>
                            <Separator className="separator hiddenSmUp" />
                        </div>
                    )}
                    <div className="ms-Grid-col ms-sm12 ms-md3">
                        <div className={"step" + (isDisabledInitialContribution() ? " disabled" : "")}>
                            <div className="step-content">
                                <h4 style={{cursor: "default"}}>Aportación inicial</h4>
                                <p>Si seleccionas esta opción deberás indicar qué importe quieres invertir inicialmente.</p>
                                <div className="number-control">
                                    <label className="ms-Label">Importe (euros)</label>
                                    <NumberFormat thousandSeparator="."
                                        decimalSeparator=","
                                        // @ts-ignore
                                        label="¿Cuál es tu dinero base?"
                                        placeholder="Escribe una cantidad..."
                                        value={props.savingsPlan?.initialContribution}
                                        onValueChange={debounce((values: any) => props.onChangeQuantity(values), 500)}
                                        suffix="€"
                                        className="ms-TextField-field"
                                    />
                                </div>
                            </div>
                            <div className="step-footer text-right">
                                <DefaultButton className="button primary-button" 
                                    disabled=
                                    {
                                        isDisabledInitialContribution()
                                        || (props.savingsPlan?.initialContribution !== undefined && props.savingsPlan?.initialContribution < 1)
                                        || !props.isLoadedModelPortfolioComposition
                                        || isAddingFunds
                                    }
                                    onClick={() =>
                                    {
                                        setIsAddingFunds(true);
                                        props.addFundsToCart();
                                    }}
                                >
                                    {isAddingFunds && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                                    Activar
                                </DefaultButton>
                            </div>
                        </div>
                        <Separator className="separator hiddenSmUp" />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md3">
                        <div className={"step" + (isDisabledPeriodicContribution() ? " disabled" : " disabled")}>
                            <div className="step-content">
                                <Checkbox />
                                <div className="checkbox-data">
                                    <h4 style={{cursor: "default"}}>Inversión periódica</h4>
                                    <p>Si seleccionas esta opción deberás indicar qué importe quieres invertir y su periodicidad.</p>
                                    <div className="ms-Grid-col ms-sm6">
                                        <div className="number-control">
                                            <label className="ms-Label">Importe (euros)</label>
                                            <NumberFormat thousandSeparator="."
                                                decimalSeparator=","
                                                value={props.savingsPlan?.periodicContribution}
                                                onChange={props.onChangePeriodicQuantity}
                                                suffix="€"
                                                className="ms-TextField-field"
                                            />
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm6">
                                        <Dropdown defaultSelectedKey={props.savingsPlan?.periodicContributionPeriod}
                                            label="Periodicidad"
                                            options={options}
                                            styles={dropdownStyles}
                                            onChange={props.onChangeContributionPeriod}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FirstStepsExperience;