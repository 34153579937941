import { useEffect, useState } from "react";
import DocumentTitle from "react-document-title";
import CompetitionsService, { CompetitionType, CompetitionTypes, CompetitionsUsersType, ListOfQuickLinks, QuickLink } from "../../services/CompetitionsService";
import { PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";
import styles from './index.module.sass';
import { currencyNeutreFormatter, percentFormatter } from "../../utils/numberFormatter";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { DataAndCartLines } from "../../services/PortfolioModelWalletService";
import BackButton from "../../components/buttons/BackButton";
import { Card } from "../../components/Payments/RePaymentSubscriptionStyle";
import CardWithInfo from "../../components/cardwithinfo/CardWithInfo";
import { CompetitionQuickLinks } from "../../components/competitionQuickLinks/CompetitionQuickLinks";
import * as L from "./layout.styles";
import CountdownTimer from "../../components/CountDownTimer/CountDownTimer";

const options: Highcharts.Options =
{
    title:
    {
        text: '',
    },
    xAxis: { visible: false },
    yAxis: { visible: false },
    legend: { enabled: false },
    exporting: { enabled: false },
    credits: { enabled: false },
    chart:
    {
        type: 'bar',
        margin: 0,
        height: 24,
        backgroundColor: "transparent",
        borderColor: "transparent"
    },
    tooltip:
    {
        headerFormat: "",
        valueSuffix: '%',
        outside: true,
    },
    plotOptions:
    {
        bar:
        {
            stacking: 'percent',
            groupPadding: 0,
            pointPadding: 0,
            borderRadius: 4,
            borderColor: "transparent",
        },
    },
};

interface TimeLeft {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  }

const CompetitionPromotionated = () =>
{
    let history = useHistory();
    const [competition, setCompetition] = useState<CompetitionType>();
    const [banners, setBanners] = useState<ListOfQuickLinks>({ quicklinks: [] });
    const [competitionUsers, setCompetitionUsers] = useState<CompetitionsUsersType[]>();
    const [series, setSeries] = useState<any[]>([]);
    const [error, setError] = useState({ status: false, message: '' });
    const [loading, setLoading] = useState<boolean>(true);

    const location = useLocation();

    const state = location.state as { competitionId?: string };;
    const competitionId = state != undefined && state.competitionId != undefined ? parseInt(state.competitionId) : 0;

    useEffect(() =>
    {
        Promise.all([
            CompetitionsService.getCompetition(competitionId), 
            CompetitionsService.getCompetitionBanners(competitionId)])
        .then(([competitionData, banners]) => {
                setCompetition(competitionData);
                setBanners(banners)
        },
            (error: any) =>
            {
                setError(error);
            }).finally(() => {
            setLoading(false);
        })

        
    }, []);

    useEffect(() =>
    {
        if (competition !== undefined)
        {
            var competitionsUsers = competition.competitionsUsers.map((competitionUser: CompetitionsUsersType) =>
            {
                let performance = 0;
                const valueVirtualPortfolios = competitionUser.portfolioModel.valueVirtualPortfolios;

                if (valueVirtualPortfolios.length !== 0 && valueVirtualPortfolios[0].ammount !== 0)
                {
                    const amountDistintInitial = valueVirtualPortfolios.filter(vVP => vVP.ammount !== valueVirtualPortfolios[0].ammount);
                    if (amountDistintInitial.length > 0)
                    {
                        performance = (amountDistintInitial[amountDistintInitial.length - 1].ammount - valueVirtualPortfolios[0].ammount) / valueVirtualPortfolios[0].ammount;
                    }
                }
                competitionUser.performance = performance;
                return competitionUser;
            });
            const sortedCompetitionsUsers = competitionsUsers.sort((a: CompetitionsUsersType, b: CompetitionsUsersType) => ((a.performance ?? 0) > (b.performance ?? 0) ? -1 : 1));
            setCompetitionUsers(sortedCompetitionsUsers);
        }
    }, [competition]);

    useEffect(() =>
    {
        if (competitionUsers !== undefined)
        {
            var seriesAux: any[] = [];
            competitionUsers.forEach((competitionUser: CompetitionsUsersType) =>
            {
                var serie: any[] = [];
                competitionUser.portfolioModel.jsonFundsData.forEach((dataAndCartLine: DataAndCartLines) =>
                {
                    serie.push(
                        {
                            name: dataAndCartLine.fund.name,
                            data: [dataAndCartLine.modelPercentage]
                        });
                });
                seriesAux.push(serie.sort((serieItemA, serieItemB) => serieItemA.data[0] - serieItemB.data[0]));
            });
            setSeries(seriesAux);
        }
    }, [competitionUsers]);    

    function convertToISOFormat(dateString: string): string {
        const [day, month, year] = dateString.split("/").map(Number);
        return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}T00:00:00`;
      }

    if (error.status)
    {
        return (
            <DocumentTitle title="IronIA - Competiciones - Versión Preliminar">
                <div>Error: {error.message}</div>
            </DocumentTitle>
        );
    }

    return (
        <DocumentTitle title={`IronIA - Competiciones - ${competition?.name} - Versión Preliminar`}>
            <>
                {loading && (<Spinner size={SpinnerSize.large} />)}
                {(!loading && competition == undefined && <p>Se ha producido un error al traer la información.</p>)}
                {(!loading  && competition != undefined )&& (<>
                    <section className={`${styles.competition_p}`}>
                        <BackButton />
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm8">
                                <h2>{competition?.name}</h2>

                            </div>
                            <div className="ms-Grid-col ms-sm4" style={{ display: "flex", justifyContent: "right" }}>
                                <PrimaryButton
                                    className="rounded"
                                    style={{
                                        marginTop: "0px",
                                        marginLeft: "8px",
                                        marginRight: "3px",
                                    }}
                                    onClick={() =>
                                    {
                                        history.push(
                                            `/competitions/join/nsv/${competitionId}`
                                        );
                                    }}
                                >
                                    Participar
                                </PrimaryButton>
                            </div>
                            <div className="ms-Grid-col ms-sm12">
                                <p dangerouslySetInnerHTML={{ __html: competition?.description ?? '' }} />
                            </div>

                        </div>
                        <div style={{display: "flex", justifyContent: "space-between", width: "100%", margin: "20px"}}>
                            <div className="ms-Grid-col ms-sm2">
                                <CardWithInfo
                                    title="Información"
                                    body={
                                        <div>
                                        <div>
                                            <p>Comienzo: {competition?.startDate.split("/")[1]}/{competition?.startDate.split("/")[0]}/{competition?.startDate.split("/")[2]}</p>
                                            <p>Final:  {competition?.endDate.split("/")[1]}/{competition?.endDate.split("/")[0]}/{competition?.endDate.split("/")[2]}</p>
                                        </div>
                                        {competition != undefined &&  <CountdownTimer startDate={competition.startDate} endDate={competition.endDate} /> }
                                        </div>
                                    } />
                            </div>
                            {competition?.firstPrice != undefined &&  <div className="ms-Grid-col ms-sm2">
                                <CardWithInfo
                                    title="Primer Premio"
                                    body={
                                        <div>
                                            <p>{competition?.firstPrice}</p>
                                        </div>
                                    }
                                />
                            </div>}
                           
                            {competition?.secondPrice != undefined && <div className="ms-Grid-col ms-sm2">
                                <CardWithInfo
                                    title="Segundo Premio"
                                    body={
                                        <div>
                                            <p>{competition?.secondPrice}</p>
                                        </div>
                                    }
                                />
                            </div>}
                            { competition?.thirdPrice != undefined && <div className="ms-Grid-col ms-sm2">
                                <CardWithInfo
                                    title="Tercer Premio"
                                    body={
                                        <div>
                                            <p>{competition?.thirdPrice}</p>
                                        </div>
                                    }
                                />
                            </div>
                            }
                            
                            <div className="ms-Grid-col ms-sm2">
                            </div>
                            {
                                competition?.specialPrice != undefined &&  <div className="ms-Grid-col ms-sm2">
                                <CardWithInfo
                                    title="Premio Especial"
                                    body={
                                        <div>
                                            <p>{competition?.specialPrice}</p>
                                        </div>
                                    }
                                />
                            </div>
                            }
                           
                        </div>
                        {banners != undefined && banners?.quicklinks.length > 0 && <div className="ms-Grid-row" style={{margin: "20px"}}>
                            <L.CompetitionPatronitedPatrocin>PATROCINADORES</L.CompetitionPatronitedPatrocin>
                            <CompetitionQuickLinks quicklinks={banners?.quicklinks || []} />
                        </div>}
                        
                        {competition?.startDate != undefined && new Date(competition?.startDate) <= new Date() && <>
                            <section className="ms-Grid-row">
                                <div className={`${styles.tableContainer_p} background-tertiary`}>
                                    {competitionUsers && (
                                        <div className={styles.tableScroll}>
                                            <table>
                                                <thead className="background-shadow">
                                                    <tr>
                                                        <td className={styles.positionHead}>#</td>
                                                        <td className={styles.performanceHead}>Rentabilidad</td>
                                                        <td className={styles.nameHead}>Cartera</td>
                                                        <td className={styles.compositionHead}>Composisión</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {competitionUsers.map((competitionUser, competitionUserIndex) => (
                                                        <tr key={`competitionUserIndex{competitionUserIndex}`}>
                                                            <td className={styles.position}>{competitionUserIndex + 1}</td>
                                                            <td className={styles.performance}>{percentFormatter.format(competitionUser.performance ?? 0)}</td>
                                                            <td className={styles.name}>{competitionUser.portfolioModel.name}</td>
                                                            <td className={styles.composition}>
                                                                <HighchartsReact highcharts={Highcharts}
                                                                    options={{
                                                                        ...options,
                                                                        series: series[competitionUserIndex]
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </div>
                            </section>
                        </>}
                    </section>
                </>)}
            </>
        </DocumentTitle>
    );
};

export default CompetitionPromotionated;
