import React, { useState, useEffect } from "react";

// Tipos para representar el tiempo restante
interface TimeLeft {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

// Props del componente
interface CountdownTimerProps {
  startDate: string; // Fecha de inicio
  endDate: string;   // Fecha de fin
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ startDate, endDate }) => {
  const start = new Date(convertDateFormat(startDate));
  const end = new Date(convertDateFormat(endDate));

  // Estado inicial (loading)
  const [isLoading, setIsLoading] = useState(true); 
  const [timeRemaining, setTimeRemaining] = useState<TimeLeft | null>(null); 
  const [status, setStatus] = useState<string>(""); 

  // Cálculo del tiempo restante
  function calculateTimeLeft(target: Date): TimeLeft {
    const now = new Date();
    const difference = target.getTime() - now.getTime();

    if (difference > 0) {
      return {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / (1000 * 60)) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 }; // Tiempo agotado
    }
  }

  // Convertir MM/DD/YYYY a YYYY-MM-DDTHH:mm:ss
  function convertDateFormat(dateString: string): string {
    const [month, day, year] = dateString.split("/").map(Number);
    return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}T00:00:00`;
  }

  // Actualiza el estado del contador y la lógica del estado
  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();

      if (now < start) {
        setTimeRemaining(calculateTimeLeft(start));
        setStatus("Empieza en");
      } else if (now >= start && now < end) {
        setTimeRemaining(calculateTimeLeft(end));
        setStatus("Acaba en");
      } else {
        setTimeRemaining({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        setStatus("Competición finalizada");
      }

      // Después de determinar el estado, se apaga el loading
      setIsLoading(false);

    }, 1000);

    return () => clearInterval(timer); // Limpia el intervalo
  }, [start, end]);

  if (isLoading) {
    return (
      <div style={{ textAlign: "center", color: "#cc214f" }}>
        <p>Cargando...</p>
      </div>
    );
  }

  return (
    <div style={{ textAlign: "right", color: "#cc214f", marginTop: "16px" }}>
      <div>
        {status !== "Competición finalizada" ? (
          <>
            {status}&nbsp;
            <b>
              {timeRemaining?.days}D {" "} {timeRemaining?.hours}H
              {" "}{timeRemaining?.minutes}M  {" "}{timeRemaining?.seconds}S
            </b>
          </>
        ) : (
          <b>{status}</b>
        )}
      </div>
    </div>
  );
};

export default CountdownTimer;