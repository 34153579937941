import { Callout, DefaultButton, DetailsList, IColumn, SelectionMode } from "office-ui-fabric-react";
import React from "react";
import { IFund } from "../../../services/FundService";

interface FundDocumentsCalloutProps
{
    fund?: IFund;
    onClose: () => any;
    isVisible: boolean;
}
const documentsColumns: IColumn[] = [
    { key: "name", name: "Fichero", fieldName: "name", minWidth: 200, isResizable: true },
    { key: "link", name: "Acción", fieldName: "url", minWidth: 90, isResizable: false },
];
const renderAttachmentColumn = (item: any, index?: number, column?: IColumn) =>
{
    if(!column)
    {
        return;
    }
    switch(column?.key)
    {
        case 'link':
            return (<a href={item.url} target="_blank" rel="noreferrer"><i className={'ironia-icon downloads'} /> Descargar</a>);
        default: return item[column.key];
    }
};
export const FundDocumentsCalloutEP = (props: FundDocumentsCalloutProps) =>
{
    return(
        <React.Fragment>
            {props.isVisible &&
                <Callout gapSpace={0}
                    target={`#btnDocuments_${props.fund?.isin}`}
                    onDismiss={props.onClose}
                    setInitialFocus
                >
                    <DefaultButton onClick={props.onClose} className="closeButton">
                        <i className='ironia-icon cross' />
                    </DefaultButton>
                    <h4>Documentos {props.fund?.name}</h4>
                    <DetailsList items={props.fund?.documents || []}
                        columns={documentsColumns}
                        selectionMode={SelectionMode.none}
                        onRenderItemColumn={renderAttachmentColumn}
                    />
                </Callout>
            }
        </React.Fragment>
    );
};