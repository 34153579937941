import { useCallback, useState, useEffect, useContext } from 'react';
import * as L from './layout.styles';
import { SpinnerSize, Spinner, Separator, SearchBox, DefaultButton } from '@fluentui/react';
import FundService from '../../services/FundService.tsx';
import Filters from './Filters';
import Table from '../tables/buscadorFondosTable';
import FeaturedManagers from '../FeaturedManagers';
import Auth from '../../Auth/Auth';
import { ThemeContext } from '@fluentui/react-theme-provider';
import groupFundsByFamily from '../../utils/groupFundsByFamily';
import { Link } from "react-router-dom";
import { ai } from '../../services/TelemetryService';
import { useHistory } from "react-router-dom";
import { translate } from "../../utils/i18n";

let lastPromise;

const Instruments = ({defaultFilters = {}, location, publicVersion, fromEF = false}) =>
{

    const theme = useContext(ThemeContext);
    const [filters, setFilters] = useState(
    {
        subcategory: [],
        manager: [],
        currency: [],
        available: undefined,
        income: undefined,
        switchable: undefined,
        indexed: undefined,
        class: undefined,
        ...defaultFilters,
        ...location?.state
    });
    let history = useHistory();
    //Para las redirecciones con término de búsqueda incluidas
    const searchTerm = history?.location?.state?.searchTerm ?? ""
    const [updateSearch, setUpdateSearch] = useState(false);
    const [page, setPage] = useState(0);
    const [recordsTotal, setRecordsTotal] = useState(0);
    const [familyRecordsTotal, setFamilyRecordsTotal] = useState(0);
    const [funds, setFunds] = useState([]);
    const [tableSearchTerm, setTableSearchTerm] = useState(searchTerm);
    const [loading, setLoading] = useState(false);
    const [fundGroups, setFundGroups] = useState([]);
    const [productsPerPage, setProductsPerPage] = useState(Auth.getQtyPage());
    const [isManagerQueryPresent, setIsManagerQueryPresent] = useState(false);
    const appInsights = ai.reactPlugin;

    useEffect(() =>
    {
        const params = new Proxy(new URLSearchParams(window.location.search),
        {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        if(params.manager !== null)
        {
            handleClick(params.manager);
            setIsManagerQueryPresent(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        setTableSearchTerm(history?.location?.state?.searchTerm ?? "");
        setUpdateSearch(c => !c);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, history?.location?.state]);

    const searchFunds = () =>
    {
        let {subcategory, manager, currency, available, income, switchable, indexed, classCategory} = filters;
        let subcategories = subcategory.map((sc) =>
        {
            return sc.morningStarCategoryId;
        });

        let promise = lastPromise = FundService.getFunds(page, productsPerPage, tableSearchTerm.toUpperCase(), subcategories, available, income, switchable, indexed, manager, currency, classCategory);

        promise.then((response) =>
        {
            if(promise !== lastPromise)
            {
                return;
            }
            setFunds(response.results);
            setFamilyRecordsTotal(response?.total_Family ?? 0)
            setRecordsTotal(response.total);
            setFundGroups(groupFundsByFamily(response.results));
            setLoading(false);
        })
        .catch(error =>
        {
            if(promise !== lastPromise)
            {
                return;
            }
        })
        .finally(() =>
        {
            if(promise !== lastPromise)
            {
                return;
            }
        });
    };

    const handleKey = (e) =>
    {
        if(e.key === 'Enter')
        {
            load();
        }
    };

    const load = () =>
    {
        setFamilyRecordsTotal(0);
        setRecordsTotal(0);
        setLoading(true);
        setFunds([]);
        setFundGroups([]);
        setTimeout(() => searchFunds());
    };

    useEffect(() =>
    {
        load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, productsPerPage, updateSearch]);

    const handleChange = useCallback((value, filterId) =>
    {
        setFilters(currentFilters => ({...currentFilters, [filterId]: value}));
    }, []);

    const handleClick = (managerNames) =>
    {
        if(publicVersion && isManagerQueryPresent)
        {
            return false;
        }

        const alreadySelected = filters.manager.some((name) => managerNames.includes(name));
        const filteredManagers = filters.manager.filter((name) => !managerNames.includes(name));

        if(alreadySelected)
        {
            setFilters(
            {
                ...filters,
                manager: [...filteredManagers],
            });
        }
        else
        {
            appInsights.trackEvent({ name: "QuickLink", properties: { managerName: translate(managerNames[0])}});

            setFilters(
            {
                ...filters,
                manager: [...filteredManagers, ...managerNames],
            });
        }
        setUpdateSearch(c => !c);
    };

    return(
        <L.Wrapper>
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md6">
                    <L.Title>Buscador de fondos</L.Title>
                </div>
                {publicVersion && isManagerQueryPresent &&
                (
                    <div className="ms-Grid-col text-right ms-sm12 ms-md6 link">
                        <Link to="/">
                            <DefaultButton className="button primary-button">Regístrate para operar</DefaultButton>
                        </Link>
                    </div>
                )}
            </div>
            <Separator />
            {/* FM = Featured Managers */}
            <L.FMContainer>
                <L.FMTitleContainer>
                    <L.FMTitle>GESTORAS DESTACADAS</L.FMTitle>
                </L.FMTitleContainer>
                <L.FMCardsContainer>
                    <FeaturedManagers
                        selectedManagers={filters.manager}
                        onClick={handleClick}
                        readOnly={publicVersion && isManagerQueryPresent}
                    />
                </L.FMCardsContainer>
            </L.FMContainer>
            {/* Featured Managers */}
            <L.FiltersWrapper>
                <Filters
                    onChange={handleChange}
                    selectedFilters={filters}
                    hiddenFilters={publicVersion && isManagerQueryPresent ? { manager: true } : {}}
                />
            </L.FiltersWrapper>
            <L.TableWrapper>
                <L.TableWidthManager>
                    <L.TableFilterContainer>
                        {/* TR = Table Results */}
                        <L.TRCountContainer>
                            <L.TRTitle>
                                Resultados de la búsqueda
                                <L.TRCount>{`(${familyRecordsTotal})`}</L.TRCount>
                            </L.TRTitle>
                        </L.TRCountContainer>
                        <L.InputSearchContainer>
                            <SearchBox onKeyPress={e => handleKey(e)}
                                placeholder="Buscar..."
                                value={tableSearchTerm}
                                onChange={event =>
                                {
                                    let text = event.target.value;
                                    setTableSearchTerm(text);
                                }}
                            />
                        </L.InputSearchContainer>
                        <L.EditColumnContainer>
                            {/*<DefaultButton text='Editar columnas' />*/}
                            <DefaultButton text="Buscar" onClick={load} />
                        </L.EditColumnContainer>
                    </L.TableFilterContainer>
                    {loading ?
                    (
                        <Spinner size={SpinnerSize.large} />
                    ) : (
                        <>
                            <L.TableContainer theme={theme}>
                                <Table theme={theme}
                                    publicVersion={publicVersion}
                                    groups={fundGroups}
                                    data={funds}
                                    columns={["Nombre", "Gestora", "Categoría", "Puntos IRONIA"]}
                                    fromEF={fromEF}
                                />
                            </L.TableContainer>
                            <L.PaginationContainer>
                                <L.ProductsPerPage>
                                    <span>Productos por página</span>
                                    <select onChange={event =>
                                        {
                                            Auth.setQtyPage(event.target.value);
                                            setProductsPerPage(event.target.value);
                                        }}
                                        defaultValue={Auth.getQtyPage()}
                                    >
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={30}>30</option>
                                        <option value={40}>40</option>
                                        <option value={50}>50</option>
                                    </select>
                                </L.ProductsPerPage>
                                <L.PageCount>
                                    <span>{`${page + 1}-${productsPerPage} de ${Math.ceil(recordsTotal / productsPerPage)}`}</span>
                                </L.PageCount>
                                <L.PageButtons>
                                    <button onClick={() =>
                                        {
                                            if(page > 0)
                                            {
                                                setPage(page - 1);
                                            }
                                        }}
                                        className={page === 0 ? "disabled" : ""}
                                    >
                                        <i className={"ironia-icon keyboard-arrow-left"} />
                                    </button>
                                    <button onClick={() =>
                                        {
                                            if(page < Math.ceil(recordsTotal / productsPerPage) - 1)
                                            {
                                                setPage(page + 1);
                                            }
                                        }}
                                        className={Math.ceil(recordsTotal / productsPerPage) === page + 1 ? "disabled" : ""}
                                    >
                                        <i className={"ironia-icon keyboard-arrow-right"} />
                                    </button>
                                </L.PageButtons>
                            </L.PaginationContainer>
                        </>
                    )}
                </L.TableWidthManager>
            </L.TableWrapper>
        </L.Wrapper>
    );
};

export default Instruments;