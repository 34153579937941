import { useEffect, useState } from 'react';
import { Spinner, SpinnerSize } from '@fluentui/react';
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css'; // Importa los estilos del visor
import '@react-pdf-viewer/default-layout/lib/styles/index.css'; // Estilos del plugin de layout

// Asumiendo que tienes este servicio para obtener el Blob
import MarketAttachments from '../services/MarketAttachmentService';

interface AttachmentProps {
    fileName: string;
    className: string;
}

export const MarketAttachment = (props: AttachmentProps) => {
    const [base64UrlImage, setBase64UrlImage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(true);

    // Crear una instancia del plugin de layout
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    useEffect(() => {
        // Obtener el Blob del PDF
        MarketAttachments.getMarketBlobAttachment(props.fileName)
            .then((response: any) => {
                const file = new Blob([response.data], { type: response.headers['content-type'] });
                setBase64UrlImage(URL.createObjectURL(file)); // Crear una URL del Blob
            })
            .finally(() => setIsLoading(false));
    }, [props.fileName]);

    return (
        <>
            {isLoading ? (
                <div className="spinner-container">
                    <Spinner size={SpinnerSize.large} className="button-spinner" />
                </div>
            ) : (
                props.fileName.endsWith(".pdf") ? (
                    <div style={{ position: 'relative', flex: 1, width: '100%', minHeight: '1300px' }}>
                        {/* Aquí se pasa la URL generada al componente PDF Viewer */}
                        <Viewer
                            fileUrl={base64UrlImage}
                            // plugins={[defaultLayoutPluginInstance]} // Pasando el plugin de layout
                        />
                    </div>
                ) : (
                    <img src={base64UrlImage} className={props.className} alt="Cargando..." />
                )
            )}
        </>
    );
};
