import { QuickLink } from '../../services/CompetitionsService';
import './buscadoresStyle.css'


const CardCompanyComponents = (props: QuickLink) => {
    return (
        <a
            href={props.url}
            target="_blank" 
            rel="noopener noreferrer"
            className="linkCard"
        >
            <div className="containerImgCard">
                <img
                    width={152}
                    height={88}
                    style={{ borderRadius: "15px" }}
                    src={props.imgBanner}
                    alt="Card Image"
                />
            </div>
        </a>
    );
};

export default CardCompanyComponents;