import { useEffect, useState } from "react";
import DocumentTitle from "react-document-title";
import CompetitionsService, {
    CompetitionType,
    CompetitionTypes,
} from "../../services/CompetitionsService";
import Moment from "moment";
import styles from "./index.module.sass";
import
{
    DefaultButton,
    Spinner,
    SpinnerSize,
} from "@fluentui/react";
import { currencyNeutreFormatter } from "../../utils/numberFormatter";
import { CompetitionInitialValues } from "../../services/CompetitionsService";
import { useForm } from "react-hook-form";
import { Toggle } from "@fluentui/react/lib/Toggle";
import { useHistory } from "react-router-dom";
import Auth from "../../Auth/Auth";
import PortfolioModelWalletService, {
    ModelSavingPlanSelector,
    ModelWallet,
    ModelWalletOptions,
    ModelWalletSelector,
} from "../../services/PortfolioModelWalletService";
import Select from "react-dropdown-select";
import ElementsService, {
    ElementAmountType,
    SavingsPlanElementsStatus,
} from "../../services/ElementsService";
import { GenericMessageModalWithoutButton } from "../../components/Modals/GenericModal";
import SavingsPlanService from "../../services/SavingsPlanService";
import { ITableSPlansActive } from "../../components/SavePlans/TableActiveSavingPlans";

const CompetitionNew = () =>
{
    const [errorD, setErrorD] = useState({ status: false, message: "" });
    const [loading, setLoading] = useState<boolean>(false);
    const [sending, setSending] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const [startDate, setStartDate] = useState<string>("");
    const [startDateInDate, setStartDateInDate] = useState<Date>();
    const [endDate, setEndDate] = useState<string>("");
    const [endDateInDate, setEndDateInDate] = useState<Date>();
    const [price, setPrice] = useState<number>();
    const [description, setDescription] = useState<string>("");
    const [selectedSavingPlan, setSelectedSavingPlan] = useState<number>();
    const [selectedModelPortfolio, setSelectedModelPortfolio] = useState<number>();
    const [selectedValueCompetition, setSelectedValueCompetition] =
        useState(false);
    const [isModal, setIsModal] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [haveError, setHaveError] = useState<boolean>(false);
    const [selectedValueType, setSelectedValueType] = useState(false);
    const [savingPlanModelPortfolios, setSavingPlanModelPortfolios] = useState<
    ModelWalletOptions[]
    >();
    const [elementsAmount, setElementsAmount] = useState<ElementAmountType>();
    const [starDateOneMonthMoreDate, setStarOneMonthMoreDate] = useState<Date>();
    const [savingsPlans, setSavingsPlans] = useState<ModelSavingPlanSelector[]>();
    const [chargedModelPortfolio, setChargedModelPortfolio] = useState<boolean>(true);

    let history = useHistory();
    const user = Auth.getUserProfile();

    const {
        handleSubmit,
    } = useForm();

    useEffect(() =>
    {
        SavingsPlanService.getSavingsPlansAndStatus(user.id).then((res: any) =>
        {
            var savingPlans = res.filter((e: any) => e.status == 4)

            let spSavingPlans = savingPlans.map((savingPlan: ITableSPlansActive) => ({
                value: savingPlan.id,
                label: savingPlan.planName
            }));

            setSavingsPlans(spSavingPlans);
        }).finally(() =>
        {
            setLoading(false);
        });
    }, []);

    useEffect(() =>
    {
        
        if(selectedSavingPlan != undefined){
            setChargedModelPortfolio(false);

            Promise.all([
                PortfolioModelWalletService.getModelWallets(selectedSavingPlan), 
                ElementsService.getElementsAmountBySavingsPlanIdCompetition(selectedSavingPlan)])
            .then(([modelPortfolio, elementsAmountAux]) => {
    
                let spModelPortfolios = modelPortfolio.map((portfolio: ModelWallet) => ({
                    value: portfolio.id,
                    label: portfolio.name, 
                }));

                setSavingPlanModelPortfolios(spModelPortfolios);
                setSelectedModelPortfolio(undefined);
    
                if(elementsAmountAux != ""){
                    setElementsAmount(elementsAmountAux);
                } else {
                    setElementsAmount(undefined);
                }
            
            } ).finally(() => {
                setChargedModelPortfolio(true);
            })
        }else {
            setSavingPlanModelPortfolios([]); 
          }
    }, [selectedSavingPlan]);

    if (errorD.status)
    {
        return (
            <DocumentTitle title="IronIA - Competiciones - Versión Preliminar">
                <div>Error: {errorD.message}</div>
            </DocumentTitle>
        );
    }

    const onSubmit = async (data: CompetitionInitialValues) =>
    {

        if (
            name != "" && description != "" && startDate != "" && endDate != "" && selectedModelPortfolio != undefined && selectedSavingPlan != undefined
            && ((price != undefined && price > 0 && elementsAmount != undefined && selectedValueCompetition == true && price < elementsAmount.elementWater)
                || (elementsAmount != undefined && selectedValueCompetition == false && price != undefined && price < elementsAmount.elementIA)))
        {

            setHaveError(false);

            const competitionInitialValues: CompetitionInitialValues = {
                name: name,
                startDate: startDate,
                endDate: endDate,
                description: description,
                type: selectedValueType ? 1 : 0,
                price: price,
                typeOfPrice: selectedValueCompetition ? 1 : 0,
                modelPortfolio: selectedModelPortfolio.toString(),
                allowOutdatedIncriptions: false,
                userId: user.id,
                savingsPlanId: selectedSavingPlan
            };

            setSending(true);

            CompetitionsService.createCompetition(competitionInitialValues)
                .then(
                    (response: CompetitionType) =>
                    {
                        setIsModal(true);
                        setShowModal(true);
                    },
                    (error: any) =>
                    {
                        setErrorD({ status: true, message: error.response.data });
                    }
                )
                .finally(() =>
                {
                    setSending(false);
                });
        } else
        {
            setHaveError(true);
        }
    };

    const handleToggleValueCompetitionChange = (
        event: React.MouseEvent<HTMLElement>,
        checked: boolean | undefined
    ) =>
    {
        setSelectedValueCompetition(checked ?? false);
    };

    const handleToggleValueTypeChange = (
        event: React.MouseEvent<HTMLElement>,
        checked: boolean | undefined
    ) =>
    {
        setSelectedValueType(checked ?? false);
    };

    const handleStartDateChange = (date: Date | null | undefined) =>
    {
        let dateFormat = Moment(date).format("DD/MM/YYYY");
        const startDateForDate = new Date(date!);

        setStartDateInDate(startDateForDate);

        setStartDate(dateFormat);

        let dateOneMonthMore = Moment(date).add(1, "month").startOf('day').format("DD/MM/YYYY");
        setStarOneMonthMoreDate(Moment(date).add(1, "month").startOf('day').toDate());
    };


    const handleEndDateChange = (date: Date | null | undefined) =>
    {
        const endDateForDate = new Date(date!);
        let dateFormat = Moment(date).format("DD/MM/YYYY");

        setEndDateInDate(endDateForDate!)
        setEndDate(dateFormat);
    };

    return (
        <DocumentTitle title="IronIA - Nueva Competición - Versión Preliminar">
            <section className={`${styles.index}`}>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12">
                        <h2>Crear Competición - Versión Preliminar</h2>
                    </div>
                </div>
                {loading && <Spinner size={SpinnerSize.large} />}
                {!loading && (
                    <div className="ms-Grid-row" style={{ display: "flex" }}>
                        <article className={`${styles.card} rounded background-background`}>
                            <form id="form" onSubmit={handleSubmit(onSubmit)} noValidate>
                                <div
                                    className="ms-Grid-row"
                                    style={{ display: "flex", alignItems: "center" }}
                                >
                                    <p className="ms-Grid-col ms-sm2 ms-md2 ms-lg2 ms-xl2">
                                        Nombre
                                    </p>
                                    <div
                                        className="ms-Grid-col ms-sm12 ms-md12 ms-lg10 ms-xl10"
                                        style={{ display: "flex", flexDirection: "column" }}
                                    >
                                        <input
                                            type="text"
                                            className={styles.inputstring}
                                            name="name"
                                            disabled={sending} // @ts-ignore
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                        {haveError && name == "" && (
                                            <p style={{ color: "red" }}>Debes rellenar el nombre</p>
                                        )}
                                    </div>
                                </div>
                                <div
                                    className="ms-Grid-row"
                                    style={{ display: "flex", alignItems: "center" }}
                                >
                                    <p className="ms-Grid-col ms-sm2 ms-md2 ms-lg2 ms-xl2">
                                        Comienzo
                                    </p>
                                    <div
                                        className="ms-Grid-col ms-sm4 ms-md4 ms-lg4 ms-xl4"
                                        style={{ display: "flex", flexDirection: "column" }}
                                    >
                                        <input
                                            type="date"
                                            className={styles.inputdate}
                                            name="description"
                                            disabled={sending} // @ts-ignore
                                            onChange={(e) => handleStartDateChange(e.target.value)}
                                        />
                                        {haveError && startDate == "" && (
                                            <p style={{ color: "red" }}>Debes rellenar el comienzo</p>
                                        )}
                                        {startDateInDate != undefined &&
                                            startDateInDate < Moment().toDate() && (
                                                <p style={{ color: "red" }}>
                                                    La fecha inicial no puede ser menor al día de hoy
                                                </p>
                                            )}
                                    </div>

                                    <p
                                        className="ms-Grid-col ms-sm2 ms-md2 ms-lg2 ms-xl2"
                                        style={{ textAlign: "center" }}
                                    >
                                        Final
                                    </p>
                                    <div
                                        className="ms-Grid-col ms-sm4 ms-md4 ms-lg4 ms-xl4"
                                        style={{ display: "flex", flexDirection: "column" }}
                                    >
                                        <input
                                            type="date"
                                            className={styles.inputdate}
                                            name="description"
                                            disabled={sending} // @ts-ignore
                                            onChange={(e) => handleEndDateChange(e.target.value)}
                                            placeholder=""
                                        />
                                        {haveError && endDate == "" && (
                                            <p style={{ color: "red" }}>Debes rellenar el final</p>
                                        )}
                                        {endDateInDate != undefined && starDateOneMonthMoreDate != undefined && starDateOneMonthMoreDate > endDateInDate && (
                                            <p style={{ color: "red" }}>
                                                La fecha final debe ser cómo mínimo 30 días mayor de la
                                                fecha inicial
                                            </p>
                                        )}
                                    </div>
                                </div>

                                <div
                                    className="ms-Grid-row"
                                    style={{ marginBottom: "60px !important" }}
                                >
                                    <p className="ms-Grid-col ms-sm2 ms-md2 ms-lg2 ms-xl2">
                                        Descripción
                                    </p>
                                    <div
                                        className="ms-Grid-col ms-sm10 ms-md10 ms-lg10 ms-xl10"
                                        style={{ display: "flex", flexDirection: "column" }}
                                    >
                                        <textarea
                                            className={styles.inputTextArea}
                                            name="description"
                                            disabled={sending} // @ts-ignore
                                            onChange={(e) => setDescription(e.target.value)}
                                        />
                                        {haveError && description == "" && (
                                            <p style={{ color: "red" }}>
                                                Debes rellenar la descripción
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div
                                    className="ms-Grid-row"
                                    style={{ display: "flex", alignItems: "center" }}
                                >
                                    <p className="ms-Grid-col ms-sm2 ms-md2 ms-lg2 ms-xl2">
                                        Inscripción
                                    </p>

                                    <div
                                        className="ms-Grid-col ms-sm5 ms-md5 ms-lg5 ms-xl5"
                                        style={{ display: "flex", flexDirection: "column" }}
                                    >
                                        <input
                                            type="number"
                                            className={styles.inputnumeric}
                                            name="price"
                                            disabled={sending}
                                            pattern="[0-9]+" // @ts-ignore
                                            onChange={(e) => setPrice(e.target.value)}
                                        />
                                        {/* {haveError &&
                                            (price == undefined ||
                                                typeof price !== "number" ||
                                                price % 1 !== 0) && (
                                                <p style={{ color: "red" }}>
                                                    Debes rellenar la inscripción con un número entero,
                                                    por ejemplo: 20
                                                </p>
                                            )} */}
                                        {(selectedModelPortfolio != undefined && elementsAmount == undefined) &&    <p style={{ color: "red" }}>
                                                   La cantidad de elements que tienes para este plan de cartera es 0
                                                    
                                                </p> }
                                        {((price != undefined && price > 0 && elementsAmount != undefined && selectedValueCompetition == true && price > elementsAmount.elementWater)
                                            || (elementsAmount != undefined && selectedValueCompetition == false && price != undefined && price > elementsAmount.elementIA)) && (
                                                <p style={{ color: "red" }}>
                                                    No puedes poner más elements de los que tienes. Tu total
                                                    de elements es de{" "}
                                                    {selectedValueCompetition
                                                        ? elementsAmount.elementWater
                                                        : elementsAmount.elementIA}{" "}
                                                </p>
                                            )}
                                        <p style={{ color: "grey", marginTop: "6px" }}>
                                            Para inscripción gratuita escribe 0
                                        </p>
                                    </div>

                                    <div style={{ display: "flex" }}>
                                        <p style={{ marginRight: "5px" }}>Elements IA</p>
                                        <Toggle
                                            checked={selectedValueCompetition}
                                            onChange={handleToggleValueCompetitionChange}
                                            style={{ backgroundColor: "#CC214F" }}
                                            styles={{
                                                thumb: {
                                                    backgroundColor: "white",
                                                },
                                            }}
                                        />
                                        <p style={{ marginLeft: "5px" }}>Elements Agua</p>
                                    </div>
                                </div>

                                <div className="ms-Grid-row" style={{ display: "flex" }}>
                                    <p style={{ marginRight: "5px" }}>Un Ganador</p>
                                    <Toggle
                                        checked={selectedValueType}
                                        onChange={handleToggleValueTypeChange}
                                        style={{ backgroundColor: "#CC214F" }}
                                        styles={{
                                            thumb: {
                                                backgroundColor: "white",
                                            },
                                        }}
                                    />
                                    <p style={{ marginLeft: "5px" }}>Tres ganadores</p>
                                </div>
                                <div className="ms-Grid-row">
                                    <p className="ms-Grid-col ms-sm2 ms-md2 ms-lg2 ms-xl2">
                                        Plan de Cartera
                                    </p>
                                    <div
                                        style={{ display: "flex", flexDirection: "column" }}
                                    >
                                        <div style={{ display: "flex" }}>
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-xl6">
                                                {/*//@ts-ignore */}
                                                <Select
                                                    options={ savingsPlans ?? []}
                                                    placeholder=""
                                                    className={`${styles.inputSelect}`}
                                                    onChange={(selectedOptions) => {
                                                        setSelectedSavingPlan(selectedOptions[0].value);
                                                        setSelectedModelPortfolio(undefined);
                                                        setSavingPlanModelPortfolios([]);
                                                    }}
                                                    searchable={false}
                                                />
                                            </div>
                                            {savingsPlans == undefined && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                                        </div>
                                        {haveError && selectedSavingPlan == undefined && (
                                            <p className="ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-xl6" style={{ color: "red" }}>
                                                Debes seleccionar un plan de cartera
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <p className="ms-Grid-col ms-sm2 ms-md2 ms-lg2 ms-xl2">
                                        Cartera Modelo
                                    </p>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <div style={{ display: "flex" }}>
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-xl6">
                                                {/*//@ts-ignore */}
                                                <Select
                                                    options={ savingPlanModelPortfolios ?? []}
                                                    placeholder=""
                                                    className={`${styles.inputSelect}`}
                                                    onChange={(selectedOptions) =>{
                                                        setSelectedModelPortfolio(selectedOptions[0]?.value || undefined) 
                                                    }}
                                                    values={
                                                        selectedModelPortfolio
                                                          ? [
                                                              // Busca el label asociado al value seleccionado
                                                              {
                                                                value: selectedModelPortfolio,
                                                                label: savingPlanModelPortfolios?.find(
                                                                  (portfolio) => portfolio.value === selectedModelPortfolio
                                                                )?.label || "", // Si no se encuentra, deja un string vacío
                                                              },
                                                            ]
                                                          : []
                                                      }
                                                    searchable={false}
                                                />
                                            </div>
                                            {!chargedModelPortfolio && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                                        </div>
                                       
                                        {haveError && selectedModelPortfolio == undefined && (
                                            <p className="ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-xl6" style={{ color: "red" }}>
                                                Debes seleccionar al menos una cartera modelo
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="ms-Grid-row" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <DefaultButton
                                        className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 ms-xl1 button tiny-primary-button"
                                        onClick={() => history.push("/competitions")}
                                        disabled={sending}
                                    >
                                        Cancelar
                                    </DefaultButton>

                                    <DefaultButton
                                        className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 ms-xl1 button tiny-primary-button"
                                        type="submit"
                                        disabled={sending}
                                    >
                                        {sending && (
                                            <Spinner
                                                size={SpinnerSize.small}
                                                className="button-spinner"
                                            />
                                        )}
                                        Crear
                                    </DefaultButton>
                                </div>
                            </form>
                        </article>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignContent: "center",
                                width: "50%",
                            }}
                        >
                            {(name != "" || startDate != "" || endDate != "" || description != "") && (
                                <article
                                    className={`${styles.littlecard} ms-Grid-col ms-sm12 ms-md6 ms-lg4 ms-xl3 rounded background-background`}
                                >
                                    <header>
                                        <div style={{ display: "flex" }}>
                                            <p
                                                style={{
                                                    marginBottom: "0px !important",
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                    wordBreak: "break-word",
                                                }}
                                            >
                                                {name}
                                            </p>
                                        </div>
                                        {startDate != "" && endDate != "" && (
                                            <p>
                                                Del {startDate} al {endDate}
                                            </p>
                                        )}
                                    </header>
                                    <main
                                        dangerouslySetInnerHTML={{
                                            __html: description ?? "",
                                        }}
                                        style={{ marginTop: "10px", wordBreak: "break-word" }}
                                    />
                                    <footer
                                        style={{
                                            backgroundColor: "white",
                                            position: "absolute",
                                            bottom: "4px",
                                            left: "0", // Asegura que el footer se posicione desde la izquierda
                                            width: "100%", // Opcional, ajusta el ancho según necesites
                                            fontSize: "14px",
                                            fontWeight: "500",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            padding: "0 10px", // Añade espacio horizontal entre los elementos
                                            boxSizing: "border-box",
                                            paddingLeft: "20px",
                                            paddingRight: "20px",
                                            paddingTop: "20px",
                                            paddingBottom: "20px",
                                        }}
                                    >
                                        <span className={styles.type}>Tipo: {CompetitionTypes[selectedValueType ? 1 : 0]}</span>
                                        <span className={styles.price}>
                                            {price == 0 ? (
                                                <>Inscripción gratuita</>
                                            ) : (
                                                <>
                                                    {price != undefined
                                                        ? currencyNeutreFormatter.format(price)
                                                        : 0}{" "}
                                                    Elements {selectedValueCompetition ? "Agua" : "IA"}
                                                </>
                                            )}
                                        </span>
                                    </footer>
                                </article>
                            )}
                            {(name != "" || startDate != "" || endDate != "" || description != "") && (
                                <p
                                    style={{
                                        alignSelf: "center",
                                        marginTop: "10px",
                                        width: "45%",
                                        fontSize: "17px",
                                        textAlign: "justify",
                                        color: "rgb(85, 103, 105)",
                                    }}
                                >
                                    Crear una competición significa participar en ella. Sí pulsa
                                    en crear se le restarán {price} de Elements{" "}
                                    {selectedValueCompetition ? "Agua" : "IA"}.
                                </p>
                            )}
                        </div>
                    </div>
                )}
                {isModal && (
                    <GenericMessageModalWithoutButton
                        title="Curso creado e inscripción registrada"
                        body={
                            <p>
                                Hemos registrado el curso en la base de datos. Recuerda que su
                                creación conllevaba participar en ella, por lo que has utilizado{" "}
                                {price} Elements {selectedValueCompetition ? "Agua" : "IA"}.
                            </p>
                        }
                        btnSubmitText="Aceptar"
                        showModal={showModal}
                        onSubmit={() =>
                        {
                            setShowModal(false);
                            history.push("/competitions");
                        }}
                        onClose={() =>
                        {
                            setShowModal(false);
                            history.push("/competitions");
                        }}
                    ></GenericMessageModalWithoutButton>
                )}
            </section>
        </DocumentTitle>
    );
};

export default CompetitionNew;
