import { SubTableInternal } from './SubTableInternal';
import styled from "styled-components";
import { useLocation } from 'react-router-dom';

const Table = ({groups, columns, publicVersion, theme}) =>
{

    const location = useLocation();

    return(
        <TableStyled theme={theme}>
            <thead>
                <tr>
                    {columns && columns.length > 0 && columns.map((column, columnIndex) =>
                    {
                        return(
                            <th style={column === 'Nombre' ? {paddingLeft: 20} : null} key={'columnIndex' + columnIndex}>
                                <div>{column}</div>
                            </th>
                        );
                    })}
                </tr>
            </thead>
            <tbody>
                {groups && groups.length > 0 && groups.map((row, index) =>
                {
                    return(
                        <SubTableInternal theme={theme}
                            publicVersion={publicVersion}
                            group={groups.find((group) => group.family === row.family)}
                            fund={row}
                            name={row.family}
                            manager={row.manager}
                            category={row.category}
                            key={'index' + index}
                            averageScore={row.averageScore}
                            fromEF={location.state != undefined && location.state.fromEF == true}
                        />
                    );
                })}
            </tbody>
        </TableStyled>
    );
};

export default Table;
export const TableStyled = styled.table`
    width: 100%;
    border-spacing: 0;
    overflow: hidden;
    thead
    {
        text-align: left;
        border-top-right-radius: 30px;
        div{}
        th
        {
            border-spacing: 0;
            padding: 0;
            div
            {
                //padding: 17px ;
                padding-top: 17px;
                padding-right: 17px;
                padding-bottom: 17px;
            }
        }
        th:first-of-type
        {
            div
            {
                padding-left: 10px;
                border-top-left-radius: 30px;
            }
        }
        th:last-of-type
        {
            div
            {
                padding-right: 10px;
                border-top-right-radius: 30px;
            }
        }
    }
    tbody
    {
        button.expanded
        {
            border: none;
            cursor: pointer;
            transition: 150ms;
            border-radius: 0;
            &:hover
            {
                border-bottom: 1px solid #4284e6;
                transition: 150ms;
            }
        }
        strong
        {
            color: ${props => props?.theme?.palette?.black}
        }
        tr
        {
            td:first-of-type
            {
                //padding-left: 1.75rem;
            }
            td
            {
                border-bottom: 2px solid #ecf1f3;
                div
                {
                    padding: 0px 0;
                }
                p
                {
                    color: ${props => props?.theme?.palette?.black}
                }
            }
            svg
            {
                margin-right: 3px;
            }
            .max-content
            {
                min-width: max-content;
            }
        }
        tr:last-of-type
        {
            td
            {
                border-bottom: none;
            }
        }
        tr.container-table td:first-of-type
        {
            padding: 0;
        }
    }
`;