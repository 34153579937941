import styled from "styled-components";

const CardWI = styled.div`
  background-color: white;
  padding: 1.75rem;
  border-radius: 0.75rem;
  box-shadow: 0 3.2px 7.2px 0 rgb(0 0 0 / 13%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%);
  height: 90px
`;

const CardTitleWI = styled.h4`
color: grey;
font-weight: 500;
margin-bottom: 6px
`;


type Props = {
    title?: string;
    body?: string | JSX.Element | JSX.Element[];
};

const CardWithInfo = ({ title, body }: Props) =>
{
    return (
        <CardWI>
            <CardTitleWI>{title?.toUpperCase()}</CardTitleWI>
            <p>{body}</p>
        </CardWI>
    );
};

export default CardWithInfo;