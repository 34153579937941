import { useEffect, useState } from "react";
import DocumentTitle from "react-document-title";
import CompetitionsService, {
    CompetitionType,
    CompetitionTypes,
    CompetitionsUsersTypeElement,
} from "../../services/CompetitionsService";
import { Spinner, SpinnerSize, DefaultButton } from "@fluentui/react";
import styles from "./index.module.sass";
import { currencyNeutreFormatter } from "../../utils/numberFormatter";
import { useHistory, useParams } from "react-router-dom";
import Auth from "../../Auth/Auth";
import PortfolioModelWalletService, {
    ModelSavingPlanSelector,
    ModelWallet,
    ModelWalletOptions,
    ModelWalletSelector,
} from "../../services/PortfolioModelWalletService";
import Select from "react-dropdown-select";
import ElementsService, {
    ElementAmountType,
} from "../../services/ElementsService";
import BackButton from "../../components/buttons/BackButton";
import { GenericMessageModalWithoutButton } from "../../components/Modals/GenericModal";
import SavingsPlanService from "../../services/SavingsPlanService";
import { ITableSPlansActive } from "../../components/SavePlans/TableActiveSavingPlans";
import { ErrorModal } from "../../components/Modals/ErrorModal";

const CompetitionParticipate = () =>
{
    const [error, setError] = useState({ status: false, message: "" });
    const [loading, setLoading] = useState<boolean>(true);
    const [sending, setSending] = useState<boolean>(false);

    const { competitionId } = useParams<any>();
    const [competition, setCompetition] = useState<CompetitionType>();
    const [elementsAmount, setElementsAmount] = useState<ElementAmountType>();
    const [isModal, setIsModal] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedSavingPlan, setSelectedSavingPlan] = useState<number>();
    const [selectedValueCompetition, setSelectedValueCompetition] =
        useState(false);
    const [haveError, setHaveError] = useState<boolean>(false);
    const [savingPlanModelPortfolios, setSavingPlanModelPortfolios] = useState<
        ModelWalletOptions[]
    >();
    const [savingsPlans, setSavingsPlans] = useState<ModelSavingPlanSelector[]>();
    const [chargedModelPortfolio, setChargedModelPortfolio] = useState<boolean>(true);
    const [selectedModelPortfolio, setSelectedModelPortfolio] = useState<number>();

    const history = useHistory();
    const plan = Auth.getActivePlan();
    const user = Auth.getUserProfile();

    useEffect(() =>
    {
        CompetitionsService.getCompetition(competitionId).then(
            (response: CompetitionType) =>
            {
                setCompetition(response);
            },
            (error: any) =>
            {
                setError({ status: true, message: error.response.data });
            }
        );
    }, []);

    useEffect(() =>
    {
        SavingsPlanService.getSavingsPlansAndStatus(user.id).then((res: any) =>
        {
            var savingPlans = res.filter((e: any) => e.status == 4)

            let spSavingPlans = savingPlans.map((savingPlan: ITableSPlansActive) => ({
                value: savingPlan.id,
                label: savingPlan.planName
            }));

            setSavingsPlans(spSavingPlans);
        }).finally(() =>
        {
            setLoading(false);
        });
    }, []);

    useEffect(() =>
    {

        if (selectedSavingPlan != undefined)
        {
            setChargedModelPortfolio(false);

            Promise.all([
                PortfolioModelWalletService.getModelWallets(selectedSavingPlan),
                ElementsService.getElementsAmountBySavingsPlanIdCompetition(selectedSavingPlan)])
                .then(([modelPortfolio, elementsAmountAux]) =>
                {

                    let spModelPortfolios = modelPortfolio.map((portfolio: ModelWallet) => ({
                        value: portfolio.id,
                        label: portfolio.name,
                    }));

                    setSavingPlanModelPortfolios(spModelPortfolios);
                    setSelectedModelPortfolio(undefined);

                    if (elementsAmountAux != "")
                    {
                        setElementsAmount(elementsAmountAux);
                    } else
                    {
                        setElementsAmount(undefined);
                    }

                }).finally(() =>
                {
                    setChargedModelPortfolio(true);
                })
        } else
        {
            setSavingPlanModelPortfolios([]);
        }
    }, [selectedSavingPlan]);

    const participate = () =>
    {
        setSending(true);

        const participationValues: CompetitionsUsersTypeElement = {
            competitionId: competitionId,
            savingsPlanId: plan.id,
            portfolioModelId: selectedModelPortfolio ?? 0,
            userId: user.id
        };

        CompetitionsService.inscribeInCompetition(participationValues)
            .then(
                (response: CompetitionType) =>
                {
                    setIsModal(true);
                    setShowModal(true);
                },
                (error: any) =>
                {
                    setError({ status: true, message: error.response.data });
                }
            )
            .finally(() =>
            {
                setSending(false);
            });
    };


    return (
        <DocumentTitle title="IronIA - Competiciones - Versión Preliminar">
            <section className={`${styles.index}`}>
                <BackButton />
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12">
                        <h2>Competiciones - Versión Preliminar</h2>
                    </div>
                </div>
                {loading && <Spinner size={SpinnerSize.large} />}
                {!loading && (
                    <div style={{ display: "flex" }}>
                        <article className={`${styles.card} rounded background-background`}>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12">
                                    <p
                                        className="descriptioncompetition"
                                        style={{ marginBottom: "10px" }}
                                    >
                                        Si pulsa en participar se le restarán{" "}
                                        {competition?.typeOfPrice == 0 ? (
                                            <>
                                                {currencyNeutreFormatter.format(competition.price)} de
                                                Elements IA.
                                            </>
                                        ) : (
                                            <>
                                                {currencyNeutreFormatter.format(
                                                    competition?.price ?? 0
                                                )}{" "}
                                                de Elements Agua.
                                            </>
                                        )}
                                    </p>
                                    <p
                                        className="descriptioncompetition"
                                        style={{ marginBottom: "10px" }}
                                    >
                                        Selecciona la cartera modelo con la que deseas participar en
                                        la competición. La rentabilidad de la cartera durante el
                                        periodo de competición determinara a los ganadores.
                                    </p>
                                    {competition?.type == 0 ? (
                                        <p
                                            className="descriptioncompetition"
                                            style={{ marginBottom: "10px" }}
                                        >
                                            Esta competición es a un solo ganador: la cartera con más
                                            rentabilidad se lleva todos los Elements de los
                                            participantes.
                                        </p>
                                    ) : (
                                        <p className="descriptioncompetition">
                                            Esta competición es a tres ganadores: el que más
                                            rentabilidad obtenga se lleva el 50% de los Elements de
                                            los participantes, el segundo el 30% y el tercero el 20%"{" "}
                                        </p>
                                    )}
                                    <div className="ms-Grid-row" style={{ marginBottom: "14px", marginTop: "26px" }}>
                                        <p className="ms-Grid-col ms-sm2 ms-md2 ms-lg2 ms-xl2">
                                            Plan de Cartera
                                        </p>
                                        <div
                                            style={{ display: "flex", flexDirection: "column" }}
                                        >
                                            <div style={{ display: "flex" }}>
                                                <div className="ms-Grid-col ms-sm10 ms-md10 ms-lg10 ms-xl10">
                                                    {/*//@ts-ignore */}
                                                    <Select
                                                        options={savingsPlans ?? []}
                                                        placeholder=""
                                                        className={`${styles.inputSelect}`}
                                                        onChange={(selectedOptions) =>
                                                        {
                                                            setSelectedSavingPlan(selectedOptions[0].value);
                                                            setSelectedModelPortfolio(undefined);
                                                            setSavingPlanModelPortfolios([]);
                                                        }}
                                                        searchable={false}
                                                    />
                                                </div>
                                                {savingsPlans == undefined && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                                            </div>
                                            {haveError && selectedSavingPlan == undefined && (
                                                <p className="ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-xl6" style={{ color: "red" }}>
                                                    Debes seleccionar un plan de cartera
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="ms-Grid-row">
                                        <p className="ms-Grid-col ms-sm2 ms-md2 ms-lg2 ms-xl2">
                                            Cartera Modelo
                                        </p>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <div style={{ display: "flex" }}>
                                                <div className="ms-Grid-col ms-sm10 ms-md10 ms-lg10 ms-xl10">
                                                    {/*//@ts-ignore */}
                                                    <Select
                                                        options={savingPlanModelPortfolios ?? []}
                                                        placeholder=""
                                                        className={`${styles.inputSelect}`}
                                                        onChange={(selectedOptions) =>
                                                        {
                                                            setSelectedModelPortfolio(selectedOptions[0]?.value || undefined)
                                                        }}
                                                        values={
                                                            selectedModelPortfolio
                                                                ? [
                                                                    // Busca el label asociado al value seleccionado
                                                                    {
                                                                        value: selectedModelPortfolio,
                                                                        label: savingPlanModelPortfolios?.find(
                                                                            (portfolio) => portfolio.value === selectedModelPortfolio
                                                                        )?.label || "", // Si no se encuentra, deja un string vacío
                                                                    },
                                                                ]
                                                                : []
                                                        }
                                                        searchable={false}
                                                    />
                                                </div>
                                                {!chargedModelPortfolio && <Spinner size={SpinnerSize.small} className="button-spinner" />}
                                            </div>

                                            {haveError && selectedModelPortfolio == undefined && (
                                                <p className="ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-xl6" style={{ color: "red" }}>
                                                    Debes seleccionar al menos una cartera modelo
                                                </p>
                                            )}
                                        </div>

                                        {competition != undefined &&
                                            elementsAmount != undefined &&
                                            competition.typeOfPrice == 0 &&
                                            competition?.price > elementsAmount?.elementIA ? (
                                            <p style={{ color: "red", marginTop: "30px" }}>
                                                No puede participar en la competición ya que actualmente
                                                posee {elementsAmount?.elementIA} Elements IA y el precio
                                                por participar es de {competition?.price} Elements IA{" "}
                                            </p>
                                        ) : (
                                            <></>
                                        )}
                                        {competition != undefined &&
                                            elementsAmount != undefined &&
                                            competition.typeOfPrice == 1 &&
                                            competition?.price > elementsAmount?.elementWater ? (
                                            <p style={{ color: "red", marginTop: "30px" }}>
                                                No puede participar en la competición ya que actualmente
                                                posee {elementsAmount?.elementWater} Elements Agua y el
                                                precio por participar es de {competition?.price} Elements
                                                Agua
                                            </p>
                                        ) : (
                                            <></>
                                        )}

                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "right",
                                                position: "relative",
                                                top: "190px",
                                            }}
                                        >
                                            <DefaultButton
                                                className="button tiny-primary-button"
                                                onClick={() => history.push("/competitions")}
                                                disabled={sending}
                                            >
                                                Cancelar
                                            </DefaultButton>

                                            <DefaultButton
                                                className="button tiny-primary-button"
                                                type="submit"
                                                disabled={
                                                    sending ||
                                                    (competition != undefined &&
                                                        elementsAmount != undefined &&
                                                        competition.typeOfPrice == 1 &&
                                                        competition?.price > elementsAmount?.elementWater) ||
                                                    (competition != undefined &&
                                                        elementsAmount != undefined &&
                                                        competition.typeOfPrice == 0 &&
                                                        competition?.price > elementsAmount?.elementIA)
                                                }
                                                onClick={participate}
                                            >
                                                {sending && (
                                                    <Spinner
                                                        size={SpinnerSize.small}
                                                        className="button-spinner"
                                                    />
                                                )}
                                                Participar
                                            </DefaultButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignContent: "center",
                                width: "50%",
                            }}
                        >
                            <article
                                className={`${styles.littlecard} ms-Grid-col ms-sm12 ms-md6 ms-lg4 ms-xl3 rounded background-background`}
                            >
                                <header>
                                    <div style={{ display: "flex" }}>
                                        <p
                                            style={{
                                                marginBottom: "0px !important",
                                                fontSize: "16px",
                                                fontWeight: "600",
                                                wordBreak: "break-word",
                                                alignSelf: "center",
                                            }}
                                        >
                                            {competition?.name}
                                        </p>
                                    </div>
                                    <p className="color-neutralTertiaryAlt">
                                        Del {competition?.startDate.split("/")[1]}/
                                        {competition?.startDate.split("/")[0]}/
                                        {competition?.startDate.split("/")[2]}
                                        {" "} al {" "}
                                        {competition?.endDate.split("/")[1]}/
                                        {competition?.endDate.split("/")[0]}/
                                        {competition?.endDate.split("/")[2]}
                                    </p>
                                </header>
                                <div
                                    style={{
                                        position: "relative",
                                        width: "390x",
                                        height: competition?.price == 0 ? "204px" : "190px",
                                    }}
                                >
                                    <main
                                        style={{ marginTop: "10px", wordBreak: "break-word" }}
                                        dangerouslySetInnerHTML={{
                                            __html: competition?.description ?? "",
                                        }}
                                    />
                                    <footer
                                        style={{
                                            backgroundColor: "white",
                                            width: "100%", // Opcional, ajusta el ancho según necesites
                                            fontSize: "14px",
                                            fontWeight: "500",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            padding: "0 10px", // Añade espacio horizontal entre los elementos
                                            boxSizing: "border-box",
                                            paddingLeft: "20px",
                                            paddingRight: "20px",

                                            position: "absolute",
                                            bottom: "40px",
                                            right: "7px",
                                        }}
                                    >
                                        <span className={styles.type}>
                                            Tipo: {CompetitionTypes[competition?.type ?? 0]}
                                        </span>
                                        <span className={styles.price}>
                                            {competition?.price === 0 ? (
                                                <>Inscripción gratuita</>
                                            ) : (
                                                <>
                                                    {competition != undefined
                                                        ? currencyNeutreFormatter.format(competition.price)
                                                        : 0}{" "}
                                                    Elements{" "}
                                                    {competition?.typeOfPrice == 1 ? "Agua" : "IA"}
                                                </>
                                            )}
                                        </span>
                                    </footer>
                                </div>
                            </article>
                        </div>
                        <ErrorModal
                            title="Ha ocurrido un error al procesar tu solicitud"
                            body={
                                <div>
                                    <p>{error.message}</p>
                                </div>
                            }
                            btnSubmitText="Aceptar"
                            showModal={error.status}
                            onSubmit={() =>
                            {
                                setError({ status: false, message: "" });
                            }}
                            onClose={() =>
                            {
                                setHaveError(false);
                            }}
                        />
                        {isModal && (
                            <GenericMessageModalWithoutButton
                                title="Inscripción registrada"
                                body={
                                    competition != undefined && competition?.price > 0 ? <p>Tu inscripción ha sido registrada. Recuerda que por
                                        participar, has utilizado {competition?.price} Elements{" "}
                                        {competition?.typeOfPrice == 1 ? "Agua" : "IA"}.</p> :
                                        <p> Tu inscripción ha sido registrada. Pulsa el boton aceptar para continuar.</p>
                                }
                                btnSubmitText="Aceptar"
                                showModal={showModal}
                                onSubmit={() =>
                                {
                                    setShowModal(false);
                                    history.push("/competitions");
                                }}
                                onClose={() =>
                                {
                                    setShowModal(false);
                                    history.push("/competitions");
                                }}
                            ></GenericMessageModalWithoutButton>
                        )}
                    </div>
                )}
            </section>
        </DocumentTitle>
    );
};

export default CompetitionParticipate;
