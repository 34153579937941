import * as React from 'react';
import { Stack, IStackStyles, IStackTokens } from '@fluentui/react';
import CardCompanyComponents from "./CardCompanyComponents";
import { ListOfQuickLinks } from '../../services/CompetitionsService';

// Styles definition
const stackStyles: IStackStyles = {
    root: {
        width: "100%",
    },
};
// Tokens definition
const sectionStackTokens: IStackTokens = { childrenGap: 10 };

const themedLargeStackTokens: IStackTokens = {
    childrenGap: 'l1',
    padding: 'l1',
};

export const CompetitionQuickLinks: React.FunctionComponent<ListOfQuickLinks> = (props) => {
    return (
        <Stack tokens={sectionStackTokens}>
            <Stack horizontal horizontalAlign="start">
                <Stack>
                    <Stack horizontal styles={stackStyles} tokens={themedLargeStackTokens}>
                        {props.quicklinks.sort(() => Math.random() - 0.5).map((e, index) => (
                            <CardCompanyComponents imgBanner={e.imgBanner} url={e.url} key={index} />
                        ))}
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};
