import { Service } from "./Service";
import axios from "axios";
import { proxyUrl } from "../config";
import { setupInterceptorsTo } from "./Interceptors";
import { ISignature } from "./SignaturesService";
setupInterceptorsTo(axios);

interface ISharedPortfolio
{
    id?: number,
    name: string,
    agreeToTermsAndConditions: boolean,
    sharedTimes: number,
    status: number,
    signatureId?: number,
    signature?: ISignature,
    fnmPortfolioId?: number,
    attachment?: string,
    createdAt?: string,
    updatedAt?: string
}
export type SharedPortfolioType = ISharedPortfolio
export interface ISharedPortfolioStats
{
    id?: number,
    createdAt?: string | Date,
    updatedAt?: string | Date,
    profitability?: number,
    profitabilityPosition?: number,
    popularityPosition?: number,
    sharedPortfolio: any,
    distribution: any,
    assetsAllocation: any,
    amount?: number
}
export type SharedPortfolioStatsType = ISharedPortfolioStats
export interface ISignBody
{
    signatureCode: string
}
export interface IGetSharedPortfoliosStatsParams
{
    filter?: string,
    offset?: number,
    resultsPerPage?: number
}
export interface IPagedResponseSummary
{
    resultsPerPage: number,
    offset: number,
    total: number
}
export interface IPagedResponse<ItemType>
{
    results: ItemType[],
    summary: IPagedResponseSummary
}
const baseURLOverride = undefined;//"http://localhost:7073/api";

class SharedPortfoliosService extends Service<ISharedPortfolioStats[]>
{
    public getSharedPortfolio = async (sharedPortfolioId: number): Promise<SharedPortfolioType> =>
    {
        let url: string = `${proxyUrl}/sharedPortfolios/${sharedPortfolioId}`
        const req = await axios.get<any>(url)
        return req.data
    }
    public validateName = async (name: string): Promise<boolean> =>
    {
        let url: string = `${proxyUrl}/sharedPortfolios/validateName?name=${encodeURIComponent(name)}`
        const req = await axios.get<any>(url)
        return req.data
    }
    public async createSharedPortfolio(sharedPortfolio: SharedPortfolioType)
    {
        let url: string = `${proxyUrl}/sharedPortfolios`
        const req = await axios.post(url, sharedPortfolio);
        return req.data;
    }
    public patchSharedPortfolio = async (sharedPortfolioId: number, sharedPortfolio: any): Promise<SharedPortfolioType> =>
    {
        let url: string = `${proxyUrl}/sharedPortfolios/${sharedPortfolioId}`;
        const req = await axios.patch(url, sharedPortfolio);
        return req.data;
    }
    public addSignatureToSharedPortfolio = async (sharedPortfolioId: number): Promise<SharedPortfolioType> =>
    {
        let url: string = `${proxyUrl}/sharedPortfolios/${sharedPortfolioId}/signature`;
        const req = await axios.post(url);
        return req.data;
    }
    public getSharedPortfoliosStats(params?: IGetSharedPortfoliosStatsParams | string)
    {
        if(typeof params === "string")
        { // Needed to use SWR.
            try
            {
                params = JSON.parse(params) as IGetSharedPortfoliosStatsParams;
            }
            catch { }
        }
        return Service.request<IPagedResponse<ISharedPortfolioStats>>(
        {
            method: "get",
            url: '/sharedPortfolioDailyStats',
            params
        }, baseURLOverride);
    }
    public getLastSharedPortfolioDailyStatBySharedPortfolioId = async (sharedPortfolioId: number): Promise<ISharedPortfolioStats> =>
    {
        let url: string = `${proxyUrl}/sharedPortfolioDailyStats/getLastBySharedPortfolioId/${sharedPortfolioId}`
        const req = await axios.get<any>(url)
        return req.data
    }
}

const service = new SharedPortfoliosService();
export default service;