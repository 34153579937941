import { useState, useEffect, useRef } from 'react';
import { SavingsPlanType, SavingsPlanTypes } from '../../services/SavingsPlanService';
import SubscriptionService, { SubscriptionType } from '../../services/SubscriptionService';
import DocumentTitle from 'react-document-title';
import Auth from "../../Auth/Auth";
import BackButton from "../buttons/BackButton";
import FirstStepsExperience from './FirstStepsExperience';
import UsersSubscription, { UsersSubscriptionType } from '../../services/UsersSubscriptionService';
import subscriptionService from '../../services/SubscriptionService';
import { getCdnUrl } from '../../utils/Cdn';

interface INewExperienceSavingsPlanProps
{
    publicVersion: boolean;
}

const NewExperienceSavingsPlan = (props: INewExperienceSavingsPlanProps) =>
{
    const [error, setError] = useState({ message: false });
    const user = Auth.getUserProfile();
    const hasValueSubscription = useRef<boolean>(false);
    const [subscription, setSubscription] = useState<SubscriptionType>();
    const [savingsPlanToCopy, setSavingsPlanToCopy] = useState<SavingsPlanType | undefined>();
    const [isLoaded, setIsLoaded] = useState(false);
    const [savingsPlan, setSavingsPlan] = useState<SavingsPlanType>(
    {
        userId: user.id ? user.id : null,
        name: "Plan Libertad",
        periodicContribution: 0,
        periodicContributionPeriod: 1,
        type: SavingsPlanTypes.Experience,
        status: 0
    });

    useEffect(() =>
    {
        if(subscription !== undefined)
        {
            return;
        }

        var subscriptionStoraged = Auth.getExperienceSubscription();

        if(subscriptionStoraged !== null && subscriptionStoraged !== "" && subscriptionStoraged !== undefined)
        {
            SubscriptionService.getSubscription(parseInt(subscriptionStoraged)).then((subscription: SubscriptionType) =>
            {
                setSubscription(subscription);
                setIsLoaded(true);
            },
            (error) =>
            {
                setError(error);
                setIsLoaded(true);
            });
        }
        else
        {
            UsersSubscription.getUserSubscriptionsByType(user.id, "experience").then((usersSubscriptions: UsersSubscriptionType[]) =>
            {
                if(usersSubscriptions !== undefined && usersSubscriptions.length > 0)
                {
                    hasValueSubscription.current = true;
                    setSubscription(usersSubscriptions[0].subscription);
                    if(usersSubscriptions[0].savingsPlans !== null && usersSubscriptions[0].savingsPlans !== undefined && usersSubscriptions[0].savingsPlans[0] !== undefined)
                    {
                        setSavingsPlanToCopy(usersSubscriptions[0].savingsPlans[0]);
                    }
                    setIsLoaded(true);
                }
                else
                {
                    subscriptionService.getPromotedSubscriptionGetByUserId(user.id).then(promotedSubscription =>
                    {
                        if(!promotedSubscription.id)
                        {
                            SubscriptionService.getRecommendedSubscription().then((recommendedSubscription: SubscriptionType) =>
                            {
                                UsersSubscription.getUserSubscriptionsByType(user.id, "promotion").then((usersSubscriptions: UsersSubscriptionType[]) =>
                                {
                                    if(usersSubscriptions !== undefined && usersSubscriptions.length > 0)
                                    {
                                        hasValueSubscription.current = true;
                                        setSubscription(recommendedSubscription);
                                        if(usersSubscriptions[0].savingsPlans !== null && usersSubscriptions[0].savingsPlans !== undefined && usersSubscriptions[0].savingsPlans[0] !== undefined)
                                        {
                                            setSavingsPlanToCopy(usersSubscriptions[0].savingsPlans[0]);
                                        }
                                        setIsLoaded(true);
                                    }
                                    else
                                    {
                                        Auth.setExperienceSubscription(recommendedSubscription.id);
                                        setSubscription(recommendedSubscription);
                                        setIsLoaded(true);
                                    }
                                },
                                error =>
                                {
                                    setError(error);
                                    setIsLoaded(true);
                                });
                            },
                            error =>
                            {
                                setError(error);
                                setIsLoaded(true);
                            });
                        }
                        else
                        {
                            setSubscription(promotedSubscription);
                            Auth.setExperienceSubscription(promotedSubscription.id);
                            setIsLoaded(true);
                        }
                    },
                    (error) =>
                    {
                        setError(error);
                        setIsLoaded(true);
                    });
                }
            },
            (error) =>
            {
                setError(error);
                setIsLoaded(true);
            });
        }
    }, [subscription, user.id]);

    const onChangePeriodicQuantity = (event: any) =>
    {
        setSavingsPlan(prevState =>
        ( // @ts-ignore
            { ...prevState, periodicContribution: parseFloat(event.target.value.replace(/[.€]/g, "")) }
        ))
    };

    const onChangeContributionPeriod = (event: any) =>
    {
        setSavingsPlan(prevState =>
        ( // @ts-ignore
            { ...prevState, periodicContributionPeriod: parseFloat(event.target.value) }
        ))
    };

    if(error.message)
    {
        return (<div>Error: {error.message}</div>)
    }

    return(
        <DocumentTitle title='IronIA - Nuevo plan de cartera'>
            <div>
                {!props.publicVersion && <BackButton />}
                <div className="ms-Grid-row savings-plans section-header">
                    <div className="ms-Grid-col ms-md3">
                        <div className="image-experience-container">
                            <img src={getCdnUrl('ihaveexperience.webp')} alt='Cliente con experiencia' />
                        </div>
                    </div>
                    <div className="ms-Grid-col ms-md9">
                        <h2 className="highlighted">Plan Libertad</h2>
                        <p className="description">Utiliza el buscador de fondos para seleccionar tus productos, añádelos al carrito y págalos desde tu banco habitual.</p>
                    </div>
                </div>
                <div className="ms-Grid-row savings-plans options">
                    <FirstStepsExperience
                        savingsPlan={savingsPlan}
                        savingsPlanIsCompleted={true}
                        isSavingsPlanReady={isLoaded}
                        user={user}
                        subscription={subscription}
                        savingsPlanToCopy={savingsPlanToCopy}
                        hasValueSubscription={hasValueSubscription.current}
                        publicVersion={props.publicVersion}
                        onChangePeriodicQuantity={onChangePeriodicQuantity}
                        onChangeContributionPeriod={onChangeContributionPeriod}
                    />
                </div>
            </div>
        </DocumentTitle>
    );
}

export default NewExperienceSavingsPlan;