import { IDropdownStyles } from "@fluentui/react";

export type ActivePlansToAgregate = {
    id: string,
    finametrixId: string,
    createdAt: Date,
    isSelected: boolean,
    finametrixContract: string,
    fnmContractId: number
};

export type MarketItem = {
    id: string;
    fileUrl: string;
    imageUrl: string;
};

export interface Subscription {
    id?: number,
    name: string,
    description: string,
    period: string,
    price?: number,
    limit?: number,
    freePeriods?: number,
    advised?: boolean,
    recommended?: boolean,
    actionText?: string,
    subscriptionType: string,
    priceIronia: number,
    priceProvider: number,
    riskLevel: number,
    providerId?: number,
    provider?: any,
    type?: string,
    limitPeriod?: number,
    isSelected?: boolean
}

export type AttemptResponse = {
    success: boolean,
    attempt: Attempt,
}

export type Attempt = {
    id: number,
    savingPlanId: number,
    subscriptionId: number,
    userId: number,
    extactedAnalysisChatGPT: string | null,
    marketReportFileUrl: string | null,
}

export type TrialAttemps = {
    success: boolean,
    usedAttempts: number,
}

export const dropdownStyles: Partial<IDropdownStyles> =
{
    caretDown:
    {
        color: "#cc214F",
        fontSize: "14px !important",
        fontWeight: 700
    },
    dropdownItems:
    {
        color: "#cc214F !important",
        borderColor: "transparent !important",
        fontWeight: 500,
        fontSize: "20px",
        marginTop: "5px"
    },
    title:
    {
        color: "#cc214F !important",
        borderColor: "transparent !important",
        fontWeight: 500,
        fontSize: "20px",
        marginTop: "5px"
    },
    dropdownItemSelected:
    {

    },
    dropdownOptionText: { color: "rgb(204, 33, 79) !important" }
};

export const initialReporting =
{
    hasError: false,
    positions:
    {
        positionsTo:
        {
            positions: [],
            aggregation: []
        }
    },
    performance:
    {
        serie: [{ dateAsString: "", accReturn: 0 }]
    }
}