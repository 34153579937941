import React from 'react'
import * as L from '../layout.styles';
import { DefaultButton, MessageBar, MessageBarType } from '@fluentui/react';
import { useHistory } from 'react-router';
import { CartTableEP, GetCartInfoEP } from './CartTableEP';
import { CartModesEP } from '../CartEducationalPlanScreen';
import { roundToDecimalPlaces } from '../../../utils/numberFormatter';
import { clearCartEP, removeLineEP, updateLineEP, updateModelPercentageLineEP } from '../../../store/educationalcart/actions';
import { connect, useDispatch } from "react-redux";


interface CartTablePropsEP
{
    cartLines: any[],
    cartModeStatus: CartModesEP,
    savingPlanType?: number,
    currentInstrumentIds?: string[] | undefined,
    onChangeCartModeStatus?: (newStatus: CartModesEP) => any
}

function mapStateToProps(state: any, props: any)
{
    return { cartLines: state.cart.cartLines };
}

export const TMPCartEP = (props: CartTablePropsEP) =>
{
    const history = useHistory();
    const dispatch = useDispatch();
    const { cartModeStatus } = props;
    const { items, total, modelPercentageTotal, hasInvalidAmounts, hasNonAvailableFunds, nonAvailableFunds } = GetCartInfoEP(props.cartLines, props.currentInstrumentIds);


    const removeCartLine = async (fund: any) =>
    {
        dispatch(removeLineEP(fund));
    };

    const updateCartLine = async (fundItem: any, amount?: string) =>
    {
        let fund = props.cartLines.filter(item => item.fund.isin === fundItem.isin)?.[0]?.fund;
        if (!fund)
        {
            return;
        }

        let n = 0;
        if (amount)
        {
            try
            {
                n = Number.parseFloat(amount);
            }
            catch (e) { }
        }
        if (typeof n === "undefined" || isNaN(n))
        {
            n = 0;
        }
        dispatch(updateLineEP({ fund, amount: n }));
    };

    const updateCartModelPercentageLine = async (fundItem: any, percentage?: string) =>
    {
        let fund = props.cartLines.filter(item => item.fund.isin === fundItem.isin)?.[0]?.fund;
        if (!fund)
        {
            return;
        }
        let n = 0;
        if (percentage)
        {
            try
            {
                n = Number.parseFloat(percentage);
            }
            catch (e) { }
        }
        if (typeof n === "undefined" || isNaN(n))
        {
            n = 0;
        }
        dispatch(updateModelPercentageLineEP({ fund, modelPercentage: n, amount: fund.amout }));
    };

    const removeAllLinesEP = async () =>
    {
        dispatch(clearCartEP());
    };


    return (
        <React.Fragment>
            {hasNonAvailableFunds &&
                (
                    <div className="cart-top-alerts">
                        <MessageBar messageBarType={MessageBarType.error} isMultiline={true} dismissButtonAriaLabel="Close">
                            Los siguientes fondos no están disponibles:
                            <p>
                                {nonAvailableFunds.map((fundName: string) =>
                                (
                                    <li>{fundName}</li>
                                ))}
                            </p>
                        </MessageBar>
                    </div>
                )}
            {(cartModeStatus === CartModesEP.CREATE_MODEL_PORTFOLIO) && roundToDecimalPlaces(modelPercentageTotal, 2) !== 100 &&
                (
                    <div className="cart-top-alerts">
                        <MessageBar messageBarType={MessageBarType.error} isMultiline={false} dismissButtonAriaLabel="Close">
                            <React.Fragment>
                                Total {roundToDecimalPlaces(modelPercentageTotal, 2)}% debe ser 100% para guardar o realizar aportaciones con la cartera modelo
                            </React.Fragment>
                        </MessageBar>
                    </div>
                )}
            {(items.length > 0 &&
                (
                    <React.Fragment>
                        <CartTableEP items={items} total={total} modelPercentageTotal={modelPercentageTotal}
                            removeCartLine={item => removeCartLine(item)}
                            updateCartLine={(item, newAmount) => updateCartLine(item, newAmount)}
                            updateCartModelPercentageLine={(item, newAmount) => updateCartModelPercentageLine(item, newAmount)}
                            cartModeStatus={cartModeStatus}
                            currentInstrumentIds={props.currentInstrumentIds}
                        />
                        {cartModeStatus === CartModesEP.CREATE_MODEL_PORTFOLIO &&
                            (
                                <L.OuterFooterArea>
                                    <DefaultButton onClick={() => history.push("/search", { fromEF: true })} className="button tiny-primary-button">
                                        Añadir más fondos
                                    </DefaultButton>
                                </L.OuterFooterArea>
                            )}
                    </React.Fragment>
                )) ||
                (
                    <React.Fragment>
                        <h3 className="text-center" style={{ cursor: "default" }}>
                            No has incluido nada en tu carrito. ¡Pásate por nuestro buscador de fondos!
                        </h3>
                        <L.CenterArea>
                            <DefaultButton onClick={() => history.push("/search", { fromEF: true })} disabled={hasInvalidAmounts} className="button tiny-secondary-button">
                                Explorar fondos
                            </DefaultButton>
                        </L.CenterArea>
                    </React.Fragment>
                )}
        </React.Fragment>
    )
}

export const CartEP = connect(mapStateToProps)(TMPCartEP);