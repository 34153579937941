import React from "react";
import { useEffect, useState } from "react";
import { Icon } from '@fluentui/react/lib/Icon';
import styled, { createGlobalStyle } from "styled-components";
import { Link } from "react-router-dom";

export const HelperStyle = createGlobalStyle`
    *,
    ::after,
    ::before
    {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    } 
    ul
    {
        list-style-type: none;
    }
    a
    {
        text-decoration: none;
    }
`;
export const ModalMainCont = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 430px;
    border-radius: 15px;
    background-color: #ffffff;
    z-index: 150;
    padding: 40px;
    & hr
    {
        margin: 25px 0 25px 0;
        border: solid 1px #E9EFF2;
    }
`;
export const ModalToExitCont = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
`;
export const ModalToExit = styled.div`
    position: relative;
    & i
    {
        &:hover
        {
            cursor: pointer;
        }
    }
`;
export const ModalContinue = styled.h3`
    font-size: 20px !important;
    font-weight: 800;
    color: #2A4143;
    margin-top: -20px !important;
    font-weight: 700 !important;
    word-spacing: -1px;
`;
export const ModalNeedsCont = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    &p
    {
        font-size: 15px;
        font-weight: 500;
        color: #6a7a7c;
        margin-bottom: 25px;
    }
`;
export const ModalBtnCont = styled.div`
    display: flex;
    float: right;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 20px;
`;
export const ModalLinkBtn = styled(Link)`
    display: block;
    width: fit-content;
`;
export const ModalAcceptBtn = styled.button`
    display: block;
    border: none;
    border-radius: 20px;
    width: 160px;
    height: 45px;
    background-color: #cc214f;
    font-family: inherit;
    color: #ffffff;
    font-weight: 500;
    &:hover
    {
        cursor: pointer;
    }
`;
interface ModalFocusProps
{
    height: string;
}

const ModalFocus = styled.div<ModalFocusProps>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${(props) => props.height};
    background-color: #2a414380;
    z-index: 100;
`;
const ModalExperienceSameIdentities = ({setModalShow}: {setModalShow: any}) =>
{
    const [height, setHeight] = useState('');

    useEffect(() =>
    {
        const toHeight = document?.querySelector('#root')?.scrollHeight + 'px';
        setHeight(toHeight);
    }, []);

    return (
        <React.Fragment>
            <HelperStyle />
            <ModalMainCont>
                <ModalToExitCont>
                    <ModalToExit>
                        <Icon iconName='ChromeClose' styles={closeBtn} onClick={() => setModalShow(false)} />
                    </ModalToExit>
                </ModalToExitCont>
                <ModalContinue>¡Proceso de firma completado!</ModalContinue>
                <hr />
                <ModalNeedsCont>
                    <p>Enhorabuena, has completado la suscripción.</p>
                </ModalNeedsCont>
                <ModalBtnCont>
                    <ModalLinkBtn to={'/savingsPlans'}>
                        <ModalAcceptBtn>Aceptar</ModalAcceptBtn>
                    </ModalLinkBtn>
                </ModalBtnCont>
            </ModalMainCont>
            <ModalFocus className='modal' height={height} />
        </React.Fragment>
    );
};

const closeBtn =
{
    root:
    {
        fontSize: "14px",
        fontWeight: "1000",
        position: "relative" as "relative",
        color: "#BFC6C7",
    },
}

export default ModalExperienceSameIdentities;