import { useEffect, useState } from 'react';
import { Attachment } from '../../Attachment';
import * as S from '../../ConvenienceTest/ConvenienceTest.style';
import { DefaultButton } from '@fluentui/react';
import DocumentIntelligenceService from '../../../services/DocumentIntelligenceService';
import { DNIPlaceholder } from './DNIPlaceHolder';
import UserIdentitiesService, { UserIdentityType } from '../../../services/UserIdentitiesService';

interface DocumentUploadProps {
    onUpload: (result: DocumentIntelligenceResult) => void;
    lockMainForm: (lock: boolean) => void;
    idFront?: string;
    idBack?: string;
    userId: number;
    userIdentity: UserIdentityType;
}

export interface DocumentIntelligenceResult {
    firstName: string;
    lastName: string;
    secondLastName: string;
    documentNumber: string;
    address: string;
    dateOfExpiration: string;
    placeOfBirth: string;
    dateOfBirth: string;
    documentType: string;
    countryRegion: string;
    fileNames: string[];
};

export const DocumentUpload: React.FC<DocumentUploadProps> = ({ onUpload, lockMainForm, idFront, idBack, userId, userIdentity}) => {
    const [documentoAnverso, setDocumentoAnverso] = useState<File | null>(null);
    const [documentoReverso, setDocumentoReverso] = useState<File | null>(null);
    const [documentImageFilenames, setDocumentImageFilenames] = useState<string[]>([idFront || '', idBack || '']);
    const [fileFormLocked, setFileFormLocked] = useState<boolean>(false);

    const [documentAnverseError, setDocumentAnverseError] = useState<string>('')
    const [documentReverseError, setDocumentReverseError] = useState<string>('')

    useEffect(() => {
        if (idFront && idBack)
            setShowDniImage(true);
    }, [idFront, idBack]);

    // spinner dni images
    const [loadingDniImages, setLoadingDniImages] = useState<boolean>(false);
    const [showDniImage, setShowDniImage] = useState<boolean>(false);

    const handleDocument = (event: React.ChangeEvent<HTMLInputElement>) => {
        switch (event.target.id) {
            case 'idFront':
                setDocumentoAnverso(event.target.files?.[0] || null);
                setDocumentAnverseError('');
                break;
            case 'idBack':
                setDocumentoReverso(event.target.files?.[0] || null);
                setDocumentReverseError('')
                break;
            default:
                break;

        }
    };

    const handleDocumentSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        // reset de errores
        setDocumentAnverseError('');
        setDocumentReverseError('');

        // INFO: bloqueo de campos mientras se procesa el documento
        setFileFormLocked(true);
        lockMainForm(true);

        if (!documentoAnverso) {
            setFileFormLocked(false);
            setDocumentAnverseError('Faltan documentos por subir');
            return;
        }

        if (!documentoReverso) {
            setFileFormLocked(false);
            setDocumentReverseError('Faltan documentos por subir');
            return;
        }

        const documentData = {
            documentoAnverso,
            documentoReverso,
        };

        setLoadingDniImages(true);

        try {
            const response = await DocumentIntelligenceService.sendDocumentToVerify(userId, documentData);
            if(response && response.fileNames && response.fileNames.length > 0) {
                if(response.fileNames.length === 2){
                    userIdentity.idFront = response.fileNames[0];
                    userIdentity.idBack = response.fileNames[1];

                    const updateUserIdentityResponse = await UserIdentitiesService.updateUserIdentity(userIdentity);
                    if(updateUserIdentityResponse.idFront !== null && updateUserIdentityResponse.idBack !== null){
                        setDocumentImageFilenames(response.fileNames);
                        setShowDniImage(true);
                        onUpload(response as DocumentIntelligenceResult);
    
                        setLoadingDniImages(false);
                        lockMainForm(false);
    
                        setFileFormLocked(false);
                        setLoadingDniImages(false);
                    }else{
                        setDocumentAnverseError("Ha ocurrido un error en la subida de los documentos. Inténtelo de nuevo.");
                        setShowDniImage(false);
                        setLoadingDniImages(false);
                        setFileFormLocked(false);
                        setLoadingDniImages(false);
                        lockMainForm(true);
                    }
                }else{
                    setDocumentAnverseError("Ha ocurrido un error en la subida de los documentos. Inténtelo de nuevo.");
                    setShowDniImage(false);
                    setLoadingDniImages(false);
                    lockMainForm(true);
                    setFileFormLocked(false);
                    setLoadingDniImages(false);
                }
            }else{
                if(typeof response === "string"){
                    response.includes('anverso') ? setDocumentAnverseError(response) : setDocumentReverseError(response);
                }else {
                    setDocumentAnverseError('No se recibieron nombres de archivo. Inténtelo de nuevo.');
                }
                setShowDniImage(false);
                setLoadingDniImages(false);
                setFileFormLocked(false);
                lockMainForm(true);
            }
        } catch (error: any) {
            console.error('Error al procesar el documento:', error.message || error);
            if (error.message.includes('anverso')) {
                setDocumentAnverseError(error.message || error);
            }else if (error.message.includes('reverso')) {
                setDocumentReverseError(error.message || error);
            }else{
                setDocumentAnverseError("Ha ocurrido un error en la subida de los documentos. Inténtelo de nuevo.");
            }
        } 
    };

    return (
        <>
            <S.FieldSet>
                <S.FieldRBCont>
                    <S.Label>DNI / NIE parte delantera *</S.Label>
                    <S.FieldFileCont>
                        <S.FileInput
                            id="idFront"
                            name="idFront"
                            type="file"
                            disabled={fileFormLocked}
                            accept=".png, .jpg, .jpeg, .pdf"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleDocument(event)}
                        />
                    </S.FieldFileCont>
                    <p style={{ fontSize: '11px', margin: '4px 0px' }}>
                        <span>* Se admite los siguientes formatos de imagen: .png, .jpg, .jpeg y .pdf</span>
                    </p>
                    <p style={{ fontSize: '13px', fontWeight: '600', color: 'red' }}>{documentAnverseError}</p>
                    <S.DocumentIntelligenceWrapper>
                        {loadingDniImages ? (
                            <DNIPlaceholder addSpinner={true} />
                        ) : showDniImage ? (
                            <S.DocumentAttachmentWrapper >
                                <Attachment
                                    fileName={documentImageFilenames && documentImageFilenames[0] ? documentImageFilenames[0] : ''}
                                    className="document-preview"
                                />
                            </S.DocumentAttachmentWrapper >
                        ) : <DNIPlaceholder addSpinner={false} />}
                    </S.DocumentIntelligenceWrapper>
                </S.FieldRBCont>
                <S.FieldRBCont>
                    <S.Label>DNI / NIE parte trasera *</S.Label>
                    <S.FieldFileCont>
                        <S.FileInput
                            id="idBack"
                            name="idBack"
                            type="file"
                            disabled={fileFormLocked}
                            accept=".png, .jpg, .pdf, .jpeg"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleDocument(event)}
                        />
                    </S.FieldFileCont>
                    <p style={{ fontSize: '11px', margin: '4px 0px' }}>
                        <span>* Se admiten los siguientes formatos de imagen: .png, .jpg, .jpeg y .pdf</span>
                    </p>
                    <p style={{ fontSize: '13px', fontWeight: '600', color: 'red' }}>{documentReverseError}</p>
                    <S.DocumentIntelligenceWrapper>
                        {loadingDniImages ? (
                            <DNIPlaceholder addSpinner={true} />
                        ) : showDniImage ? (
                            <S.DocumentAttachmentWrapper >
                                <Attachment
                                    fileName={documentImageFilenames && documentImageFilenames[1] ? documentImageFilenames[1] : ''}
                                    className="document-preview"
                                />
                            </S.DocumentAttachmentWrapper >
                        ) : <DNIPlaceholder addSpinner={false} />}
                    </S.DocumentIntelligenceWrapper>
                </S.FieldRBCont>

                <DefaultButton
                    className="button tiny-primary-button document-send-button"
                    disabled={fileFormLocked}
                    onClick={handleDocumentSubmit}
                >
                    Procesar
                </DefaultButton>

            </S.FieldSet>
        </>
    );
};