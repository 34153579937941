import React from "react";
import DocumentTitle from 'react-document-title';
import styled from "styled-components";
import scrollToTop from "../../utils/scrollToTop";
import { useHistory, useLocation } from "react-router";
import { useBoolean } from "@fluentui/react-hooks";
import { Separator } from 'office-ui-fabric-react/lib/Separator';
import { Spinner, SpinnerSize } from "@fluentui/react";
import { SignatureComponent } from "../Signature/SignatureComponent";
import { ISignature } from "../../services/SignaturesService";
import ModalExperienceSameIdentities from "./ModalExperienceSameIdentities";
import ModalKOExperienceSameIdentities from "./ModalKOExperienceSameIdentities";
import Auth from "../../Auth/Auth";
import moment from "moment";
import savingsPlans from "../../services/SavingsPlanService";
import contract, { SyncResult } from "../../services/FnmContractService";
import ProcessingModalComponent from "../Modals/ProcessingModalComponent";
import ModalAuthorizedExperienceSameIdentities from "./ModalAuthorizedExperienceSameIdentities";
import { DefaultButton, PrimaryButton } from "@fluentui/react";

export const TextBold = styled.p`
    margin-bottom: 0;
    font-weight: bold;
`;
export const Text = styled.p`
    margin-bottom: 0;
`;
export const ContainerSignature = styled.div`
    grid-area: main;
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
`;
export const MainContainerSignature = styled.div`
    padding: 40px 55px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
`;

export const InfoContainer = styled.div`
    padding: 1rem;
    max-width: 1600px;
    margin: 0 auto;
`;

export const InfoSection = styled.div`
    margin: 1rem 0;
    padding: 1rem;
    background-color: #f8f8f8;
    border-radius: 4px;
    cursor: default;
`;

export const InfoTitle = styled.h4`
    color: #323130;
    margin-bottom: 0.5rem;
    font-size: 16px;
    cursor: default;
`;

export const InfoGroup = styled.div`
    margin: 1rem 0;
    cursor: default;
`;

export const InfoLabel = styled.span`
    font-weight: 600;
    color: #323130;
    margin-right: 0.5rem;
    cursor: default;
`;

export const InfoValue = styled.span`
    color: #605e5c;
    cursor: default;
`;

const ExperienceSavingsPlanSameIdentities = (props: any) =>
{
    const { state } = useLocation<any>();
    const history = useHistory();
    var subscription = state?.subscription;
    var savingsPlanToCopy = state?.savingsPlanToCopy;
    var identities = state?.identities;
    const user = Auth.getUserProfile();
    const [isModalOpen, {setTrue: showModal, setFalse: hideModal}] = useBoolean(false);
    const [modalOkShow, setModalOkShow] = React.useState(false);
    const [modalAuthorizedItems, setModalAuthorizedItems] = React.useState(false);
    const [modalKoShow, setModalKoShow] = React.useState(false);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [signatures, setSignatures] = React.useState<ISignature[]>([]);
    const [hasAuthorized, setHasAuthorized] = React.useState<boolean>(false);
    const [authorizedItems, setAuthorizedItems] = React.useState<ISignature[]>([]);
    const [showInitialScreen, setShowInitialScreen] = React.useState(true);

    React.useEffect(() =>
    {
        scrollToTop();
    }, []);
    React.useEffect(() =>
    {
        if(showInitialScreen === false && signatures.length === 0)
        {
            savingsPlans.addSavingPlanSameIdentities(savingsPlanToCopy.id).then((response: any) =>
            {
                if(response !== null && response !== undefined && response.length > 0)
                {
                    setHasAuthorized(response.some((item: any) => item.savingsPlansUserIdentity.Relation === 5));

                    if(response.some((item: any) => item.savingsPlansUserIdentity.Relation === 5))
                    {
                        setAuthorizedItems(response.filter((item: any) => item.savingsPlansUserIdentity.Relation === 5));
                    }
                    
                    localStorage.setItem("MultiPlanSavingPlanId", String(response[0].savingsPlansUserIdentity.savingsPlanId));
                    setSignatures(response);
                    setIsLoaded(true);
                }
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signatures, user.id, subscription.id, showInitialScreen]);
    const processExpirationDate = (signs: ISignature[]) =>
    {
        if(signs !== undefined && signs.length > 0)
        {
            // eslint-disable-next-line array-callback-return
            signs.map((signature: ISignature) =>
            {
                if(signature && typeof signature.expiresAt === "string")
                {
                    signature.expiresAt = moment(signature.expiresAt).toDate();
                }
            });
        }
    }
    const handleSubmit = () =>
    {
        if(signatures.length > 0 || !modalOkShow)
        {
            scrollToTop();
            showModal();
            
            var savingPlanIdString = localStorage.getItem("MultiPlanSavingPlanId");

            if(savingPlanIdString !== undefined && savingPlanIdString !== null)
            {
                var savingPlanId: number = parseInt(savingPlanIdString);

                contract.syncSavingPlan(savingPlanId).then((response: SyncResult) =>
                {
                    if(response.result === true)
                    {
                        hideModal();
                        if(hasAuthorized)
                        {
                            setModalAuthorizedItems(true);
                            localStorage.removeItem("MultiPlanSavingPlanId");
                        }
                        else
                        {
                            setModalOkShow(true);
                            localStorage.removeItem("MultiPlanSavingPlanId");
                        }
                    }
                    else
                    {
                        hideModal();
                        setModalKoShow(true);
                        localStorage.removeItem("MultiPlanSavingPlanId");
                    }
                });
            }
            else
            {
                hideModal();
                setModalKoShow(true);
            }
        }
    };

    return(
        <React.Fragment>
            <DocumentTitle title="IronIA - Suscripción Mismos Intervinientes">
                <React.Fragment>
                    <h2 style={{ display: "inline-block", margin: 0 }}>Nueva Suscripción - Mismos Intervinientes</h2>
                    <Separator />
                    {showInitialScreen ?
                    (
                        <InfoContainer>
                            <div className="ms-Grid-row">
                                <h3 style={{cursor: 'default'}}>
                                    Estamos utilizando tu suscripción a {subscription.name} para este plan. No tienes que realizar ningún pago adicional.
                                </h3>
                                <h3 style={{fontSize: '17px', marginBottom: '1.5rem', cursor: 'default'}}>
                                    Los datos del Plan de Ahorro que vas a replicar para crear el nuevo son los siguientes:
                                </h3>
                                <InfoSection>
                                    {identities.some((p: any) => p.relation === 1) === true &&
                                    (
                                        <InfoGroup>
                                            <InfoLabel>Titular:</InfoLabel>
                                            <InfoValue>
                                                {identities.filter((p: any) => p.relation === 1)
                                                    .map((p: any) => `${p.userIdentity.firstName} 
                                                    ${p.userIdentity.lastName}`).join(', ')
                                                }
                                            </InfoValue>
                                        </InfoGroup>
                                    )}
                                    {identities.some((p: any) => p.relation === 11) === true &&
                                    (
                                        <InfoGroup>
                                            <InfoLabel>Cotitulares:</InfoLabel>
                                            <InfoValue>
                                                {identities.filter((p: any) => p.relation === 11)
                                                    .map((p: any) => `${p.userIdentity.firstName} 
                                                    ${p.userIdentity.lastName}`)
                                                    .join(', ')
                                                }
                                            </InfoValue>
                                        </InfoGroup>
                                    )}
                                    {identities.some((p: any) => p.relation === 4) === true &&
                                    (
                                        <InfoGroup>
                                            <InfoLabel>Representantes Legales:</InfoLabel>
                                            <InfoValue>
                                                {identities.filter((p: any) => p.relation === 4)
                                                    .map((p: any) => `${p.userIdentity.firstName} 
                                                    ${p.userIdentity.lastName}`)
                                                    .join(', ')
                                                }
                                            </InfoValue>
                                        </InfoGroup>
                                    )}
                                    {identities.some((p: any) => p.relation === 5) === true &&
                                    (
                                        <InfoGroup>
                                            <InfoLabel>Autorizados:</InfoLabel>
                                            <InfoValue>
                                                {identities.filter((p: any) => p.relation === 5)
                                                    .map((p: any) => `${p.userIdentity.firstName} 
                                                    ${p.userIdentity.lastName}`)
                                                    .join(', ')
                                                }
                                            </InfoValue>
                                        </InfoGroup>
                                    )}
                                    <InfoGroup>
                                        <InfoLabel>Nombre del Plan de Ahorro:</InfoLabel>
                                        <InfoValue>{savingsPlanToCopy.name}</InfoValue>
                                    </InfoGroup>
                                </InfoSection>
                            </div>
                            <ButtonContainer>
                                <DefaultButton text="Cancelar" onClick={() => history.push('/savingsPlans/iHaveExperience')} />
                                <PrimaryButton text="Continuar" onClick={() => setShowInitialScreen(false)} />
                            </ButtonContainer>
                        </InfoContainer>
                    ) : 
                    (
                        <React.Fragment>
                            <div className="ms-Grid-row">
                                <h3>Estamos utilizando tu a {subscription.name} para este plan.</h3>
                            </div>
                            <ContainerSignature>
                                <MainContainerSignature>
                                    {!isLoaded &&
                                    (
                                        <Spinner size={SpinnerSize.large} />)
                                    }
                                    {isLoaded &&
                                    (
                                        <React.Fragment>
                                            <SignatureComponent signatures={signatures}
                                                alreadySignedText=" "
                                                submitButtonText="Firmar contrato"
                                                cancelButtonText="Volver"
                                                headerTextArea={"Introduce el código que te hemos enviado al teléfono XXXXXX" + signatures[0].phone?.slice(signatures[0].phone.length - 3) + " por SMS para firmar el contrato."}
                                                backButtonText="Volver"
                                                onBackButtonClicked={() => history.push('/savingsPlans/iHaveExperience')}
                                                onCancelButtonClicked={() => history.push('/savingsPlans/iHaveExperience')}
                                                requestNewCodeButtonText="Recibir nuevo código"
                                                onNewCodeSended={(signatures: any) =>
                                                {
                                                    processExpirationDate(signatures);
                                                    setSignatures(signatures);
                                                }}
                                                onSignatureChanged={(signatures: any) =>
                                                {
                                                    processExpirationDate(signatures);
                                                    setSignatures(signatures);
                                                }}
                                                onSigned={handleSubmit}
                                            />
                                            <ProcessingModalComponent
                                                isModalOpen={isModalOpen}
                                                hideModal={hideModal}
                                            />
                                        </React.Fragment>
                                    )}
                                </MainContainerSignature>
                            </ContainerSignature>
                            {modalOkShow && <ModalExperienceSameIdentities setModalShow={setModalOkShow} />}
                            {modalAuthorizedItems && <ModalAuthorizedExperienceSameIdentities setModalShow={setModalAuthorizedItems} savingPlanId={2} authorizedItems={authorizedItems.length} />}
                            {modalKoShow && <ModalKOExperienceSameIdentities setModalShow={setModalKoShow} />}
                        </React.Fragment>
                    )}
                </React.Fragment>
            </DocumentTitle>
        </React.Fragment>
    );
}

export default ExperienceSavingsPlanSameIdentities;