import styled from 'styled-components';

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
  gap: 20px;

  span {
    color: #95a0a1;
    border-radius: 16px;
    line-height: 28px;
  }

  > * {
    vertical-align: middle;
  }
`;

export const ResultsPerPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  height: 100%;

  select {
    min-width: 70px;
    border: none;
    border-bottom: 1px solid black;
    padding: 5px 0;
  }
`;

export const PageCount = styled.div`
  display: flex;
`;

export const PageButtons = styled.div`
  button {
    cursor: pointer;
    border: 0;
    color: #CC214F;

    &.disabled {
      cursor: default;
      color: #F1BAC9;
    }
  }
`;

export const CompetitionPatronitedPatrocin = styled.h4`
  font-size: 12px;
  font-weight: 500;
  margin: 5px 0 0 0px;
  color: #95a0a1;
`;