import { Table } from "../Table";
import { ActiveIcon } from "../../icons/IconsTable";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { TooltipHost } from "@fluentui/react";
import { InfoIcon } from "@fluentui/react-icons-mdl2";
import { CopyIcon } from '@fluentui/react-icons-mdl2';
import { useBoolean, useId } from "@fluentui/react-hooks";
import { Callout, DefaultButton, DelayedRender, Text } from "@fluentui/react";
import styles from './index.module.sass';
import { decimalFormatter, longPercentFormatter } from "../../../utils/numberFormatter";

interface IChildrenTableProps
{
    children: any[],
    onRowClicked: () => any;
    publicVersion?: boolean;
    fromEF: boolean;
}



export const ChildrenTable = (props: IChildrenTableProps) =>
{

    const [isCalloutVisible, { setTrue: showCallout, setFalse: hideCallout }] = useBoolean(false);
    const [clipboardIsin, setClipboardIsin] = useState<string>("");
    const buttonId = useId('callout-button');

    React.useEffect(() =>
    {
        const timer = setTimeout(() =>
        {
            hideCallout();
        }, 1000);
        return () => clearTimeout(timer);
    }, [hideCallout, isCalloutVisible]);

    return(
		<Table.TableStyled>
			<thead>
				<tr>
					<th>
						<div style={{marginLeft: "5px"}}>Nombre/ISIN</div>
					</th>
					<th>
						<div>Divisa</div>
					</th>
					<th>
						<div>Gastos Corrientes (OGC)</div>
					</th>
					<th>
						<div>Retrocesión</div>
					</th>
					<th>
						<div>Inversión Inicial Mínima</div>
					</th>
					<th>
						<div>Dividendos</div>
					</th>
					<th>
						<TooltipHost className="ironia-tooltip" content="Esta columna refleja la aplicación del mínimo de manera genérica para la clase del fondo. Puede que en una orden concreta sí se aplique.">
							<div>
								Aplica Mínimo <InfoIcon />
							</div>
						</TooltipHost>
					</th>
					<th>
						<div>Clase Limpia</div>
					</th>
					<th>
						<div>Disponible</div>
					</th>
					<th>
						<div />
					</th>
				</tr>
			</thead>
			<tbody>
				{props.children.length > 0 && props.children.map((item, index) =>
                (
                    <React.Fragment key={index}>
                        <tr key={item.key} onClick={props.onRowClicked}>
                            <td>
                                <div className="max-content">
                                    <DefaultButton id={buttonId + index + item.key}
                                        className={styles.copyButton}
                                        style={{paddingRight: "15px"}}
                                        onClick={() =>
                                        {
                                            setClipboardIsin(item.isin);
                                            showCallout();
                                            navigator.clipboard.writeText(item.isin);
                                        }}
                                    >
                                        <CopyIcon />
                                    </DefaultButton>
                                    {isCalloutVisible && clipboardIsin === item.isin &&
                                    (
                                        <Callout className={styles.callout} target={`#${buttonId + index + item.key}`}
                                            onDismiss={hideCallout}
                                            isBeakVisible={false}
                                            role="alert"
                                        >
                                            <DelayedRender>
                                                <Text variant="small">ISIN copiado</Text>
                                            </DelayedRender>
                                        </Callout>
                                    )}
                                    <button className="expanded">
                                    {
                                        <Link to={
                                            {
                                                pathname: (props.publicVersion ? "/iframe" : "") + `/${item.isin}`,
                                                state:
                                                {
                                                    fund: item,
                                                    brothers: props.children,
                                                    fromEF: props.fromEF
                                                }
                                            }}
                                        >
                                            <div>
                                                {item.name}
                                                <br />
                                                {item.isin}
                                            </div>
                                        </Link>
                                    }
                                    </button>
                                </div>
                            </td>
                            <td>{item.currency}</td>
                            <td>{longPercentFormatter.format(item.ongoingCharges/100)}</td>
                            <td>{longPercentFormatter.format(parseFloat(item.rebate)/100)}</td>
                            <td>
                                {decimalFormatter.format(item.minimumInitialInvestment)}
                                &euro;
                            </td>
                            <td>{item.income ? "Sí" : "No"}</td>
                            <td>{item.noMin ? "Sí" : "No"}</td>
                            <td>{item.cleanShare ? "Sí" : "No"}</td>
                            <td>{item.available ? "Sí" : "No"}</td>

                            <td>{item.currency === "EUR" && item.cleanShare ? <ActiveIcon color="#1BB2AD" /> : ""}</td>
                        </tr>
                    </React.Fragment>
                ))}
			</tbody>
		</Table.TableStyled>
	);
};