import {usePDFSlick} from "@pdfslick/react"
import "@pdfslick/react/dist/pdf_viewer.css";

import { GlobalWorkerOptions } from "pdfjs-dist";

GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js";


type PDFViewerAppProps = {
  pdfFilePath: string,
};

const PDFViewer = ({ pdfFilePath }: PDFViewerAppProps) => {
  const { viewerRef, usePDFSlickStore, PDFSlickViewer } = usePDFSlick(
    pdfFilePath,
    {
      singlePageViewer: false,
      scaleValue: "page-width",
    }
  );

  return (
    <PDFSlickViewer {...{ viewerRef, usePDFSlickStore }} />
  );
};

export default PDFViewer;